import React, { useState } from 'react';
import { useDispatch } from 'react-redux'; 
import { Grid, LinearProgress,Card,CardHeader, CardContent, Typography, Box, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { deleteProject, setStoreData } from '../../Redux/actions';
import { navigate } from 'hookrouter'; 
import RoleChecker from '../Common/RoleChecker';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip as ReToolTip, Label } from 'recharts';

export default function ProjectCard(props: any) {
 
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
  
    const dispatch: any = useDispatch();
    const { setAlertMessage, 
        classes, value, fetchData, setEditData, setOpen, triggerDelete} = props;
 
    const handleCloseMenu = () => {
      setAnchorEl(null);
    };
    const handleEditProject = (proj: any) => {
      handleCloseMenu();
      setEditData(proj);
      setOpen(true);
    };
  
    const handleDeleteProject = (proj: any) => {
      handleCloseMenu();
      triggerDelete(proj);
    };
  
    const handleClick = (event: any, id: any) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };

    const COLORS = ['#CCCCCC', '#04b404', 'red'];
    let vp = 0;
    let vf = 0;
    let vc = 0;
    let vt = 0;

    if (value.valves && value.valves.length > 0) {
      vt = (value.valves.length);
      const pendingValves = value.valves.filter((element: any) => (element.status === 'Pending' || element.status === 'Pending Approval' || element.status === 'Active' || element.status === 'Approved'));
      const failedValves = value.valves.filter((element: any) => (element.status === 'Failed'));
      const completedValves = value.valves.filter((element: any) => (element.status === 'Passed' || element.status === 'Modified'));
      vp += pendingValves ? pendingValves.length : 0;
      vf += failedValves ? failedValves.length : 0;
      vc += completedValves ? completedValves.length : 0;
    }

    const vData = [
      { name: 'Pending', value: vp },
      { name: 'Completed', value: vc },
      { name: 'Failed', value: vf }
    ];

    const projectManagers = value.projectUsers
    .filter((item:any) => item.roles.name === 'project_admin')
    .map((item:any) => (item.user.name)).join();

    const teamLeads = value.projectUsers
    .filter((item:any) => item.roles.name === 'team_lead')
    .map((item:any) => (item.user.name)).join();

    const CustomLabel = ({ centerText }: any) =>{
      return (
       <text x={55} y={56} fill="#000000" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
          <tspan alignmentBaseline="middle" className="bfv-doughnut-center-text">{ centerText }</tspan>
       </text>
      )
    }

    const CustomTooltip = ({ active, payload }:any) => {
      if (active) {
        return (
          <div className={classes.customToolTip}>
            <p>{`${payload[0].name} : ${payload[0].value}`}</p>
          </div>
        );
      }
      return null;
    };
    
    return (<Grid item xs={12} md={4}
      className={classes.root}
    >
      <Card className={classes.card}
        onClick={() => {
          dispatch(setStoreData('currentProject', value));
          navigate(`/project/${value.id}`);
        }}
      >
        <CardHeader action={
          <RoleChecker
            aclList={[{
                  type:'project', 
                  refId: value.id
                }
             ]}
          ><IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={(e) => {
              handleClick(e, value.id);
            }}>
            <MoreVertIcon />
          </IconButton>
          </RoleChecker>
        }
          title={<Typography variant="h6" component="h6"><span className={classes.cardHeaderText}><Tooltip title={<span className={classes.toolTip}>{value.client}</span>} interactive={true} arrow={true}><span>{value.client}</span></Tooltip></span></Typography>}
          className={classes.cardHeader}
        >
        </CardHeader>
        <CardContent className={classes.content}>
        <Grid container>
          <Grid item xs={6} sm={6} container>
            <Grid item xs container direction="column">
              <Grid item>
                <Typography>
                <span className={classes.contentText}>
                  <span className={`w3-text-gray ${classes.projectCardSideTitle}`}>Project Code - </span>
                  <Tooltip title={<span className={classes.toolTip}>{value.projectCode}</span>} interactive={true} arrow={true}><span>{value.projectCode}</span></Tooltip>
                </span>
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  <span className={`w3-text-gray ${classes.projectCardSideTitle}`}>Status - </span>
                  <span>{value.status}</span>
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{marginBottom:'-8px'}}>
                  <span className={classes.contentText}>
                    {projectManagers && <><span className={`w3-text-gray ${classes.projectCardSideTitle}`}>PM - </span>
                    <Tooltip title={<span className={classes.toolTip}>{projectManagers}</span>} interactive={true} arrow={true}><span>{projectManagers}</span></Tooltip>
                    <br/>
                    </>}
                  {teamLeads && <>
                  <span className={`w3-text-gray ${classes.projectCardSideTitle}`}>TL - </span><Tooltip title={<span className={classes.toolTip}>{teamLeads}</span>} interactive={true} arrow={true}><span>{teamLeads}</span></Tooltip>
                  </>}
                  </span>
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                <span className={classes.contentText}>
                <span className={`w3-text-gray ${classes.projectCardSideTitle}`}>Location - </span>
                  {value.location}
                </span>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
            <Grid item xs={6}>
              <div className={'w3-right'}>
                  <PieChart width={95} height={95} className="bfv-margin-auto">
                    <Pie
                      data={vData}
                      cx={50}
                      cy={50}
                      labelLine={false}
                      innerRadius={25}
                      outerRadius={40}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {
                        vData.map((entry:any, index:number) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                      }
                      <Label width={30} position="center"
                        content={<CustomLabel centerText={vt} />}>
                    </Label>
                    </Pie>
                    <ReToolTip content={<CustomTooltip />}/>
                  </PieChart>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Menu
        key={value.id}
        anchorEl={anchorEl}
        keepMounted
        open={openMenu}
        onClose={handleCloseMenu}
        PaperProps={{
          style: {
            maxHeight: 190,
            minWidth: 110,
          },
        }}
      >
       <MenuItem onClick={(e) => {
            e.stopPropagation();
            navigate(`/project-report/${value.id}`);
          }}>
            View Report
        </MenuItem>
        <MenuItem onClick={(e) => {
            e.stopPropagation();
            handleEditProject(value);
          }}>
            Edit
        </MenuItem>
        { value.status !== 'Completed' &&
        <MenuItem onClick={(e) => {
          e.stopPropagation();
          handleDeleteProject(value);
        }}>
          Delete
        </MenuItem>
        }
    
      </Menu>
    </Grid>)
  }

