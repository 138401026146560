import { Button, CircularProgress, Grid, MenuItem, Paper, TextField } from '@material-ui/core';
//import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { postResult } from '../../Redux/actions';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    PD_CONFIG,
    approval,
    createValveDetail,
    editValveDetail,
    findValveDetailById,
    getAllTerms,
    getStandards,
    getValveVersions,
    postCreateValveVersion,
} from '../../Redux/actions';
import AlertDialog from '../Common/AlertDialog';
import RoleChecker from '../Common/RoleChecker';

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.black
        },
        body: {
            fontSize: 14
        }
    })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            flexShrink: 0,
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.background.default
            }
        }
    })
)(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginTop: '10px',
        width: '100%',
        overflowX: 'auto'
    },
    formControl: {
        margin: theme.spacing(1)
    },
    formGroup: {
        padding: '30px 40px'
    },
    marginBottom10: {
        marginBottom: '10px'
    },
    selectLabel: {
        background: 'white',
        padding: '0px 10px'
    },
    w3Panel: {
        marginBottom: '0px'
    },
    table: {
        minWidth: 700
    },
    buttonSection: {
        padding: '40px'
    },
    image: {
        height: '400px',
        width: 'auto'
    },
}));

function ParametersForm(props: any) {

    const classes = useStyles();
    const dispatch: any = useDispatch();
    const { valveId, updateParamObj, onTabChange, valveInfo, getValveInfo, resultObj, updateSavedParamObj } = props;
    const [editData, setEditData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [editFlag, setEditFlag] = useState(false);
    const [alertMessage, setAlertMessage] = useState();
    const [valveDetailId, setValveDetailId] = useState();
    const initialData: any[] = [];
    const [valveVersionData, setValveVersionData] = useState(initialData);

    const [values, setValues] = useState<any>(Object.assign({
        manufacturer: '',
        modelNumber: '',
        pipeDiverPlatform: '',
        valveSize: '',
        valveType: '',
        diskDiameter: '',
        defaultOrStandardValue: '',
        standardId: '',
        classType: '',
        eccentricity: '',
        rotations:'',
        plateThickness: '',
        balance: '0',
        valveOpenDirection: '',
        valvePosition: '',
        valveId: valveId,
        resultAngleRange: '',
        resultCalculatedValues: '',
        resultConclusion: '',
        maxEccentricity: '',
        maxPlateThickness: '',
        isEccentricOrConcentric: ''
    }, editData));
    const [errors, setErrors] = useState<any>({
        manufacturer: '',
        modelNumber: '',
        pipeDiverPlatform: '',
        valveSize: '',
        valveType: '',
        diskDiameter: '',
        defaultOrStandardValue: '',
        eccentricity: '',
        standardId: '',
        classType: '',
        plateThickness: '',
        maxEccentricity: '',
        valvePosition: '',
        valveOpenDirection: '',
        isEccentricOrConcentric: ''
    });
    const [standardList, setStandardList] = useState([]);
    const [standardInfo, setStandardInfo] = useState();
    const [classTypeList, setClassTypeList] = useState([]);
    //const [valveDiaList, setValveDiaList] = useState();
    //const [ isEditing, setEditing ] = useState(false);

    const fetchData = () => {
        dispatch(findValveDetailById(valveId))
            .then((resp: any) => {
                if (resp && resp.data) {
                    const dbData = Object.assign({}, resp.data, {
                        balance: resp.data.json ? resp.data.json.balance :0,
                        rotations: resp.data.json ? resp.data.json.rotations :null,
                    });
                    setEditData(dbData);
                    setValues(dbData);
                    setIsLoading(false);
                    setValveDetailId(resp.data.id);
                    updateSavedParamObj(resp.data.json);
                }
            });
        getValveInfo();
    };

    useEffect(() => {
        setIsLoading(true);
        fetchData();
        dispatch(getAllTerms(1)).then((resp: any) => {
            if (resp && resp.status === 200) {
                const standardList = resp.data.map((item: any) => {
                    return {
                        label: item.name,
                        value: item.id,
                        item
                    };
                });
                setStandardList(standardList);
                setIsLoading(false);
            }
        });

        dispatch(getValveVersions(valveId)).then((resp: any) => {
            if (resp && resp.status === 200) {
                setValveVersionData(resp.data);
            }
        });
    }, [dispatch]);

    useEffect(() => {
        if (!values.standardId) {
            return;
        }
        dispatch(getStandards(values.standardId))
            .then((resp: any) => {
                if (resp && resp.status === 200) {
                    const standardData = resp.data;
                    setStandardInfo(standardData);
                    let filterList: any = [];
                    let found = false;
                    filterList = standardData
                        .filter((item: any) => item.valveDiameter == (values.valveSize))
                        .map((item: any) => item.standardClass);
                    setClassTypeList(filterList);
                }
            });

    }, [values.standardId]);

    const handleCalculate = (e: any) => {

        if (!(values.pipeDiverPlatform && values.valveType && values.valveSize && values.valveOpenDirection && values.plateThickness)) {
            setAlertMessage('Please select PipeDiver Platform, Valve Type, Valve Size (Dia), Plate Thickness, Valve Opening Direction to calculate Pass Angle Range...');
            return;
        }
        if (!values.diskDiameter) {
            setValues({
                diskDiameter: values.valveSize - 1
            });
        }
        if ((values.plateThickness && values.valveSize)
            && (parseInt(values.plateThickness) > parseInt(values.valveSize))) {
            setAlertMessage(' Entered Plate Thickness is greater than Valve Size, Please change the Plate Thickness..');
            return;
        }

        // if(values.valveType === 'unknown'){
        //   setAlertMessage('We don\'t have simulation for this type of valve. We suggest to fully open the ButterFly Valve during Inspection');
        //   return;
        // }

        const pipeDiver:any = PD_CONFIG.find((item: any) => item.id == values.pipeDiverPlatform);
        if (!pipeDiver) {
            setAlertMessage('Invalid PipeDiver Selection');
            return;
        }
        values.pipeDiver = pipeDiver;
        updateParamObj(values);
        onTabChange(e, 1);
    };

    const handleChange = (event: any) => {
        setEditFlag(true);
        const { name, value } = event.target;
        setValues({
            ...values,
            [name]: value
        });
        const err = Object.assign({}, errors);
        if (err[name]) {
            err[name] = null;
        }
        setErrors(err);
    };

    const getValveDiaList = (value: string) => {
        const item = PD_CONFIG.find((item: any) => item.id == value);
        if (item) {
            return item.allowedRanges;
        }
        return ([0]);
    };

    const handleSelect = (e: any) => {
        setEditFlag(true);
        const { name, value } = e.target;

        if (name === 'isEccentricOrConcentric') {
            if (value === 'concentric') {
                values.eccentricity = "0";
                values.maxEccentricity = "0";
            }else{
                values.maxEccentricity = values['diskDiameter'] / 2;
                values.maxPlateThickness = values['diskDiameter'];
            }
        }
        if (name === 'defaultOrStandardValue') {
            if (value === 'Manual') {
                values.standardId = null;
                values.maxEccentricity = values['diskDiameter'] / 2;
                values.maxPlateThickness = values['diskDiameter'];
            }
        }
        if (name === 'valveSize') {
            values.diskDiameter = value - 1;
            values.maxEccentricity = '';
            values.maxPlateThickness = '';
            values.classType = '';
        }
        setValues({
            ...values,
            [name]: value
        });
    };

    const handleClassTypeSelect = (e: any) => {

        if (!values.valveSize) {
            setAlertMessage('Please select Valve Size before selecting Class Type...');
            return;
        }
        const { name, value } = e.target;
        const standardItem = standardInfo.find((item: any) =>
            (
                item.standardClass === value
                && item.valveDiameter === parseInt(values.valveSize)
                && item.termId === values.standardId
            )
        );
        if (!standardItem) {
            setAlertMessage('Selected Valve Size/Class Type not available in Standards for pre-population. Please select another Valve Size or select BFV Data Input as Manual to continue...');
            return;
        }

        const standardValues: any = {
            maxEccentricity: standardItem.shaftEccentricity,
            maxPlateThickness: standardItem.maxDiscThickness,
            eccentricity: 0,
            plateThickness: standardItem.maxDiscThickness
        };

        setValues({
            ...values,
            ...standardValues,
            [name]: value
        });
    };

    const handleSaveVersion = (e: any) => {
        setEditFlag(false);
        if (!valveDetailId) {
            setAlertMessage('Valve Detail Info is not saved. Please save the data');
            return;
        }
        const valveDetailData = Object.assign({}, values);
        valveDetailData.status = 'Updated';
        valveDetailData.valveId = valveId;
        valveDetailData.id = valveDetailId;
        dispatch(postCreateValveVersion(valveDetailData))
            .then((resp: any) => {
                const res = resp && resp.data;
                if (res.success) {
                    setAlertMessage('Valve Detail Version created successfully!!');
                    dispatch(getValveVersions(valveId)).then((resp: any) => {
                        if (resp && resp.status === 200) {
                            setValveVersionData(resp.data);
                        }
                    });
                } else {
                    setAlertMessage('Failed to create Valve Detail Version..');
                }
            });
    };

    const saveCalculatedResultValues = () =>{
        if ((resultObj && resultObj.passRangeList.length && resultObj.optimalValue.success)) {
            const finalResult = {
                id: valveId,
                dir: resultObj.passRangeList[0].dir,
                start: resultObj.passRangeList[0].start,
                end: resultObj.passRangeList[0].end,
                bfov: resultObj.optimalValue.bfov,
                brov: resultObj.optimalValue.brov,
                openDirection: resultObj.optimalValue.openDirection,
                rEn: resultObj.optimalValue.rEn,
                rEx: resultObj.optimalValue.rEx,
                reason: resultObj.optimalValue.reason,
                score: resultObj.optimalValue.score,
                success: resultObj.optimalValue.success,
                tfov: resultObj.optimalValue.tfov,
                theta: resultObj.optimalValue.theta,
                tp: resultObj.optimalValue.tp,
                trov: resultObj.optimalValue.trov,
            };

            dispatch(postResult(finalResult));
        }
    }

    const handleSave = (e: any) => {
        setEditFlag(false);
        e.preventDefault();
        e.stopPropagation();
        const valveDetailData = Object.assign({}, values);
        valveDetailData.valveId = valveId;
        const pd = PD_CONFIG.find((item:any) => item.id == valveDetailData.pipeDiverPlatform);
        if (pd) {
            valveDetailData.pdDia =  pd.dia;
        }

        if (!(valveDetailData.pipeDiverPlatform && valveDetailData.valveType && valveDetailData.valveSize
            && valveDetailData.valveOpenDirection && valveDetailData.plateThickness &&
            `${valveDetailData.eccentricity}`.length > 0)) {
            setAlertMessage('Please select Valve Type, Valve Size, PipeDiver Platform, Valve Opening Direction, Plate Thickness & Eccentricity to save the data...');
            return;
        }
        if (!(valveDetailData.manufacturer && valveDetailData.modelNumber)) {
            setAlertMessage('Please enter the Valve Manufacturer and Valve Model Number.');
            return;
        }


        if (!valveDetailId) {
            valveDetailData.status = 'Active';
            dispatch(createValveDetail(valveDetailData))
                .then((resp: any) => {
                    const res = resp && resp.data;
                    if (res.success) {
                        setValveDetailId(res.data.id);
                        saveCalculatedResultValues();
                        fetchData();
                        setAlertMessage('Valve Details created successfully!!');
                    } else {
                        setAlertMessage('Failed to create Valve Details..');
                    }
                });
        } else {

            dispatch(editValveDetail(valveDetailData, valveDetailId))
                .then((resp: any) => {
                    const res = resp && resp.data;
                    if (res.success) {
                        setValveDetailId(res.data.id);
                        saveCalculatedResultValues();
                        fetchData();
                        setAlertMessage('Valve Details updated successfully!!');
                    } else {
                        setAlertMessage('Failed to update Valve Details..');
                    }
                });
        }

    };

    const updateStatus = (status: string) => {
        dispatch(approval(status, valveId)).then((resp: any) => {
            fetchData();
        });
    };

    const inputLabel = React.useRef<HTMLLabelElement>(null);

    if (editData && editData.id && !editFlag) {

        if (editData.standardId) {
            values.defaultOrStandardValue = 'Standard';
        } else {
            values.defaultOrStandardValue = 'Manual';
        }

        if (editData.eccentricity == 0) {
            values.isEccentricOrConcentric = 'concentric';
        } else {
            values.isEccentricOrConcentric = 'eccentric';
        }

    }
    let valveStatus = '';
    if (values && valveInfo.status) {
        valveStatus = valveInfo.status;
    } 
    return (
        <div>
            {isLoading ?

                (<Grid>
                    <div className="textMarginCenter">
                        <CircularProgress />
                    </div>
                </Grid>)
                :
                (
                    <Fragment>
                        <form onSubmit={handleSave}>
                            <Grid container className={classes.formGroup} spacing={2} justify="flex-start"
                                alignItems="center">
                                <Grid item xs={12} md={3}>

                                    <FormControl variant="outlined" fullWidth margin="dense"
                                        className={classes.formControl}>
                                        <InputLabel className={classes.selectLabel} ref={inputLabel} id="valveTypeLabel">
                                            Select Valve Type
                              </InputLabel>
                                        <Select
                                            labelId="valveTypeLabel"
                                            id="valveType"
                                            name="valveType"
                                            value={values.valveType}
                                            onChange={v => handleSelect(v)}
                                            fullWidth
                                        >
                                            <MenuItem value="flatPlate">Flat Plate</MenuItem>
                                            <MenuItem value="humpBack">Hump Back</MenuItem>
                                            <MenuItem value="unknown">Unknown</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControl
                                        variant="outlined"
                                        fullWidth
                                        margin="dense"
                                        className={classes.formControl}>
                                        <InputLabel className={classes.selectLabel} ref={inputLabel}
                                            id="pipeDiverPlatformLabel">
                                            PipeDiver Platform
                              </InputLabel>
                                        <Select
                                            labelId="pipeDiverPlatformLabel"
                                            id="pipeDiverPlatform"
                                            name="pipeDiverPlatform"
                                            value={values.pipeDiverPlatform}
                                            onChange={v => handleSelect(v)}
                                            fullWidth
                                        >
                                            {PD_CONFIG.map((item: any) => <MenuItem key={item.label} value={item.id}>{item.label}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <FormControl
                                        variant="outlined"
                                        fullWidth
                                        margin="dense"
                                        className={classes.formControl}>
                                        <InputLabel className={classes.selectLabel} ref={inputLabel} id="valveSizeLabel">
                                            Select Valve Size
                              </InputLabel>
                                        <Select
                                            labelId="valveSizeLabel"
                                            id="valveSize"
                                            name="valveSize"
                                            value={values.valveSize}
                                            onChange={v => handleSelect(v)}
                                            required
                                            fullWidth
                                        >
                                            {
                                                getValveDiaList(values.pipeDiverPlatform).map((item: any) => (
                                                    <MenuItem key={`key_${item}`} value={item}>
                                                        {`${item} Inches`}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>


                                <Grid item xs={12} md={3}>
                                    <FormControl variant="outlined" fullWidth margin="dense"
                                        className={classes.formControl}>
                                        <TextField fullWidth
                                            id="valveManufacturer"
                                            name="manufacturer"
                                            margin="dense"
                                            label="Valve Manufacturer"
                                            value={values.manufacturer}
                                            onChange={(e) => handleChange(e)}
                                            variant="outlined"
                                            required
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <FormControl variant="outlined" fullWidth margin="dense"
                                        className={classes.formControl}>
                                        <TextField fullWidth
                                            id="valveModelNo"
                                            name="modelNumber"
                                            margin="dense"
                                            label="Valve ModelNumber"
                                            value={values.modelNumber}
                                            onChange={(e) => handleChange(e)}
                                            variant="outlined"
                                            required
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <FormControl variant="outlined" fullWidth margin="dense"
                                        className={classes.formControl}>
                                        <InputLabel className={classes.selectLabel} ref={inputLabel}
                                            id="valvePositionLabel">
                                            Valve Position
                              </InputLabel>
                                        <Select
                                            labelId="valvePositionLabel"
                                            id="valvePosition"
                                            name="valvePosition"
                                            value={values.valvePosition}
                                            onChange={v => handleSelect(v)}
                                            fullWidth
                                        >
                                            <MenuItem value="horizontal">Horizontal</MenuItem>
                                            <MenuItem value="vertical">Vertical</MenuItem>
                                            <MenuItem value="unknown">Unknown</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <FormControl variant="outlined" fullWidth margin="dense"
                                        className={classes.formControl}>
                                        <InputLabel className={classes.selectLabel} ref={inputLabel}
                                            id="valveOpenDirectionLabel">
                                            Valve Opening Direction
                              </InputLabel>
                                        <Select
                                            labelId="valveOpenDirectionLabel"
                                            id="valveOpenDirection"
                                            name="valveOpenDirection"
                                            value={values.valveOpenDirection}
                                            onChange={v => handleSelect(v)}
                                            fullWidth
                                        >
                                            <MenuItem value="Clockwise">Clockwise</MenuItem>
                                            <MenuItem value="Anticlockwise">AntiClockwise</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>


                                <Grid item xs={12} md={3}>
                                    <FormControl variant="outlined" fullWidth margin="dense"
                                        className={classes.formControl}>
                                        <InputLabel className={classes.selectLabel} ref={inputLabel}
                                            id="defaultOrStandardValueLabel">
                                            Select BFV Data Input..
                              </InputLabel>
                                        <Select
                                            labelId="defaultOrStandardValueLabel"
                                            id="defaultOrStandardValue"
                                            name="defaultOrStandardValue"
                                            value={values.defaultOrStandardValue}
                                            onChange={v => handleSelect(v)}
                                            fullWidth
                                        >
                                            <MenuItem value="Manual">Manual Entry</MenuItem>
                                            <MenuItem value="Standard">Standard</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Fragment>
                                    {(values.defaultOrStandardValue == 'Manual') && (

                                        <Grid item xs={12} md={3}>
                                            <FormControl variant="outlined" fullWidth margin="dense"
                                                className={classes.formControl}>
                                                <InputLabel className={classes.selectLabel} ref={inputLabel}
                                                    id="eccentricOrConcentricLabel">
                                                    Is Valve Eccentric?
                                    </InputLabel>
                                                <Select
                                                    labelId="eccentricOrConcentricLabel"
                                                    id="isEccentricOrConcentric"
                                                    name="isEccentricOrConcentric"
                                                    placeholder=""
                                                    value={values.isEccentricOrConcentric}
                                                    onChange={v => handleSelect(v)}
                                                    fullWidth
                                                >
                                                    <MenuItem value="concentric">Concentric</MenuItem>
                                                    <MenuItem value="eccentric">Eccentric</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    )}
                                </Fragment>

                                {(values.defaultOrStandardValue == 'Standard') && (
                                    <Fragment>
                                        <Grid item xs={12} md={3}>
                                            <FormControl variant="outlined" fullWidth margin="dense"
                                                className={classes.formControl}>
                                                <InputLabel className={classes.selectLabel} ref={inputLabel}
                                                    id="standardLabel">
                                                    Select Standard
                                    </InputLabel>
                                                <Select
                                                    disabled={(values.defaultOrStandardValue && values.defaultOrStandardValue === 'Standard')? false : true}
                                                    labelId="standardLabel"
                                                    id="standard"
                                                    name="standardId"
                                                    value={values.standardId}
                                                    onChange={v => handleSelect(v)}
                                                    fullWidth
                                                >
                                                    {
                                                        standardList.map((item: any) => {
                                                            return <MenuItem key={`key_${item.value}`} value={item.value}>{item.label}</MenuItem>;
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <FormControl variant="outlined" fullWidth margin="dense"
                                                className={classes.formControl}>
                                                <InputLabel className={classes.selectLabel} ref={inputLabel}
                                                    id="classTypeLabel">
                                                    Select Class Type
                                    </InputLabel>
                                                <Select
                                                    labelId="classTypeLabel"
                                                    id="classType"
                                                    name="classType"
                                                    value={values.classType}
                                                    onChange={v => handleClassTypeSelect(v)}
                                                    fullWidth
                                                >
                                                    {
                                                        classTypeList.map((item: any) => (
                                                            <MenuItem key={`key_${item}`} value={item}>
                                                                {`${item}`}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                    </Fragment>)}
                                <Grid item xs={12} md={3}>
                                    <FormControl variant="outlined" fullWidth margin="dense"
                                        className={classes.formControl}>
                                        <TextField fullWidth
                                            InputLabelProps={{ shrink: values.eccentricity.length !== 0 }}
                                            disabled={(values.isEccentricOrConcentric && values.isEccentricOrConcentric === 'concentric') ? true:false}
                                            id="eccentricity"
                                            name="eccentricity"
                                            margin="dense"
                                            label="Eccentricity in inches"
                                            value={values.eccentricity}
                                            onChange={(e) => handleChange(e)}
                                            variant="outlined"
                                            inputProps={{
                                                required: true,
                                                type: 'number',
                                                step: 0.01,
                                                min: -values.maxEccentricity,
                                                max: values.maxEccentricity,
                                            }}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <FormControl variant="outlined" fullWidth margin="dense"
                                        className={classes.formControl}>
                                        <TextField fullWidth
                                            InputLabelProps={{ shrink: values.plateThickness ? true : false }}
                                            id="plateThickness"
                                            name="plateThickness"
                                            margin="dense"
                                            label="Plate Thickness in Inches"
                                            value={values.plateThickness}
                                            onChange={handleChange}
                                            variant="outlined"
                                            inputProps={{
                                                required: true,
                                                type: 'number',
                                                step: 0.01,
                                                min: -values.maxPlateThickness,
                                                max: values.maxPlateThickness
                                            }}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <FormControl
                                        variant="outlined"
                                        fullWidth
                                        margin="dense"
                                        className={classes.formControl}>
                                        <TextField fullWidth
                                            id="diskDiameter"
                                            name="diskDiameter"
                                            margin="dense"
                                            disabled={values.valveSize ? true : false}
                                            label="Disk Diameter in inches"
                                            placeholder=""
                                            value={values.diskDiameter ? values.diskDiameter : (values.valveSize ? (values.valveSize - 1) : '')}
                                            onChange={(e) => handleChange(e)}
                                            variant="outlined"
                                            required
                                            InputLabelProps={{ shrink: values.diskDiameter ? true : false }}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={5}>
                                    <FormControl
                                        variant="outlined"
                                        fullWidth
                                        margin="dense"
                                        className={classes.formControl}>
                                        <TextField fullWidth
                                            id="rotations"
                                            name="rotations"
                                            margin="dense"
                                            label="Number of rotations for fully open(0 to 90°)"
                                            placeholder=""
                                            value={values.rotations}
                                            onChange={(e) => handleChange(e)}
                                            inputProps={{
                                                required: true,
                                                type: 'number',
                                                step: 0.5,
                                                min: 0, 
                                            }}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: values.rotations ? true : false }}
                                        />
                                    </FormControl>
                                </Grid>

                            </Grid>

                            <Grid container className={classes.formGroup}>
                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={4} direction={'row'} justify={'flex-start'} alignItems={'flex-start'}>

                                        {
                                            (valveDetailId && valveStatus === 'Pending Approval') &&

                                            <RoleChecker>
                                                <Grid item>
                                                    <Button
                                                        variant="outlined"
                                                        color="secondary"
                                                        onClick={(e) => handleSaveVersion(e)}
                                                    >Save Version</Button>
                                                </Grid>
                                            </RoleChecker>
                                        }


                                        {valveDetailId && (valveStatus === 'Active' || valveStatus === 'Created') &&
                                            <RoleChecker adminAccess={false} aclList={[
                                                {
                                                    type: 'project',
                                                    refId: valveInfo.projectId
                                                }, {
                                                    type: 'valve',
                                                    refId: valveInfo.id
                                                }
                                            ]}>
                                                <Grid item>
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={(e) => updateStatus('Pending Approval')}
                                                    >Submit For Approval</Button>
                                                </Grid>
                                            </RoleChecker>
                                        }


                                        {valveDetailId && valveStatus === 'Pending Approval' && <RoleChecker adminAccess={false} aclList={[
                                            {
                                                type: 'project',
                                                refId: valveInfo.projectId
                                            }, {
                                                type: 'valve',
                                                refId: valveInfo.id
                                            }
                                        ]}>
                                            <Grid item>
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    onClick={(e) => updateStatus('Active')}
                                                >Cancel Approval Request</Button>
                                            </Grid>
                                        </RoleChecker>
                                        }

                                        <RoleChecker>
                                            <Grid item>
                                                {valveDetailId && valveStatus === 'Pending Approval' &&
                                                    <RoleChecker>
                                                        <Button
                                                            variant="outlined"
                                                            color="primary"
                                                            onClick={(e) => updateStatus('Approved')}
                                                        >Approve</Button>
                                                    </RoleChecker>
                                                }
                                            </Grid>
                                            <Grid item>
                                                {valveDetailId && valveStatus === 'Pending Approval' &&
                                                    <RoleChecker>
                                                        <Button
                                                            variant="outlined"
                                                            color="secondary"
                                                            onClick={(e) => updateStatus('Active')}
                                                        >Reject</Button>
                                                    </RoleChecker>
                                                }
                                            </Grid>
                                        </RoleChecker>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid container justify={'flex-end'} alignItems={'flex-end'} spacing={4}>

                                        
                                        <Grid item>
                                            <RoleChecker aclList={[
                                                {
                                                    type: 'project',
                                                    refId: valveInfo.projectId
                                                }
                                            ]}>
                                            <Button
                                                color="primary"
                                                variant="outlined"
                                                onClick={(e) => handleCalculate(e)}
                                            >Calculate</Button>
                                            </RoleChecker>
                                        </Grid>

                                        {(valveStatus === 'Active' || valveStatus === 'Created' || valveStatus === '') &&
                                            <RoleChecker aclList={[
                                                {
                                                    type: 'project',
                                                    refId: valveInfo.projectId
                                                }, {
                                                    type: 'valve',
                                                    refId: valveInfo.id
                                                }
                                            ]}>
                                                <Grid item>
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        variant="contained"
                                                    >Save</Button>
                                                </Grid>
                                            </RoleChecker>
                                        }


                                        {valveDetailId && (valveStatus === 'Pending Approval') &&
                                            <RoleChecker>
                                                <Grid item>
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        variant="contained"

                                                    >Save</Button>
                                                </Grid>
                                            </RoleChecker>
                                        }

                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </Fragment>


                )
            }
            {
                values.valveType === 'flatPlate' &&
                <Grid className="w3-padding w3-image">
                    <img src="https://dist.d.ceegees.in/xylem/graphic_fp.png" alt="Flate Plate" className={classes.image} />
                </Grid>
            }
            {
                values.valveType === 'humpBack' &&
                <Grid className="w3-padding w3-image">
                    <img src="https://dist.d.ceegees.in/xylem/graphic_hb.png" alt="Hump Back" className={classes.image} />
                </Grid>
            }
            {alertMessage
                ? (
                    <AlertDialog
                        handleClose={() => {
                            setAlertMessage(null);
                        }}
                        message={alertMessage}
                    />
                ) : null}

            {valveDetailId && <div>

                <h5 className="w3-padding">Valve Parameter Versions</h5>
                <div className='card-padding'>
                    <Paper className={classes.root}>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Manufacturer</StyledTableCell>
                                    <StyledTableCell>Model Number</StyledTableCell>
                                    <StyledTableCell>PipeDiver Platform</StyledTableCell>
                                    <StyledTableCell>Valve Size</StyledTableCell>
                                    <StyledTableCell>Valve Type</StyledTableCell>
                                    <StyledTableCell>Standard</StyledTableCell>
                                    <StyledTableCell>Class Type</StyledTableCell>
                                    <StyledTableCell>Shaft Eccentricity</StyledTableCell>
                                    <StyledTableCell>Max Plate Thickness</StyledTableCell>
                                    <StyledTableCell>Disk Diameter</StyledTableCell>
                                    <StyledTableCell>Created By</StyledTableCell>
                                    <StyledTableCell>Created At</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {valveVersionData.map(versions => (
                                    <StyledTableRow key={versions.id}>
                                        <StyledTableCell>{versions.valveData.manufacturer}</StyledTableCell>
                                        <StyledTableCell>{versions.valveData.modelNumber}</StyledTableCell>
                                        <StyledTableCell>{versions.valveData.pipeDiverPlatform}</StyledTableCell>
                                        <StyledTableCell>{versions.valveData.valveSize}</StyledTableCell>
                                        <StyledTableCell>{versions.valveData.valveType}</StyledTableCell>
                                        <StyledTableCell>{versions.valveData.standardId}</StyledTableCell>
                                        <StyledTableCell>{versions.valveData.classType}</StyledTableCell>
                                        <StyledTableCell>{versions.valveData.eccentricity}</StyledTableCell>
                                        <StyledTableCell>{versions.valveData.plateThickness}</StyledTableCell>
                                        <StyledTableCell>{versions.valveData.diskDiameter}</StyledTableCell>
                                        <StyledTableCell>{versions.user.name}</StyledTableCell>
                                        <StyledTableCell>{moment(versions.createdAt).format('DD/MM/YYYY hh:mm')}</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </div>
            </div>}
        </div>
    );

}

export default ParametersForm;
