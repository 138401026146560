import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Grid, Paper, LinearProgress, CircularProgress, Card, CardHeader, CardContent, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { deleteProject, findProjects, getProjectList, setStoreData, getFilterProjectList } from '../../Redux/actions';
import { navigate } from 'hookrouter';
import AlertDialog from '../Common/AlertDialog';
import CreateProjectModal from './CreateProjectModal';
import SearchBox from '../Common/SearchBox';
import Pagination from '../Common/Pagination';
import ProjectCard from './ProjectCard';
import ProjectFilters from './ProjectFilters';
import RoleChecker from '../Common/RoleChecker';
import ConfirmDialog from '../Common/ConfirmDialog';
import TitleHeader from '../Common/TitleHeader';
import DialogModal from '../Common/DialogModal';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: '8px',
  },
  card: {
    padding: 6,
    height: 180,
    width: '100%',
    backgroundColor: 'white',
    cursor: 'pointer',
    overFlow: 'scroll',
  },
  addPaper: {
    padding: '25px',
    height: 180,
    background: '#FFFFFF',
  },
  addBtnText: {
    verticalAlign: 'middle',
    lineHeight: '130px',
    textAlign: 'center',
  },
  spacing: {
    marginLeft: theme.spacing(1),
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 500,
    // padding: '10px',
    display: 'inline-block',
    [theme.breakpoints.up('md')]: {
      width: '18vw',
    },

    [theme.breakpoints.down('sm')]: {
      width: '50vw',
    },
    [theme.breakpoints.down('xs')]: {
      width: '75vw',
    },
  },
  content: {
    padding: '0px 5px 5px 5px',
    marginBottom: '8px',
  },
  contentText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    [theme.breakpoints.up('md')]: {
      width: '15vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '40vw',
    },
    [theme.breakpoints.down('xs')]: {
      width: '40vw',
    },
  },
  paginateTopPadding: {
    paddingTop: '50px'
  },
  cardHeader:{
    padding:'5px 5px 0px 5px'
  },
  cardHeaderText:{
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    [theme.breakpoints.up('md')]: {
      width: '15vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '40vw',
    },
    [theme.breakpoints.down('xs')]: {
      width: '60vw',
    },
  },
  projectCardSideTitle:{
    fontSize: '13px'
  },
  toolTip:{
    fontSize:'13px'
  },
  customToolTip:{
    background: 'rgba(0, 0, 0, 0.6)',
    padding:'0px 5px',
    borderRadius:'5px',
    color:'white',
    fontSize:'13px'
  }
}));

const initialPaginateData = {
  page: 1,
  offset: 0,
  limit: 8
};



function ManageProjects(props: any) {
  const classes = useStyles();
  const dispatch: any = useDispatch();
  const initialData: any[] = [];
  let manageProjects: any = null;
  const [data, setData] = useState(initialData);
  const [selectedProject, setProject] = useState<any>(Object.assign({}));
  const [alertMessage, setAlertMessage] = useState();
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState();
  const state: any = useSelector(state => state);
  const { projectList } = state;
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [searchStr, setSearchStr] = useState('');
  const [filterValues, setFilterValues] = useState<any>(Object.assign({
    userId: '',
    location: '',
    time: '',
  }));
  const [ currentPage, setCurrentPage ] = useState(1);
  const limit = 8;
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const fetchData = (paginateData: any) => {
    if (!paginateData) {
      paginateData = initialPaginateData;
    }
    setIsLoading(true);
    dispatch(getProjectList(paginateData)).then((resp: any) => {
      if (resp && resp.status === 200) {
        const projectListData = resp.data;
        if (!projectListData) {
          return;
        }
        setData(projectListData.data);
        setTotalCount(projectListData.total);
        setIsLoading(false);
      }
    });
  };

  const fetchFilteredData = (filterQueryData: any) => {
    setIsLoading(true);
    dispatch(getFilterProjectList(filterQueryData)).then((resp: any) => {
      setData(resp.data.data);
      setTotalCount(resp.data.total);
      setIsLoading(false);
    });
  }

  useEffect(() => {
    fetchData(initialPaginateData);
  }, [dispatch]);

  const triggerFilter = (filters: any) =>{
    let filterPaginate = {
      page: 1,
      offset: 0,
      limit
    };
    filterPaginate = Object.assign(filterPaginate,filters);
    setCurrentPage(1);
    fetchFilteredData(filterPaginate); 
    setFilterValues(filters);
  }

  const handleSearch = async (e: any) => {
    const search = e.target.value.toLowerCase();
    const filters = {};
    Object.keys(filterValues).forEach((key:any) => {
      if(filterValues[key] !== ''){
        Object.assign(filters, {[key]:filterValues[key]});
      }
    });

    let searchQueryData = {
      page: 1,
      offset: 0,
      limit
    };

    if(search !== ''){
      setSearchStr(search);
      searchQueryData = Object.assign(searchQueryData, { search: search });
      if(Object.keys(filters).length >0){
        searchQueryData = Object.assign(searchQueryData, filters);
      }
      fetchFilteredData(searchQueryData);
    }else if(search === ''){
      setSearchStr('');
      if(Object.keys(filters).length >0){
        searchQueryData = Object.assign(searchQueryData, filters);
      }
      fetchFilteredData(searchQueryData);
    }else{
      fetchData(initialPaginateData);
    }
    setCurrentPage(1);
  };

  const handlePagination = (pageValue: any, perPage: any) => {
    const filters = {};
    Object.keys(filterValues).forEach((key:any) => {
      if(filterValues[key] !== ''){
        Object.assign(filters, {[key]:filterValues[key]});
      }
    });
    let paginateData = {
      page: pageValue,
      offset: perPage,
      limit
    }
    setCurrentPage(pageValue);
      if(searchStr !== ''){
        paginateData = Object.assign(paginateData, { search: searchStr });
        if(Object.keys(filters).length >0){
          paginateData = Object.assign(paginateData,filters);
        }
        fetchFilteredData(paginateData);
      }else if(searchStr === ''){
        if(Object.keys(filters).length >0){
          paginateData = Object.assign(paginateData, filters);
        }
        fetchFilteredData(paginateData);
      }else{
        setCurrentPage(1);
        fetchData(initialPaginateData);
      }
  }

  const clearFilters = () =>{
    setSearchStr('')
    setFilterValues({
      userId: '',
      location: '',
      time: '',
    });
    setCurrentPage(1);
    fetchData(initialPaginateData);
  }
  
  const clearFilterState = () =>{
    setFilterValues({
      userId: '',
      location: '',
      time: '',
    });
  }

  const handleDeleteProject = () => {
    if(selectedProject){
      setShowConfirmDialog(false);
      dispatch(deleteProject(selectedProject.id)).then((resp: any) => {
        if (resp && resp.status === 200) {
          const result = resp.data;
          if(!result.success){
            setAlertMessage(result.message);
            return;
          }
          setProject({});
          setAlertMessage(result.message);
          setCurrentPage(1);
          fetchData(initialPaginateData);
        } else {
          setAlertMessage('Project deletion failed...!');
        }
      });
    }
  };

  const triggerDelete = (proj: any)=>{
    setProject(proj);
    setShowConfirmDialog(true)
  }

  const addProjectCard = <Grid item xs={12} md={4} style={{ padding: '8px' }}>
    <Card className={`add-item-card ${classes.addPaper}`} onClick={() => {
      setEditData(null);
      setOpen(true);
    }}>
      <Typography className={classes.addBtnText} display="block" align="center">
        + Add New Project
      </Typography>
    </Card>
  </Grid>;
  let projectCardList: any[] = [];
  if (data && data.length > 0) {
    projectCardList = data.map((value: any, idx: number) => (<ProjectCard
      key={`Project_${value.id}`}
      classes={classes}
      setEditData={setEditData}
      setAlertMessage={setAlertMessage}
      fetchData={()=>{
        setCurrentPage(1)
        fetchData(initialPaginateData)
      }}
      setOpen={setOpen}
      value={value}
      triggerDelete={triggerDelete}/>));
  }
  if (isLoading || !data) {
    manageProjects = (
      <Grid item xs={12} md={12}>
        <div className="textMarginCenter">
          <div className="w3-center">
            <CircularProgress />
          </div>
        </div>
      </Grid>
    );
  } else if (data && data.length) {
    manageProjects = projectCardList;
  } else if (data && data.length === 0) {
    manageProjects = (
      <Grid item xs={12} md={3} className="textMarginCenter">
        <h5>No Projects Found</h5>
      </Grid>
    );
  }

  return (
    <div >
      <TitleHeader title="Projects"
        showSearch={true}>
        <div style={{ marginRight: '10px' }}>
          <SearchBox
            value={searchStr}
            placeholder={'Search Projects...'}
            handleSearch={handleSearch}
          />
        </div>
        <ProjectFilters
          fetchFilteredData={triggerFilter}
          clearFilterValues={clearFilters}
          clearFilterState={clearFilterState}
        />
      </TitleHeader>
      <Grid container>
        <RoleChecker>
        {addProjectCard}
        </RoleChecker>
        {manageProjects}
        {(data && data.length > 0 && totalCount > limit) && (
          <Grid container className={`w3-center ${classes.paginateTopPadding}`}>
            <Pagination
              cPage={currentPage}
              defaultPerPage={limit}
              data={{ totalCount }}
              onChange={handlePagination}
            />
          </Grid>)}
      </Grid>


      {open ? (
        <DialogModal
          fullWidth
          isOpen={open}
          hideTitle={true}
          hideBtns={true}
          maxWidth="sm"
          dialogContent={(
            <CreateProjectModal
              triggerList={() => {
                setSearchStr('');
                setCurrentPage(1);
                fetchData(initialPaginateData)
              }}
              editData={editData}
              handleClose={() => {
                setOpen(false);
                setEditData({});
              }}
            />
          )}
        /> ): null}
      {alertMessage
        ? (
          <AlertDialog
            handleClose={() => setAlertMessage(null)}
            message={alertMessage}
          />
        )
        : null
      }
      {showConfirmDialog
        ? (
          <ConfirmDialog
            handleCancel={() => {
              setShowConfirmDialog(false)
              setProject({});
            }}
            handleOk={handleDeleteProject}
            message={'Are you sure to delete the project?'}
          />
        ): null}
    </div>
  );
}

export default ManageProjects;