import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Drawer, Divider, List, FormControl, InputLabel, Select, Button, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import ReactSelect from 'react-select';
import { getAllUsers, getProjectList } from '../../Redux/actions';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles({
  list: {
    paddingTop: 50,
    maxWidth: 380,
    padding: 20,
  },
  selectLabel: {
    background: 'white',
    padding: '0px 10px',
  },
  formControl: {
    minWidth: 300,
  },
  filterIcon: {
    marginTop: '4px',
    marginRight: '20px',
  },
  reactSelect: {
    zIndex: 5
  },
  scrollMenuList:{
    maxHeight: 200, 
    overflow: 'auto'
  }
});

const ProjectFilters = (props: any) => {
  const { fetchFilteredData, clearFilterValues, clearFilterState } = props;
  const classes = useStyles();
  const dispatch: any = useDispatch();
  const initialData: any[] = [];
  const [userList, setUserList] = useState(initialData);
  const [projectList, setProjectList] = useState(initialData);
  const [drawerState, setDrawerState] = React.useState({
    right: false,
  });
  const [filterValues, setFilterValues] = useState<any>(Object.assign({
    userId: '',
    location: '',
    time: ''
  }));
  const [selectedUser, setSelectedUser] = useState<any>(Object.assign({
    label: 'Select User',
    value: ''
  }));
  let locationList: any[] = [];

  useEffect(() => {
    dispatch(getAllUsers()).then((resp: any) => {
      if (resp && resp.status === 200) {
        const userListData = resp.data.map((item: any) => ({ label: item.name, value: item.id }));;
        setUserList(userListData);
      }
    });
    dispatch(getProjectList()).then((resp: any) => {
      if (resp && resp.status === 200) {
        const respData = resp.data;
        setProjectList(respData.data);
      }
    })

  }, [dispatch]);

  type DrawerSide = 'right';
  const toggleDrawer = (side: DrawerSide, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    setDrawerState({ ...drawerState, [side]: open });
  };


  const handleUserFilter = (user: any) => {
    setSelectedUser(user);
  }

  const handleSelectFilter = (e: any) => {
    const { name, value } = e.target;
    setFilterValues({
      ...filterValues,
      [name]: value,
    });
  };

  const triggerFilter = () => {
    const filterQueryData = {};

    if (selectedUser && selectedUser.value) {
      Object.assign(filterQueryData, { userId: selectedUser.value })
    }

    if (filterValues.location) {
      Object.assign(filterQueryData, { location: filterValues.location })
    }

    if (filterValues.time) {
      const timeFilter =  moment().subtract(filterValues.time, "days");
      const timeStampValue = moment(timeFilter).format('YYYY-MM-DDTHH:mm:ss.ssssss');
      Object.assign(filterQueryData, { time: timeStampValue })
    }
    fetchFilteredData(filterQueryData);
    setDrawerState({
      right: false,
    });
  }

  const handleClearFilter = () => {
    setDrawerState({
      right: false,
    });

    setSelectedUser({
      label: 'Select User',
      value: ''
    });
    setFilterValues({
      userId: '',
      location: '',
      time: '',
    });
    clearFilterValues();
  }

  if(projectList && projectList.length >0){
    locationList = projectList.filter((obj, idx, arr) => (
      arr.findIndex((item) => item.location.toString().toLowerCase() === obj.location.toString().toLowerCase()) === idx
    ));
  }

  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const enableFilter = Object.keys(filterValues).length ? false : true

  const sideList = (side: DrawerSide) => (
    <div
      className={classes.list}
      role="presentation"
    >
      <h3>Filters</h3>
      <List>
        <Grid item xs={12} md={3}>
          <FormControl
            variant="outlined"
            fullWidth
            margin="dense"
            className={`${classes.formControl} ${classes.reactSelect}`}>
            <ReactSelect
              name="userId"
              label="Select User"
              options={userList}
              placeHolder="Select User"
              value={selectedUser}
              onChange={user => handleUserFilter(user)}
            />
          </FormControl>
        </Grid>
      </List>
      <Divider />
      <List>
        <Grid item xs={12} md={3}>
          <FormControl
            variant="outlined"
            fullWidth
            margin="dense"
            className={classes.formControl}>
            <InputLabel className={classes.selectLabel} ref={inputLabel} id="locationLabel">
              Select Location
              </InputLabel>
            <Select
              labelId="locationLabel"
              id="location"
              name="location"
              value={filterValues.location || ''}
              onChange={v => handleSelectFilter(v)}
              fullWidth
              MenuProps={{ classes: { paper: classes.scrollMenuList } }}
            >
              {
                locationList.map((item: any) => (
                  <MenuItem key={`key_${item.id}`} value={item.location}>
                    {item.location}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
      </List>
      <Divider />
      <List>
        <Grid item xs={12} md={3}>
          <FormControl
            variant="outlined"
            fullWidth
            margin="dense"
            className={classes.formControl}>
            <InputLabel className={classes.selectLabel} ref={inputLabel} id="timeFilterLabel">
              Select Time
              </InputLabel>
            <Select
              labelId="timeFilterLabel"
              id="time"
              name="time"
              value={filterValues.time}
              onChange={v => handleSelectFilter(v)}
              fullWidth
            >
              <MenuItem value="30">Last 30 days </MenuItem>
              <MenuItem value="60">Last 60 days</MenuItem>
              <MenuItem value="90">Last 90 days</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </List>
      <Divider />
      <List>
        <Grid container justify="space-between" alignItems="center">
          <Button variant="contained"
            size="small"
            onClick={handleClearFilter}>
            Clear
            </Button>
          <Button color="primary"
            variant="contained"
            size="small"
            disabled={enableFilter}
            onClick={triggerFilter}
            className="w3-center">
            Filter
            </Button>
        </Grid>
      </List>

    </div>
  );

  return (
    <div>
      <IconButton className={`w3-right cursor-pointer ${classes.filterIcon}`} onClick={toggleDrawer('right', true)} >
      <FilterListIcon />
      </IconButton>
      <Drawer anchor="right" open={drawerState.right} onClose={toggleDrawer('right', false)}
        ModalProps={{ onBackdropClick: ()=> clearFilterState() }}>
        {sideList('right')}
      </Drawer>
    </div>
  )


}

export default ProjectFilters;