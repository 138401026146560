import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, Paper, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { A, usePath, navigate } from 'hookrouter';
import { useDispatch, useSelector } from 'react-redux';

import createPhysicsModel from '../Valves/physics.js';
import BFVCalculator from '../Valves/BFVCalculator';
import moment from 'moment';
import { getProjectReport, getAllTerms } from '../../Redux/actions';
const xylemLogo = 'https://cdn.ceegees.in/xylem/xylem-logo.png';
const pureTechLogo = 'https://cdn.ceegees.in/xylem/pure-tech-logo.png';
const pdLogo = 'https://cdn.ceegees.in/xylem/pd_logo.png';
const pipeDiverTextImg = 'https://cdn.ceegees.in/xylem/pipe-diver-text.png';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  contentDiv: {
    minHeight: '100vh'
  },
  printBtn: {
    margin: '10px',
    paddingTop: '10px'
  },
  internalUse: {
    color: '#FC1514 !important',
  },
  mainHeader: {
    fontWeight: 600,
    backgroundColor: '#000000 !important',
    color: '#FFFFFF !important'
  },
  subHeader: {
    fontWeight: 600,
    backgroundColor: '#444444 !important',
    color: '#FFFFFF !important',
  },
  borderTop: {
    borderTop: '1px solid gray',
  },
  borderBottom: {
    borderBottom: '1px solid gray',
  },
  borderRight: {
    borderRight: '1px solid gray',
  },
  borderLeft: {
    borderLeft: '1px solid gray'
  },
  cellTitle: {
    textAlign: 'right',
    fontWeight: 600,
    paddingRight: '8px',
    paddingLeft: '4px'
  },
  cellText: {
    textAlign: 'left',
    paddingLeft: '8px',
    paddingRight: '4px',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word'
  },
  cellTBPadding: {
    paddingTop: '8px',
    paddingBottom: '8px'
  },
  marginRight10: {
    marginRight: '10px'
  },
  image: {
    maxWidth: '50%', 
    display: 'inline-block', 
    maxHeight: '320px',
    height: 'auto'
  },
  allLogo: {
    maxWidth: '400px',
    height: '50px',
    objectFit: 'contain',
  },
  boldText: {
    fontWeight: 'bold'
  }

});


const ValvesReport = (props: any) => {
  const classes = useStyles();
  const { data, count, key, standardList } = props; 
  const [result, setResult] = useState();
  const [calc, setCalc] = useState();

  useEffect(() => {
    if (!data.valveDetails) {
      return;
    }
    const { valveDetails } = data;
    const { theta = null, balance = 0 } = valveDetails.json;
    const c = new BFVCalculator(valveDetails, balance);
    const r: any = c.calculatePassRange(theta);
    setResult(r);
    setCalc(c);
  }, [0]);

  useEffect(() => { 
    if (!data.valveDetails || !result || !calc){
      return;
    }
    createPhysicsModel(`geometry_${data.valveDetails.id}`, {
      result: result.optimalValue,
      calc
    });
  }, [calc, result]);

  if (!data.valveDetails) {
    return (<Box display="flex" flexDirection="column" key={key}>
      <Box display="flex" flexDirection="row" justifyContent="center" className={`${classes.mainHeader} ${classes.borderRight} ${classes.borderLeft} ${classes.borderTop} ${classes.cellTBPadding}`}>
        <Typography component="h6" variant="h6">
          {`Valve ${count}`}
        </Typography>
      </Box>
      <div style={{ border: "solid 5px black" }}>
        <Box display="flex" flexDirection="row" justifyContent="center" className={`${classes.subHeader} ${classes.borderRight} ${classes.borderLeft} ${classes.borderBottom} ${classes.cellTBPadding}`}>
          <Typography component="h6" variant="h6">
            Part A: Parameters
        </Typography>
        </Box>
        <div className="w3-padding">Parameters not poulated for this Valve</div>
      </div>
    </Box>);
  }



  if (!result) {
    return <div className="w3-center w3-padding-64"> Calculating..</div>
  }
  const pdResult = result.optimalValue; 
  let standard: any = null;
  if (data.valveDetails.standardId) {
    standard = standardList.find((item: any) => item.id === data.valveDetails.standardId)
  }

  return (
    <Box display="flex" flexDirection="column" key={key}>
      <Box display="flex" flexDirection="row" justifyContent="center" className={`${classes.mainHeader} ${classes.borderRight} ${classes.borderLeft} ${classes.borderTop} ${classes.cellTBPadding}`}>
        <Typography component="h6" variant="h6">
          {`Valve ${count}`}
        </Typography>
      </Box>

      <div style={{ border: "solid 5px black" }}>
        <Box display="flex" flexDirection="row" justifyContent="center" className={`${classes.subHeader} ${classes.borderRight} ${classes.borderLeft} ${classes.borderBottom} ${classes.cellTBPadding}`}>
          <Typography component="h6" variant="h6">
            Part A: Parameters
        </Typography>
        </Box>

        <Box display="flex" flexDirection="row" className={`${classes.borderBottom}`}>
          <Box width="50%" display="flex" flexDirection="row" className={`${classes.borderRight} ${classes.borderLeft}`}>
            <Box width="65%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                Valve ID
            </Typography>
            </Box>
            <Box width="35%" className={`${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellText}`}>
                {data.valveNo}
              </Typography>
            </Box>
          </Box>
          <Box width="50%" display="flex" flexDirection="row" className={`${classes.borderRight}`}>
            <Box width="65%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                Valve Name
            </Typography>
            </Box>
            <Box width="35%" className={`${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellText}`}>
                {data.name}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" className={`${classes.borderBottom}`}>
          <Box width="50%" display="flex" flexDirection="row" className={`${classes.borderRight} ${classes.borderLeft}`}>
            <Box width="65%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                Valve Location (STA#)
            </Typography>
            </Box>
            <Box width="35%" className={`${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellText}`}>
                {data.location}
              </Typography>
            </Box>
          </Box>
          <Box width="50%" display="flex" flexDirection="row" className={`${classes.borderRight}`}>
            <Box width="65%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                Inspection Date
            </Typography>
            </Box>
            <Box width="35%" className={`${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellText}`}>
                {moment(data.valveDate).format('DD/MM/YYYY')}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" className={`${classes.borderBottom}`}>
          <Box width="50%" display="flex" flexDirection="row" className={`${classes.borderRight} ${classes.borderLeft}`}>
            <Box width="65%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                Valve Type
            </Typography>
            </Box>
            <Box width="35%" className={`${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellText}`}>
                {data.valveDetails.valveType === 'humpBack' ? 'Hump Back' : 'Flat Plate'}
              </Typography>
            </Box>
          </Box>
          <Box width="50%" display="flex" flexDirection="row" className={`${classes.borderRight}`}>
            <Box width="65%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                Valve Size (inches)
              </Typography>
            </Box>
            <Box width="35%" className={`${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellText}`}>
                {data.valveDetails.valveSize}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" className={`${classes.borderBottom}`}>
          <Box width="50%" display="flex" flexDirection="row" className={`${classes.borderRight} ${classes.borderLeft}`}>
            <Box width="65%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                Valve Manufacturer
            </Typography>
            </Box>
            <Box width="35%" className={`${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellText}`}>
                {data.valveDetails.manufacturer}
              </Typography>
            </Box>
          </Box>
          <Box width="50%" display="flex" flexDirection="row" className={`${classes.borderRight}`}>
            <Box width="65%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                Valve Model / Number
            </Typography>
            </Box>
            <Box width="35%" className={`${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellText}`}>
                {data.valveDetails.modelNumber}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" className={`${classes.borderBottom}`}>
          <Box width="50%" display="flex" flexDirection="row" className={`${classes.borderRight} ${classes.borderLeft}`}>
            <Box width="65%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                Valve Position
            </Typography>
            </Box>
            <Box width="35%" className={`${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellText}`}>
                {data.valveDetails.valvePosition}
              </Typography>
            </Box>
          </Box>
          <Box width="50%" display="flex" flexDirection="row" className={`${classes.borderRight}`}>
            <Box width="65%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                Valve Opening Direction
            </Typography>
            </Box>
            <Box width="35%" className={`${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellText}`}>
                {data.valveDetails.valveOpenDirection}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" className={`${classes.borderBottom}`}>
          <Box width="50%" display="flex" flexDirection="row" className={`${classes.borderRight} ${classes.borderLeft}`}>
            <Box width="65%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                Manual / Standard
            </Typography>
            </Box>
            <Box width="35%" className={`${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellText}`}>
                {data.valveDetails.standardId ? 'Standard' : 'Manual'}
              </Typography>
            </Box>
          </Box>
          <Box width="50%" display="flex" flexDirection="row" className={`${classes.borderRight}`}>
            <Box width="65%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                Standard Selected
            </Typography>
            </Box>
            <Box width="35%" className={`${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellText}`}>
                {standard && standard.name}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" className={`${classes.borderBottom}`}>
          <Box width="50%" display="flex" flexDirection="row" className={`${classes.borderRight} ${classes.borderLeft}`}>
            <Box width="65%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                Valve Class Type
            </Typography>
            </Box>
            <Box width="35%" className={`${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellText}`}>
                {data.valveDetails.classType}
              </Typography>
            </Box>
          </Box>
          <Box width="50%" display="flex" flexDirection="row" className={`${classes.borderRight}`}>
            <Box width="65%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                Plate Thickness (T) (inches)
            </Typography>
            </Box>
            <Box width="35%" className={`${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellText}`}>
                {data.valveDetails.plateThickness}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" className={`${classes.borderBottom}`}>
          <Box width="50%" display="flex" flexDirection="row" className={`${classes.borderRight} ${classes.borderLeft}`}>
            <Box width="65%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                Is valve Eccentric?
            </Typography>
            </Box>
            <Box width="35%" className={`${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellText}`}>
                {data.valveDetails.eccentricity === 0 ? 'Concentric' : 'Eccentric'}
              </Typography>
            </Box>
          </Box>
          <Box width="50%" display="flex" flexDirection="row" className={`${classes.borderRight}`}>
            <Box width="65%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                Eccentricity of shaft (E) (inches)
            </Typography>
            </Box>
            <Box width="35%" className={`${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellText}`}>
                {data.valveDetails.eccentricity}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" className={`${classes.borderBottom}`}>
          <Box width="50%" display="flex" flexDirection="row" className={`${classes.borderRight} ${classes.borderLeft}`}>
            <Box width="65%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                Valve Disk Diameter (inches)
            </Typography>
            </Box>
            <Box width="35%" className={`${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellText}`}>
                {data.valveDetails.diskDiameter}
              </Typography>
            </Box>
          </Box>
          <Box width="50%" display="flex" flexDirection="row" className={`${classes.borderRight}`}>
            <Box width="65%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                PipeDiver platform
            </Typography>
            </Box>
            <Box width="35%" className={`${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellText}`}>
                {data.valveDetails.pipeDiverPlatform}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" className={`${classes.borderBottom}`}>
          <Box width="50%" display="flex" flexDirection="row" className={`${classes.borderRight} ${classes.borderLeft}`}>
            <Box width="65%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                Number of rotations for fully open (0 to 90°)
            </Typography>
            </Box>
            <Box width="35%" className={`${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellText}`}>
                {data.valveDetails.json && data.valveDetails.json.rotations ? data.valveDetails.json.rotations : ''}
              </Typography>
            </Box>
          </Box>
          <Box width="50%" display="flex" flexDirection="row" className={`${classes.borderRight}`}>
            <Box width="65%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                PipeDiver Balance
            </Typography>
            </Box>
            <Box width="35%" className={`${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellText}`}>
                {data.valveDetails.json && data.valveDetails.json.balance ? data.valveDetails.json.balance : ''}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="center"
          className={`${classes.subHeader} ${classes.borderRight} ${classes.borderLeft} ${classes.borderBottom} ${classes.cellTBPadding}`}>
          <Typography component="h6" variant="h6">
            Part B: Result
        </Typography>
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="center" style={{ position: 'relative' }}
          className={`${classes.borderRight} ${classes.borderLeft} ${classes.borderBottom} ${classes.cellTBPadding}`}>
          <Typography className={`${classes.cellTitle}`}>
            Geometric values (Refer image)
        </Typography>
        </Box>

        <Box display="flex" flexDirection="row" className={`${classes.borderBottom}`}>
          <Box width="50%" display="flex" flexDirection="row" className={`${classes.borderRight} ${classes.borderLeft}`}>
            <Box width="65%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                Butterfly Valve Opening Angle (ϕ) (degrees)
            </Typography>
            </Box>
            <Box width="35%" className={`${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellText}`}>
                {pdResult.thetaInfo}
              </Typography>
            </Box>
          </Box>
          <Box width="50%" display="flex" flexDirection="row" className={`${classes.borderRight}`}>
            <Box width="65%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                Top Front Opening from Center (A) (inches)
            </Typography>
            </Box>
            <Box width="35%" className={`${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellText}`}>
                {pdResult ? pdResult.tfov : ''}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" className={`${classes.borderBottom}`}>
          <Box width="50%" display="flex" flexDirection="row" className={`${classes.borderRight} ${classes.borderLeft}`}>
            <Box width="65%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                Top Rear Opening from Center (B) (inches)
            </Typography>
            </Box>
            <Box width="35%" className={`${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellText}`}>
                {pdResult.trov}
              </Typography>
            </Box>
          </Box>
          <Box width="50%" display="flex" flexDirection="row" className={`${classes.borderRight}`}>
            <Box width="65%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                Bottom Front Opening from Center (C) (inches)
            </Typography>
            </Box>
            <Box width="35%" className={`${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellText}`}>
                {pdResult.bfov}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" className={`${classes.borderBottom}`}>
          <Box width="50%" display="flex" flexDirection="row" className={`${classes.borderRight} ${classes.borderLeft}`}>
            <Box width="65%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                Bottom Rear Opening from Center (D) (inches)
            </Typography>
            </Box>
            <Box width="35%" className={`${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellText}`}>
                {pdResult.brov}
              </Typography>
            </Box>
          </Box>
          <Box width="50%" display="flex" flexDirection="row" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
          <Box width="65%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                Number Of Rotations Required
              </Typography>
            </Box>
          <Box width="35%" className={`${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellText}`}>
              {(pdResult.success) ? <span>{pdResult.rotationInfo}</span> : <span></span>}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" className={`${classes.borderBottom}`}>
          <Box width="50%" display="flex" flexDirection="row" className={`${classes.borderRight} ${classes.borderLeft}`}>
            <Box width="40%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                Conclusion
            </Typography>
            </Box>
            <Box width="65%" className={`${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellText} ${classes.boldText} ${pdResult.success ? 'w3-text-green' : 'w3-text-red'}`}>
                {pdResult.success ? <span>Pass </span> : <span>Collision Probability</span>}
              </Typography>
            </Box>
          </Box>
          <Box width="50%" display="flex" flexDirection="row" className={`${classes.borderRight}`}>
            <Box width="40%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellTitle} ${classes.marginRight10}`} style={{ wordBreak: 'break-word' }}>
                Recommendation
            </Typography>
            </Box>
            <Box width="65%" className={`${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellText}`}>
                { pdResult.recommendation}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" className={`${classes.borderBottom}`}>
          <Box width="50%" display="flex" flexDirection="row" className={`${classes.borderRight} ${classes.borderLeft}`}>
            <Box width="40%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                Suggested Pass Range
            </Typography>
            </Box>
            <Box width="65%" className={`${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellText}`}>
                {result.passRangeList.map((range: any,idx:number) => {
                  return (<div key={`pos_${idx}`}>
                    {Math.abs(range.start)} to {Math.abs(range.end)} {range.dir}
                  </div>)
                  })
                } 
              </Typography>
            </Box>
          </Box>
          <Box width="50%" display="flex" flexDirection="row" className={`${classes.borderRight}`}>
            <Box width="40%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                Reason for Fail, if any
            </Typography>
            </Box>
            <Box width="65%" className={`${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellText}`}>
                {pdResult.reason}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="center"
          style={{ padding: "10px" }}
          className={`${classes.borderBottom} ${classes.borderRight} ${classes.borderLeft}`}>
          {data.valveDetails.valveType === 'flatPlate' && <img src="https://dist.d.ceegees.in/xylem/drawing_fp.jpg"
            alt="Geometry" className={classes.image} />}
          {data.valveDetails.valveType === 'humpBack' && data.valveDetails.valveOpenDirection === 'Clockwise' && <img src="https://dist.d.ceegees.in/xylem/drawing_hb.jpg"
            alt="Geometry" className={classes.image} />}
          {data.valveDetails.valveType === 'humpBack' && data.valveDetails.valveOpenDirection === 'Anticlockwise' && <img src="https://dist.d.ceegees.in/xylem/drawing_hb_anti_clock.jpg"
            alt="Geometry" className={classes.image} />}
          
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="center"
          style={{ padding: "10px" }}
          className={`${classes.borderBottom} ${classes.borderRight} ${classes.borderLeft}`}>
          <div id={`geometry_${data.valveDetails.id}`}></div>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="center" className={`${classes.subHeader} ${classes.borderRight} ${classes.borderLeft} ${classes.borderBottom} ${classes.cellTBPadding}`}>
          <Typography component="h6" variant="h6">
            Part C: Post Inspection Summary
        </Typography>
        </Box>

        <Box display="flex" flexDirection="row" className={`${classes.borderBottom}`}>
          <Box width="50%" display="flex" flexDirection="row" className={`${classes.borderRight} ${classes.borderLeft}`}>
            <Box width="50%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                Feedback
            </Typography>
            </Box>
            <Box width="50%" className={`${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellText}`}>
                {data.valveDetails.json && data.valveDetails.json.feedback ? data.valveDetails.json.feedback : ''}
              </Typography>
            </Box>
          </Box>
          <Box width="50%" display="flex" flexDirection="column" className={`${classes.borderRight}`}>
            <Box display="flex" flexDirection="row" className={`${classes.borderBottom}`}>
              <Box width="50%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
                <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                  Angle
              </Typography>
              </Box>
              <Box width="50%" className={`${classes.cellTBPadding}`}>
                <Typography className={`${classes.cellText}`}>
                  {data.valveDetails.json && data.valveDetails.json.angle ? data.valveDetails.json.angle : ''}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" className={`${classes.borderBottom}`}>
              <Box width="50%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
                <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                  Result
              </Typography>
              </Box>
              <Box width="50%" className={`${classes.cellTBPadding}`}>
                <Typography className={`${classes.cellText} ${classes.boldText} ${data.valveDetails.json && data.valveDetails.json.result && data.valveDetails.json.result === 'Failed' ? 'w3-text-red' : 'w3-text-green'}`}>
                  {data.valveDetails.json && data.valveDetails.json.result ? data.valveDetails.json.result : ''}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" flexDirection="row">
              <Box width="50%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
                <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                  Additional Notes
              </Typography>
              </Box>
              <Box width="50%" className={`${classes.cellTBPadding}`}>
                <Typography className={`${classes.cellText}`}>
                  {data.valveDetails.json && data.valveDetails.json.notes ? data.valveDetails.json.notes : ''}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" className={`${classes.borderBottom}`}>
          <Box width="60%" display="flex" flexDirection="column" className={`${classes.borderRight} ${classes.borderLeft}`}>
            <Box className={`${classes.cellTBPadding} ${classes.borderBottom}`}>
              <Typography className={`${classes.cellTitle} ${classes.marginRight10}`} style={{ textAlign: 'left' }}>
                Describe any tool related issues and problems encountered during the inspection
            </Typography>
            </Box>
            <Box className={`${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellText}`}>
                &nbsp;
            </Typography>
            </Box>
          </Box>
          <Box width="40%" display="flex" flexDirection="column" className={`${classes.borderRight}`}>
            <Box className={`${classes.borderBottom} ${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellTitle} ${classes.marginRight10} w3-center`}>
                Action Taken
            </Typography>
            </Box>
            <Box className={`${classes.borderBottom} ${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellText}`}>
                {data.valveDetails.json && data.valveDetails.json.action ? data.valveDetails.json.action : <>&nbsp;</>}
              </Typography>
            </Box>
            <Box className={`${classes.cellTBPadding}`}>
              <Typography className={`${classes.cellText}`}>
                &nbsp;
            </Typography>
            </Box>
          </Box>
        </Box>
      </div>
    </Box>
  )

};


export default function ProjectReport(props: any) {

  const classes = useStyles();
  const dispatch: any = useDispatch();
  const path = usePath();
  const splitedPath = path.split('/');
  const currentProjectId: number = parseInt(splitedPath[2]);
  const [isLoading, setIsLoading] = useState(false);
  const [projectData, setData] = useState();
  const [standardData, setStandardData] = useState();
  let report: any = null;
  let reportData: any = null;

  const fetchData = () => {
    dispatch(getProjectReport(currentProjectId)).then((resp: any) => {
      setIsLoading(false);
      if (resp && resp.status === 200) {
        const projectDetails = resp.data;
        if (!projectDetails) {
          return;
        }
        setData(projectDetails.data);
        document.title = `Project Report - ${projectDetails.data.name} ${projectDetails.data.projectCode}  ${projectDetails.data.location} - ${moment().format('YYYY_MMM_DD_hh_mm_ss')}`;
        dispatch(getAllTerms(1)).then((resp: any) => {
          setIsLoading(false);
          if (resp && resp.status === 200) {
            setStandardData(resp.data)
          }
        });
      }
    });
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [1]);


  if (projectData && standardData) {
    reportData = (
      <>
        <Box display="flex" flexDirection="row" justifyContent="flex-end" displayPrint="none" className={classes.printBtn}>

          <Button onClick={() => window.history.back()}>Back</Button>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={() => window.print()}>Print Report</Button>
        </Box>
        <Box display="block">
          <Box component="div" display="flex" flexDirection="column">
            <Box component="div" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" className={`${classes.borderTop} ${classes.borderRight} ${classes.borderBottom} ${classes.borderLeft}`}>
              <Box justifySelf="flex-start" style={{ padding: '8px' }}>
                {/* <img src={xylemLogo} className={classes.allLogo} alt="xylem-logo" /> */}
              </Box>
              <Box justifySelf="flex-end">
                <Box style={{ padding: '8px' }}>
                  {/* <img src={pureTechLogo} className={classes.allLogo} alt="Pure Technologies Logo" /> */}
                  <img src={xylemLogo} className={classes.allLogo} alt="xylem-logo" />
                </Box>
              </Box>
            </Box>
            <Box component="div" display="flex" flexDirection="row" justifyContent="flex-start" className={`${classes.borderRight} ${classes.borderLeft} ${classes.borderBottom}`}>
              <Box style={{
                background: '#4da0b4',
                padding: '10px 20px'
              }} className={`${classes.borderRight} ${classes.cellTBPadding} w3-center`}>
                <img src={pdLogo} className={classes.allLogo} alt="PipeDiver" />
              </Box>
              <Box className={`${classes.cellTBPadding} w3-black`} style={{ padding: '20px', width: '100%' }}>
                <Typography style={{ fontSize: '18px', fontWeight: 'bold', color: 'white' }}>
                  PipeDiver Passage Report - {projectData.name}
              </Typography>
              </Box>
            </Box>
            {projectData.status !== 'completed' && (<Box display="flex" flexDirection="row" justifyContent="center" className={`${classes.borderRight} ${classes.borderLeft} ${classes.borderBottom} ${classes.cellTBPadding}`}>
              <Typography component="h6" variant="h6">
                OPERATOR TO IN ALL APPLICABLE CELLS ON-SITE
            </Typography>
            </Box>)}
            <Box display="flex" flexDirection="row" justifyContent="center" className={`${classes.borderRight} ${classes.borderLeft} ${classes.borderBottom} ${classes.cellTBPadding}`}>
              <Typography component="h6" variant="h6" className={classes.internalUse} style={{ fontWeight: 'bold' }}>
                FOR INTERNAL USE ONLY
            </Typography>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="center" className={`${classes.mainHeader} ${classes.borderRight} ${classes.borderLeft} ${classes.borderBottom} ${classes.cellTBPadding}`}>
              <Typography component="h6" variant="h6">
                Project Details
            </Typography>
            </Box>
            <Box display="flex" flexDirection="row" className={`${classes.borderBottom}`}>
              <Box width="50%" display="flex" flexDirection="row" className={`${classes.borderRight} ${classes.borderLeft}`}>
                <Box width="50%" className={`${classes.borderRight}`}>
                  <Typography className={`${classes.cellTitle} ${classes.marginRight10} ${classes.cellTBPadding}`}>
                    Client Name
                </Typography>
                </Box>
                <Box width="50%">
                  <Typography className={`${classes.cellText} ${classes.cellTBPadding}`}>
                    {projectData.client}
                  </Typography>
                </Box>
              </Box>

              <Box width="50%" display="flex" flexDirection="row" className={`${classes.borderRight} `}>
                <Box width="50%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
                  <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                    Project Code
                </Typography>
                </Box>
                <Box width="50%">
                  <Typography className={`${classes.cellText} ${classes.cellTBPadding}`}>
                    {projectData.projectCode}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box display="flex" flexDirection="row" className={`${classes.borderBottom}`}>
              <Box width="50%" display="flex" flexDirection="row" className={`${classes.borderRight} ${classes.borderLeft}`}>
                <Box width="50%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
                  <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                    Location
                </Typography>
                </Box>
                <Box width="50%" className={`${classes.cellTBPadding}`}>
                  <Typography className={`${classes.cellText}`}>
                    {projectData.location}
                  </Typography>
                </Box>
              </Box>
              <Box width="50%" display="flex" flexDirection="row" className={`${classes.borderRight}`}>
                <Box width="50%" className={`${classes.borderRight} ${classes.cellTBPadding}`}>
                  <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                    No. of Valves
                </Typography>
                </Box>
                <Box width="50%" className={`${classes.cellTBPadding}`}>
                  <Typography className={`${classes.cellText}`}>
                    {projectData.valves && projectData.valves.length}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box display="flex" flexDirection="row" className={`${classes.borderBottom} ${classes.borderRight} ${classes.borderLeft}`}>
              <Box width="50%" display="flex" flexDirection="row">
                <Box width="50%" className={`${classes.borderRight} ${classes.cellTBPadding}`} style={{ marginRight: '1px' }}>
                  <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                    Report Printed On
                </Typography>
                </Box>
                <Box width="50%" className={`${classes.cellTBPadding}`}>
                  <Typography className={`${classes.cellText}`}>
                    {moment().format('lll')}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box display="flex" flexDirection="row" className={`${classes.borderBottom}`}>
              <Box width="100%" display="flex" flexDirection="row" className={`${classes.borderRight}`}>
                <Box width="25%" className={`${classes.borderRight} ${classes.borderLeft} ${classes.cellTBPadding}`} style={{ marginRight: '1px' }}>
                  <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                    Project Manager
                  </Typography>
                </Box>
                <Box width="75%" className={`${classes.cellTBPadding}`}>
                  <Typography className={`${classes.cellText}`}>
                    {projectData.projectUsers
                      .filter((item: any) => item.roles.name === 'project_admin')
                      .map((item: any) => (item.user.name)).join()}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box display="flex" flexDirection="row" className={`${classes.borderBottom}`}>
              <Box width="100%" display="flex" flexDirection="row" className={`${classes.borderRight} ${classes.borderLeft}`}>
                <Box width="25%" className={`${classes.borderRight} ${classes.cellTBPadding}`} style={{ marginRight: '1px' }}>
                  <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                    Team Leads
                </Typography>
                </Box>
                <Box width="75%" className={`${classes.cellTBPadding}`}>
                  <Typography className={`${classes.cellText}`}>
                    {projectData.projectUsers
                      .filter((item: any) => item.roles.name === 'team_lead')
                      .map((item: any) => (item.user.name)).join()}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box display="flex" flexDirection="row" className={`${classes.borderBottom}`}>
              <Box width="100%" display="flex" flexDirection="row" className={` ${classes.borderLeft}`}>
                <Box width="25%" className={`${classes.borderRight} ${classes.cellTBPadding}`} style={{ marginRight: '2px' }}>
                  <Typography className={`${classes.cellTitle} ${classes.marginRight10}`}>
                    Description
                </Typography>
                </Box>
                <Box width="75%" className={`${classes.cellTBPadding} ${classes.borderRight}`}>
                  <Typography className={`${classes.cellText}`}>
                    {projectData.description}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          {
            projectData.valves && projectData.valves.length > 0 && projectData.valves.map((valve: any, idx: number) => (<ValvesReport count={idx + 1} key={`valve_${valve.id}`} data={valve} standardList={standardData} />))
          }
        </Box>
      </>)
  }


  if (isLoading) {
    report = (
      <Box display="flex" flexDirection="row" justifyContent="center" displayPrint="none" className={classes.contentDiv}>
        <CircularProgress style={{ alignSelf: 'center' }} />
      </Box>);
  } else if (projectData && reportData) {
    report = reportData;
  } else if (!projectData) {
    report = (
      <Box display="flex" flexDirection="row" justifyContent="center" displayPrint="none" className={classes.contentDiv}>
        <Typography component="h5" variant="h5" style={{ alignSelf: 'center' }}>
          No Data Found
        </Typography>
      </Box>);
  }



  return (
    <Paper className={classes.root}>
      {report}
    </Paper>
  );

}

