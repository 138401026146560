import { Grid, Paper, Typography, Button, Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { navigate } from 'hookrouter';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAllEnquiryList, setStoreData } from '../../Redux/actions';
import AlertDialog from '../Common/AlertDialog';
import RoleChecker from '../Common/RoleChecker';
import EnquiryModal from './EnquiryModal'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import Pagination from '../Common/Pagination';
import EnquiryFilters from './EnquiryFilters';
import TitleHeader from '../Common/TitleHeader';
import DialogModal from '../Common/DialogModal'

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1
    },
    paper: {
      padding: theme.spacing(2),
      margin: 'auto',
    },
    paddingB10: {
        paddingBottom: '10px'
    },
    paginateTopPadding: {
      paddingTop: '50px'
    },
    enqInfoTitle:{
        fontSize: '13px'
    }
}));

const initialPaginateData = {
    page: 1,
    offset: 0,
    limit: 10
  };
  
export default function EnquiryList(props: any) {
    const classes = useStyles();
    const dispatch: any = useDispatch();
    const initialData: any[] = [];
    const [data, setData] = useState(initialData);
    const [alertMessage, setAlertMessage] = useState();
    const [open, setOpen] = useState(false);
    const [enquiryDetails, setEnquiryDetails] = useState();
    const [ currentPage, setCurrentPage ] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const limit = 10;
    const [filterValues, setFilterValues] = useState<any>(Object.assign({
        status: ''
      }));
    const [isLoading, setIsLoading] = useState(false);
    let enquiryDisplay: any = null;

    const fetchData = (paginateData: any) => {
        setIsLoading(true)
        dispatch(getAllEnquiryList(paginateData)).then((resp: any) => {
            setIsLoading(false)
            if (resp && resp.status === 200) {
                const resData = resp.data;
                setData(resData.data);
                setTotalCount(resData.total);
            } else {
                setAlertMessage('Something went wrong...!');
            }
        });
    };
    useEffect(() => {
        fetchData(initialPaginateData);
    }, [dispatch]);


    const handleSelectFilter = (e: any) => {
        const { name, value } = e.target;
        setFilterValues({
            ...filterValues,
            [name]: value,
        });
    };

    const handlePagination = (pageValue: any, perPage: any) => {
        const filters = {};
        Object.keys(filterValues).forEach((key: any) => {
            if (filterValues[key] !== '') {
                Object.assign(filters, { [key]: filterValues[key] });
            }
        });
        let paginateData = {
            page: pageValue,
            offset: perPage,
            limit
        }
        setCurrentPage(pageValue);
        if(Object.keys(filters).length >0){
            paginateData = Object.assign(paginateData, filters);
            fetchData(paginateData);
        } else {
            fetchData(paginateData);
        }
    }

    const triggerFilter = () => {
        let filterPaginate = {
            page: 1,
            offset: 0,
            limit
        };
        if(filterValues.status !== ''){
            filterPaginate = Object.assign(filterPaginate, {status:filterValues.status});
        }
        setCurrentPage(1);
        fetchData(filterPaginate);
    }

    const clearFilters = () => {
        setFilterValues({
            status: '',
          });
        setCurrentPage(1);
        fetchData(initialPaginateData);
    }
    
    const clearFilterState = () => {
        setFilterValues({
            status: '',
          });
    }

    let enquiryCards: any[] = [];

    if (data && data.length > 0) {
        enquiryCards = data.map((enquiry, idx) => (
            <Paper className={`w3-section ${classes.paper}`} key={enquiry.id}>
                <Grid container spacing={2} justify="space-between">
                    <Grid item xs={8} sm={8} md={8} container spacing={1}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box display="flex" flexDirection="column">
                                <Typography gutterBottom variant="subtitle1">
                                    <span className={`w3-text-gray ${classes.enqInfoTitle}`}>Subject - </span>
                                    {enquiry.subject}
                                </Typography>
                                <div>
                                    <span className={`w3-text-gray ${classes.enqInfoTitle}`}>Status - </span>
                                    <Typography gutterBottom className={`w3-tag w3-round ${enquiry.status === 'Closed' ? 'w3-green' : 'w3-red'}`}>{enquiry.status}</Typography>
                                </div>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box display="flex" flexDirection="column">
                                <Typography gutterBottom variant="subtitle1">
                                    <span className={`w3-text-gray ${classes.enqInfoTitle}`}>Client Name - </span>
                                    {enquiry.valve ? enquiry.valve.project.client : null}
                                </Typography>
                                <Typography gutterBottom>
                                        <span className={`w3-text-gray ${classes.enqInfoTitle}`}>Valve Name - </span>
                                        {enquiry.valve ? enquiry.valve.name : null}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Typography>
                                <span className={`w3-text-gray ${classes.enqInfoTitle}`}>Submitted by - </span> {enquiry.user.name}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography>
                                <span className={`w3-text-gray ${classes.enqInfoTitle}`}>On - </span> {moment(enquiry.createdAt).format('DD/MM/YYYY')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} md={4} sm container>
                        <Grid item xs container direction="column" className="w3-right-align" spacing={2} >

                            {
                                enquiry.status === 'Closed' &&
                                <Grid item>
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      size="small"
                                      onClick={() => {
                                          setEnquiryDetails(enquiry);
                                          setOpen(true);
                                      }}>
                                        View Reply
                                    </Button>
                                </Grid>
                            }
                            { enquiry.status === 'Pending' &&
                              <RoleChecker aclList = {
                                [{
                                  type: 'project',
                                  refId: enquiry.valve.project.id
                                }]
                              }>
                                <Grid item>
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      size="small"
                                      onClick={() => {
                                          setEnquiryDetails(enquiry);
                                          setOpen(true);
                                      }}>
                                        Reply
                                    </Button>
                                </Grid>
                              </RoleChecker>
                            }
                            <Grid item>
                                <IconButton
                                    onClick={() => {
                                        navigate(`project/${enquiry.valve.project.id}/valve/${enquiry.valveId}`)
                                    }}>
                                    <ArrowForwardIosIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        ))
    }

    if (isLoading || !data) {
        enquiryDisplay = (
            <div className="w3-center">
            <CircularProgress />
            </div>
        );
      } else if (enquiryCards && enquiryCards.length) {
        enquiryDisplay = enquiryCards;
      } else if (enquiryCards && enquiryCards.length === 0) {
        enquiryDisplay = (            
            <div className="w3-center">
                <h5>No Enquiries</h5>
            </div>
        );
      }


    return (
        <div >
            <TitleHeader title="Enquiries" showSearch={false}>
                <EnquiryFilters
                    handleSelectFilter={handleSelectFilter}
                    filterQueries={filterValues}
                    fetchFilteredData={triggerFilter}
                    clearFilterValues={clearFilters}
                    clearFilterState={clearFilterState}
                />
            </TitleHeader>
            {enquiryDisplay}
            {(data && data.length > 0 && totalCount > limit) && (
                <Grid container className={`w3-center ${classes.paginateTopPadding}`}>
                    <Pagination
                        cPage={currentPage}
                        defaultPerPage={limit}
                        data={{ totalCount }}
                        onChange={handlePagination}
                    />
                </Grid>)}
            {open ? (<DialogModal
                    fullWidth
                    isOpen={open}
                    hideTitle={true}
                    hideBtns={true}
                    maxWidth="sm"
                    dialogContent={(
                        <EnquiryModal
                        open={open}
                        enquiryData={enquiryDetails}
                        valveId={null}
                        triggerList={()=> clearFilters()}
                        handleClose={() => setOpen(false)}
                    />)}
                  />) : null}

            {alertMessage ?
                (
                    <AlertDialog
                        handleClose={() => setAlertMessage(null)}
                        message={alertMessage}
                    />
                )
                : null
            }
        </div>
    );

}
