import React from 'react';
import {useRedirect, useRoutes} from 'hookrouter';
import Header from '../Components/Common/Header';
import Dashboard from '../Components/Common/Dashboard';
import Settings from '../Components/Common/Settings';
import ManageProjects from '../Components/Projects/ManageProjects';
import ManageStandards from '../Components/standards/standardsData';
import Standards from '../Components/standards/standards';
import ManageUsers from '../Components/Users/ManageUsers';
import ManageValves from '../Components/Valves/ManageValves';
import ValveDetail from '../Components/Valves/Detail';
import ValveList from '../Components/Valves/valvelist';
import EnquiryList from '../Components/Enquiries/EnquiryList';
import ProjectReport from '../Components/Projects/ProjectReport';

const img  = 'https://cdn.ceegees.in/xylem/xylem-wave.png';
const routes = {
    '/': () => <Dashboard/>,
    '/dashboard': () => <Dashboard/>,
    '/projects': () => <ManageProjects/>,
    '/settings': () => <Settings/>,
    '/users': () => <ManageUsers/>,
    '/project/:id': () => <ManageValves/>,
    '/project/:projectId/valve/:id': () => <ValveDetail/>,
    '/standards': () => <ManageStandards/>,
    '/standards/:termId': () => <Standards/>,
    '/valvelist': () => <ValveList/>,
    '/enquiries': () => <EnquiryList />,
    '/project-report/:id': () => <ProjectReport/>
};

const AppRoutes = () => {
    useRedirect('/', '/dashboard');
    const pages = useRoutes(routes);
    return (
        <div>
            <Header/>
            <div className="main-content w3-padding">
                {pages}
            </div>

            <div className="app-footer">
                <img src={img}/>
                <div className="copy-right">
                    Copyright © 2020 Xylem Inc. All rights reserved.

                </div>

            </div>
        </div>
    );
};

export default AppRoutes;
