
import React, { useEffect, useState } from 'react';
import BFVCalculator from './BFVCalculator';
import ResultPage from './ResultPage';
import { Grid, Button, makeStyles, ButtonGroup } from '@material-ui/core';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import createPhysicsModel from './physics.js';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'; 
import RoleChecker from './../Common/RoleChecker';
import ToolTip from './../Common/ToolTip';

const useStyles = makeStyles({
  marginTop15: {
    marginTop: '15px'
  },
  image: {
    minWidth: '40%',
    maxWidth: '100%',
    maxHeight:'240px',
    pageBreakInside:'avoid'
  },
  textTransform: {
    textTransform: 'none'
  }
})


function Geometry(props: any) {

  const { paramObj, saveParamsHandler, saveResultObjHandler, projectId, valveId, savedParams } = props;
  const [result, setResult] = useState();
  const [renderResult, setRenderResult] = useState({ 
    pdBalance:0, 
    theta:0, 
  });
  const classes = useStyles();

  useEffect(() => { 
    if (!paramObj) {
      return;
    }
    if (paramObj.json && paramObj.json.theta) {
      calculateWithTheta(paramObj.json.theta, paramObj.json.balance);
    } else {
      calculateOptimal();
    }
  }, [paramObj]);
 

  const calculateWithTheta = (angle : number | null , balance: number = 0) => {

    const calc = new BFVCalculator(paramObj, balance);
    const result: any = calc.calculatePassRange(angle);

    createPhysicsModel('phaser_canvas', {
      animate: true,
      calc, 
      result: result.optimalValue, 
    });

    setRenderResult({
      pdBalance: balance, 
      theta: result.optimalValue.theta
    });
    setResult(result);
  };

  const increaseAngle = () => {
    const angle = renderResult.theta + 1;
    if ((paramObj.valveOpenDirection === 'Clockwise' && angle < 90 && angle >= 0)
      || (paramObj.valveOpenDirection === 'Anticlockwise' && angle >= -90 && angle < 0)
    ) {
      calculateWithTheta(angle, renderResult.pdBalance);
    }
  };


  const reduceAngle = () => {
    const angle = renderResult.theta - 1;
    if ((paramObj.valveOpenDirection === "Clockwise" && angle <= 90 && angle > 0)
      || (paramObj.valveOpenDirection === "Anticlockwise" && angle > -90 && angle <= 0)
    ) {
      calculateWithTheta(angle, renderResult.pdBalance);
    }
  };
  const movePDUp = () => {
    const angle = renderResult.theta;
    calculateWithTheta(angle, renderResult.pdBalance + 2);
  };

  const movePDDown = () => {
    const angle = renderResult.theta;
    calculateWithTheta(angle, renderResult.pdBalance - 2);
  };

  const calculateOptimal = () => {
    calculateWithTheta(null, renderResult.pdBalance);
  }

  let info: any = null; 
  if (!paramObj) {
    return <div className="w3-padding w3-padding-64">Please press calculate button on parameters tab to see the results.</div>
  }

  if (paramObj.valveType === 'unknown') {
    return <div className="w3-padding w3-padding-64">We don't have simulation for this type of valve. We suggest you to fully open the ButterFly Valve during inspection.</div>
  }

  if (result) {
    info = <div className="w3-margin-top">
      <table className="w3-table w3-table-all">
        <thead>
          <tr><th colSpan={2} align="center">Algorithmic Values Considered</th></tr>
        </thead>
        <tbody>
          <tr>
            <td>Entry Space</td>
            <td>{result.optimalValue.rEn} Inches</td>
          </tr>
          <tr>
            <td>Exit Space</td>
            <td>{result.optimalValue.rEx} Inches</td>
          </tr>
          <tr>
            <td>Score</td>
            <td>{result.optimalValue.score}</td>
          </tr>
          <tr>
            <td>Projection</td>
            <td>{result.optimalValue.tp} Inches</td>
          </tr>
        </tbody>
      </table>
    </div>
  } else {
    info = <div className="w3-red w3-round w3-large w3-padding w3-center w3-margin">Unable to Identify a valid Pass Range</div>;
  } 
  return (
    <div className={`w3-padding ${classes.marginTop15}`}>
      <Grid container spacing={2} justify="space-between" alignItems="flex-start">
        <Grid item sm={6} md={4}>
          <Grid container spacing={2} direction="column">
            <Grid item md={12}>
              <Grid container spacing={2} direction="column">
                <Grid item lg={12} md={12} sm={12} className="w3-center">
                  <Button variant="contained" color="primary" onClick={calculateOptimal}>
                    Calculate Optimal
                  </Button>
                </Grid>
                <Grid item lg={12} md={12} sm={12} className="w3-center">
                  <ButtonGroup variant="outlined" color="primary" aria-label="outlined primary button group">
                      <Button className={classes.textTransform} color="primary" onClick={increaseAngle}>
                        <ToolTip title="Rotate Clockwise" position={'bottom'}>
                            <RotateRightIcon />
                        </ToolTip>
                      </Button>
                      <Button className={classes.textTransform} color="primary" onClick={reduceAngle}>
                        <ToolTip title="Rotate Anti Clockwise" position={'bottom'}>
                            <RotateLeftIcon />
                        </ToolTip>
                      </Button>
                      <Button className={classes.textTransform} color="primary" onClick={movePDDown} 
                          disabled={renderResult.pdBalance < -2}>
                        <ToolTip title="Move PD down by two inches" position={'bottom'}>
                          <ArrowDownwardIcon />
                        </ToolTip>
                      </Button>
                      <Button className={classes.textTransform} color="primary" onClick={movePDUp} 
                          disabled={renderResult.pdBalance > 2}>
                          <ToolTip title="Move PD up by two inches" position={'bottom'}>
                            <ArrowUpwardIcon />
                          </ToolTip>
                      </Button>
                  </ButtonGroup>
                </Grid>
                <Grid item lg={12} md={12} sm={12} className="w3-center">
                  <table className="w3-table w3-table-all">
                    <tbody>
                    <tr><td>Theta</td>
                      <td>{ renderResult.theta}</td>
                    </tr>
                    <tr><td>Balance</td> 
                      <td>{renderResult.pdBalance}</td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="w3-right-align">
                        <RoleChecker roleName={'team_lead'} aclList={[
                        {
                          type: 'project',
                          refId: projectId
                        }
                      ]}>
                        <Button variant="outlined" color="primary" onClick={() => saveParamsHandler(renderResult)}>Save Params</Button>
                        </RoleChecker>
                      </td> 
                    </tr>
                    </tbody>
                  </table>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <table className="w3-table w3-table-all">
                <tbody>
                  <tr>
                    <td>Valve Type</td>
                    <td>{paramObj.valveType === 'humpBack' ? 'Hump Back' : 'Flat Plate'}</td>
                  </tr>
                  <tr>
                    <td>Valve Diameter</td>
                    <td>{paramObj.valveSize} Inches</td>
                  </tr>
                  <tr>
                    <td>Plate Thickness</td>
                    <td>{paramObj.plateThickness} Inches</td>
                  </tr>
                  <tr>
                    <td>Shaft Eccentricity</td>
                    <td>{paramObj.eccentricity} Inches</td>
                  </tr>
                  <tr>
                    <td>Max PipeDiver Dia</td>
                    <td>{paramObj.pipeDiver.dia} Inches</td>
                  </tr>
                  <tr>
                    <td>PipeDiver Nose Dia</td>
                    <td>{`6`} Inches</td>
                  </tr>

                  <tr>
                    <td>PipeDiver Platform</td>
                    <td>{paramObj.pipeDiver.label}</td>
                  </tr>
                </tbody>
              </table>
              {info}
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={6} md={8} style={{ overflow: 'hidden' }}>
          <Grid container spacing={1} direction="column">
            <Grid item xs={12}>
              <div id="phaser_canvas"></div>
            </Grid>
            <Grid item xs={12}>
              { paramObj.valveType === 'flatPlate' &&
                <Grid className="w3-center w3-image">
                  <img src="https://dist.d.ceegees.in/xylem/drawing_fp.jpg" alt="Flate Plate" className={classes.image} />
                </Grid>
              }
              { paramObj.valveType === 'humpBack' && paramObj.valveOpenDirection === 'Clockwise' &&
                <Grid className="w3-center w3-image">
                  <img src="https://dist.d.ceegees.in/xylem/drawing_hb.jpg" alt="Hump Back" className={classes.image} />
                </Grid>
              }
              { paramObj.valveType === 'humpBack' && paramObj.valveOpenDirection === 'Anticlockwise' &&
                <Grid className="w3-center w3-image">
                  <img src="https://dist.d.ceegees.in/xylem/drawing_hb_anti_clock.jpg" alt="Hump Back" className={classes.image} />
                </Grid>
              }
              <ResultPage result={result} paramObj={paramObj}  saveResultObjHandler={saveResultObjHandler}/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div >
      </div>
    </div>
  );

}

export default Geometry;
