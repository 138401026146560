
import React from 'react';
import { connect } from 'react-redux';

{/* <RoleCheck aclList={[
    {
        userId:1,
        type:'project',
        refId: 1
    }
]} >
</RoleCheck> */}
function RoleChecker(props:any) {
    const {userRoles, aclList , adminAccess= true, roleName , noAccessMessage=null } = props; 
    if(!userRoles.data || !userRoles.data.data ) {
        return null;
    }
    const roleData = userRoles.data.data;
    let hasAccess = adminAccess &&  roleData.find((item:any)  => item.type === 'admin');
    
    if (!hasAccess) {
        hasAccess = roleData.some((item:any) => aclList && aclList.some((acl:any) => ((acl.refId == item.refId) && (acl.type === item.type) && (item.roles.name === 'project_admin') || (item.roles.name === roleName))));    
    }
    if (hasAccess) {
        return props.children
    } else if(noAccessMessage) {
        return <div className="w3-center w3-padding-64">{noAccessMessage}</div>
    } else {
        return null;
    }
}

  const mapStateToProps = (state:any) => {
    return {
      userRoles : state.currentUserRoles
    }
  }
  
export default connect(mapStateToProps)(RoleChecker);