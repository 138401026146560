import React from 'react';
import { AppBar, Toolbar, Typography, } from '@material-ui/core';

const img = 'https://cdn.ceegees.in/xylem/logo.png';
const TopBar = () => {
    return (
        <AppBar position="fixed" className="appBar">
        <Toolbar>
            <Typography variant="h5">
                <img src={img} style={{height:"48px"}} />
            </Typography>
        </Toolbar>
        </AppBar>
    )
};

export default TopBar;
