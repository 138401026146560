import { AppBar, Box, Button, Grid, Paper, Tab, Tabs, Typography, Breadcrumbs, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { A, usePath } from 'hookrouter';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getValveInfo, setStoreData, getProjectDetailsById, postSaveParams } from '../../Redux/actions';
import EnquiryModal from '../Enquiries/EnquiryModal';
import Geometry from './Geometry';
import ParametersForm from './ParametersForm';
import ResultPage from './ResultPage';
import FeedBackForm from './feedback';
import FeedBackList from './FeedBackList';
import DialogModal from '../Common/DialogModal';
import Loader from '../Common/Loader';
import AlertDialog from '../Common/AlertDialog';
import RoleChecker from '../Common/RoleChecker';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: '10px',
    width: '100%'
  },
  buttonSection: {
    padding: '40px'
  },
  valveInfo: {
    padding: '0px 35px 20px 35px'
  },
  breadCrumbBox: {
    height: '50px',
    padding: '15px'
  },
  noDecoration: {
    textDecoration: 'none'
  },
  table: {
    minWidth: 700
  },
  operatorList: {
    wordBreak: 'break-word'
  }
}));

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {children}
    </Typography>
  );
}

function a11yProps(index: number) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`
  };
}

function ValveDetails(props: any) {

  const classes = useStyles();
  const dispatch: any = useDispatch();
  const [selectedTab, setSelectedTab] = useState(0);
  const [paramObj, setParamsObj] = useState();
  const [ showLoader, setShowLoader] = useState(false);
  const [alertMessage, setAlertMessage] = useState(); 
  const state: any = useSelector(state => state);
  const [open, setOpen] = useState(false);
  
  const path = usePath();
  const splitedPath = path.split('/');
  const currentProjectId: number = parseInt(splitedPath[2])
  const currentValveId: number = parseInt(splitedPath[4]);
  const [valveInfo, setValveInfo] = useState();
  const { currentProject } = state;
  const [ resultObj, setResultObj] = useState();
  const [ savedParamsObj, setSavedParamsObj] = useState();

  const getValveInfoX = () => {
    dispatch(getValveInfo(currentValveId)).then((res: any) => {
      if (res && res.status === 200) {
        setValveInfo(res.data);
      }
    });
  };

  useEffect(() => {
      dispatch(getProjectDetailsById(currentProjectId)).then((res: any) => {
        const respData = res.data;
        if (respData.success) {
          dispatch(setStoreData('currentProject', respData.data))
        }
      })
    getValveInfoX();
  }, [1]);

  const onTabChange = (e: any, value: any) => {
    setSelectedTab(value);
  };

  const updateParamObj = (val: any) => {
    setParamsObj(val);
  }; 

  const updateSavedParamObj = (val: any) => {
    setSavedParamsObj(val);
  }; 

  const handleSaveParams = (params: any)=>{
    setShowLoader(true);
    const paramData = {
      projectId:currentProjectId,
      valveId:currentValveId,
      theta:params.theta,
      balance:params.pdBalance,

    }
    
    dispatch(postSaveParams(paramData)).then((resp:any)=>{
      setShowLoader(false);
      const res = resp && resp.data;
        if (res && res.success) {
          setSavedParamsObj(res.data.json);
          setAlertMessage(res.message);
        }else if(res && !res.success){
          setAlertMessage(res.message);
        }
    })
  }

  const handleSaveResult = (result:any) =>{
    setResultObj(result);
  }

  if (!valveInfo){
    return <div className="w3-padding-64 w3-center">Loading...</div>
  }

  return (
    <div className={classes.root}>
      <Paper square>
        {valveInfo &&
        <Grid container>
          <Grid item xs={12} md={12}>
            {currentProject && <Box bgcolor="#EEEEEE" className={classes.breadCrumbBox}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href={`/project/${currentProjectId}`}>
                  {currentProject.client}
                </Link>
                <Typography
                  color="textPrimary"
                  aria-current="page">
                  {valveInfo.valveNo}
                </Typography>
              </Breadcrumbs>
            </Box>}
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container className={classes.valveInfo} justify="space-between" alignItems="center">
              <Grid item className="w3-padding" xs={12} md={5}>
                <Typography variant="h6" component="h6">{valveInfo.name}</Typography>
                <Typography>Location : {valveInfo.location}</Typography>
              </Grid>
              <Grid item className="w3-padding" xs={12} md={5}>
                <Typography>Operating Date : {moment(valveInfo.valveDate).format('DD/MM/YYYY')}</Typography>
              </Grid>
              <Grid item className="w3-padding" xs={12} md={2}>
                <Button
                  color="primary"
                  variant="outlined"
                  size="small"
                  className="w3-right"
                  onClick={() => {
                    setOpen(true);
                  }}
                >Make an enquiry
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        }

        <AppBar position="static" color="default">
          <Tabs
            value={selectedTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={onTabChange}
            aria-label="disabled tabs example"
          >
            <Tab label="Parameters" {...a11yProps(0)}/>
            {/* <RoleChecker>   */}
              <Tab label="Result" {...a11yProps(1)}/> 
            {/* </RoleChecker>  */}
             {/* <RoleChecker>  */}
              <Tab label="Post Inspection Summary"  {...a11yProps(2)}/>
            {/* </RoleChecker>  */}
          </Tabs>
        </AppBar>
        <div>
          <TabPanel value={selectedTab} index={0}>
            {valveInfo &&
            <ParametersForm valveId={currentValveId} 
                updateParamObj={updateParamObj}
                onTabChange={onTabChange} 
                getValveInfo={getValveInfoX} 
                valveInfo={valveInfo} 
                resultObj={resultObj} 
                updateSavedParamObj={updateSavedParamObj}/>
            }
          </TabPanel>
          
          <TabPanel value={selectedTab} index={1}>
            <RoleChecker noAccessMessage="You don not have access to this section" aclList={[
              {
                type: 'project',
                refId: valveInfo.projectId
              }
            ]}>
            <Geometry paramObj={paramObj} 
              saveParamsHandler={handleSaveParams} 
              saveResultObjHandler={handleSaveResult} 
              projectId={currentProjectId} 
              savedParams={savedParamsObj}/>
            </RoleChecker>
            {open ? (
              <DialogModal
                fullWidth
                isOpen={open}
                hideTitle={true}
                hideBtns={true}
                maxWidth="sm"
                dialogContent={(
                  <EnquiryModal
                    open={open}
                    enquiryData={null}
                    valveId={currentValveId}
                    handleClose={() => {
                      setOpen(false);
                    }}
                  />
                )}
              />)
              : null}
          </TabPanel>
          <TabPanel value={selectedTab} index={2}>
            <RoleChecker noAccessMessage="You don not have access to this section"  aclList={[
              {
                type: 'project',
                refId: valveInfo.projectId
              }
            ]}>
            <div>
            {
             valveInfo && (valveInfo.status === 'Active' || valveInfo.status === 'Pending Approval') && <div className='w3-padding w3-padding-64'>
              The valve parameters need to be approved to add Post Inspection Summary..
            </div>
            }
            {
              valveInfo && valveInfo.status === 'Approved' &&
            <div>
              <FeedBackForm valveId={currentValveId} paramObj={paramObj} getValveInfo={getValveInfoX} valveInfo={valveInfo}/>
            </div>
            }
            {
              valveInfo && (valveInfo.status === 'Passed' || valveInfo.status === 'Failed' || valveInfo.status === 'Modified') &&
                <div>
                  <FeedBackList valveId={currentValveId} paramObj={paramObj}/>
                </div>
            }
            </div>
            </RoleChecker>
          </TabPanel>
       
        </div>
      </Paper>
      {alertMessage
        ? (
          <AlertDialog
            handleClose={() => {
              setAlertMessage(null);
            }}
            message={alertMessage}
          />
        ) : null}
        { showLoader ? <Loader open={showLoader}/> : null}
    </div>
  );

}

export default ValveDetails;
