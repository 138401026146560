import queryString from 'querystring';
import axios from 'axios';
import api from './api';

const requestMap: any = api;
export const actions = {
    FETCH_REQUEST: 'FETCH_REQUEST',
    FETCH_REQUEST_SUCCESS: 'FETCH_REQUEST_SUCCESS',
    FETCH_REQUEST_ERROR: 'FETCH_REQUEST_ERROR',
    SET_DATA: 'SET_DATA'
};

const config: any = {
    headers: {}
};
 
export const BFV_CONST = {
    TYPE_FLAT_PLATE :'flatPlate',
    TYPE_HUMP_BACK:'HumpBack',
    ROTATION_CLOCKWISE:'ClockWise',
    ROTATION_ANTICLOCKWISE:'AntiClockWise'
};

export const PD_CONFIG = [
    {
        id:'Mini_Pipe_Diver',
        label:'MiniPD',
        allowedRanges: [16, 18, 20, 24, 26, 30, 32, 36, 40, 42, 48, 52, 54],
        dia: 8.5,
        numberOfBodies:3,
    },
    {
        id:'MiniPD_24D',
        label:'MiniPD 24D',
        allowedRanges: [18, 20, 24, 26, 30, 36, 42, 48],
        dia: 9.5,
        numberOfBodies:4,
    },
    {
        id:'PD_Ultra',
        label:'PD Ultra',
        allowedRanges: [18, 20, 24, 26, 30, 36, 42, 48],
        dia: 9.5,
        numberOfBodies:3,
    },
    {
        id:'LDPD_V2_3_Body',
        label:'LDPD V2_3_Body',
        allowedRanges: [48, 54, 60, 66, 72, 78, 84, 90, 96, 102, 108, 114, 120],
        dia: 11.5,
        numberOfBodies:3,
    },
    {
        id:'LDPD_V2_4_Body',
        label:'LDPD V2 4 Body',
        allowedRanges: [48, 54, 60, 66, 72, 78, 84, 90, 96, 102, 108, 114, 120],
        dia: 14.5,
        numberOfBodies:4,
    }
];

if (localStorage.getItem('bfv_access_token')) {
    config.headers['Authorization'] = 'bearer ' + localStorage.getItem('bfv_access_token');
}

const axiosApiCall: any = axios.create(config);

export const setStoreData = (key: string, value: any) => {
    return {
        type: actions.SET_DATA,
        key,
        value
    };
};

export const fetchDataRequest = (key: string) => {
    return {
        type: actions.FETCH_REQUEST,
        key
    };
};

export const fetchDataRequestError = (key: string, error: any) => {
    return {
        type: actions.FETCH_REQUEST_ERROR,
        key,
        error
    };
};


export const fetchResponseSuccess = (key: string, data: any) => {
    return {
        type: actions.FETCH_REQUEST_SUCCESS,
        key,
        data
    };
};

export const fireRequest = (
    key: string, path: any = [], params: any = {}
) => {
    return (dispatch: any) => {
        const request = Object.assign({}, requestMap[key]);
        if (path.length > 0) {
            request.path += '/' + path.join('/');
        }

    if (request.method === undefined || request.method == 'GET') {
        request.method = 'GET';
        const qs = queryString.stringify(params);
        if (qs !== '') {
            request.path += `?${qs}`;
        }
    }

        dispatch(fetchDataRequest(key));
        return axiosApiCall[request.method.toLowerCase()](request.path, params)
            .then((response: any) => {
                dispatch(fetchResponseSuccess(key, response.data));
                return response;
            })
            .catch((error: any) => {
                dispatch(fetchDataRequestError(key, error));
                return error.response;
            });
    };
};

// auth
export const postCreateUser = (form: object) => {
    return fireRequest('createUser', [], form);
};

export const getCurrentUser = () => {
    return fireRequest('currentUser');
};

export const changeAdminStatus = (userId: number, type: string) => {
    return fireRequest('updateAdminStatus', [], {
        userId,
        type
    });
};

export const getRoleUsers = (roleName: string) => { 
  return fireRequest('getRoleUsers', [], {
    role: roleName,
    type: 'nothing'
  });
};


export const getCurrentUserRoles = () => {
    return fireRequest('currentUserRoles');
};

export const postRegister = (form: object) => {
    return fireRequest('register', [], form);
};

export const postLogin = (form: object) => {
    return fireRequest('login', [], form);
};

export const postForgotPassword = (form: object) => {
    return fireRequest('forgotPassword', [], form);
};

export const postResetPassword = (form: object) => {
    return fireRequest('resetPassword', [], form);
};

export const getAllUsers = () => {
    return fireRequest('usersList');
};

export const changeUserPassword = (form: object) => {
    return fireRequest('changeUserPassword', [], form);
};
export const changeUserName = (form: object) => {
    return fireRequest('changeUserName',[], form);
};
// project
export const deleteProject = (id: number) => {
    return fireRequest('deleteProject', [id], {});
};

export const getProjectList = (paginate: object = {}) => {
    return fireRequest('projectList', [], paginate);
};

export const postCreateProject = (form: object) => {
    return fireRequest('createProject', [], form);
};

export const postUpdateProject = (form: object) => {
    return fireRequest('editProject', [], form);
};

export const getFilterProjectList = (filterData: object) => {
    return fireRequest('filterProjects', [], filterData);
};

export const getProjectReport = (projectId: number) => {
    return fireRequest('projectReport', [projectId], {});
}

export const getProjectDetailsById = (projectId: number) => {
    return fireRequest('projectDetails', [projectId], {});
}

//invite

export const getInvitedUserList = (paginate: object) => {
    return fireRequest('invitedUserList', [], paginate);
};

export const postInviteUser = (form: object) => {
    return fireRequest('inviteUser', [], form);
};

export const postAcceptInvite = (form: object) => {
    return fireRequest('acceptInvite', [], form);
};

export const updateInviteUser = (form: object, id: number) => {
    return fireRequest('editInviteUser', [id], form);
};

export const deleteInviteUser = (id: number) => {
    return fireRequest('deleteInviteUser', [id], {});
};

export const getValidateTokenStatus = (token: string) => {
    return fireRequest('validateToken', [token], {});
};

// valves

export const getValveList = (paginate: object) => {
    return fireRequest('valveList', [], paginate);
};

export const getValveVersions = (id: number) => {
    return fireRequest('getAllVersions', [], {id});
};

export const postCreateValveVersion = (form: object) => {
    return fireRequest('createValveVersion', [], form);
};

export const getValveListAll = () => {
    return fireRequest('valveListAll', [], {});
};

export const postCreateValve = (form: object) => {
    return fireRequest('createValve', [], form);
};

export const putEditValve = (form: object, id: number) => {
    return fireRequest('updateValve', [id], form);
};

export const destroyValve = (id: number, projectId:number) => {
    return fireRequest('deleteValve', [id,projectId]);
};

//roles
//--not used now
// export const listRoleUsersByRefId = (refId: number, type: string) => {
//   return fireRequest('roleUsersByRefId', [refId, type], {});
// };

export const findUser = (searchData: object) => {
    return fireRequest('findUser', [], searchData);
};

//valve
export const findValve = (searchData: object) => {
    return fireRequest('findValve', [], searchData);
};

export const findProjects = (searchData: object) => {
    return fireRequest('findProjects', [], searchData);
};

export const createValveDetail = (form: object) => {
    return fireRequest('createValveDetail', [], form);
};

export const findValveDetailById = (id: number) => {
    return fireRequest('getValveDetailById', [id], {});
};

export const editValveDetail = (form: object, id: number) => {
    return fireRequest('updateValveDetail', [id], form);
};

export const getValveInfo = (id: number) => {
    return fireRequest('valveInfo', [id], {});
};

export const postSaveParams = (paramData: object) => {
    return fireRequest('saveParams', [], paramData);
};

//standards
export const getStandards = (termId: number) => {
    return fireRequest('getStandards', [], {termId});
};

//Enquiries
export const postCreateEnquiry = (form: object) => {
    return fireRequest('createEnquiry', [], form);
};

export const getAllEnquiryList = (paginate: object) => {
    return fireRequest('enquiryList', [], paginate);
};

export const putUpdateEnquiry = (form: object, id: number) => {
    return fireRequest('updateEnquiry', [id], form);
};

//Dashboard

export const getDashboardStats = () => {
    return fireRequest('dashboardStats');
};

export const getDashEnquiryStats = () => {
    return fireRequest('enquiryStats');
};

// Meta
export const getAllMeta = () => {
    return fireRequest('getAllMeta');
};

export const addMeta = (form: object) => {
    return fireRequest('addMeta', [], form);
};

export const getMetaByName = () => {
    return fireRequest('getMetaByName', [], {});
};

export const deleteMeta = (id: number) => {
    return fireRequest('deleteMeta', [], {id});
};

export const getAllTerms = (id: number) => {
    return fireRequest('getAllTerms', [], {id});
};

export const addTerm = (form: object) => {
    return fireRequest('addTerm', [], {form});
};

export const getTermById = (id: number) => {
    return fireRequest('getTermById', [], {id});
};

export const updateTerm = (form: object) => {
    return fireRequest('updateTerm', [], {form});
};

export const deleteTerm = (id: number) => {
    return fireRequest('deleteTerm', [id], {});
};

export const findTerm = (search: string) => {
    return fireRequest('findTerm', [], {search});
};

export const getAllValvesList = (paginate: object = {}) => {
    return fireRequest('getAllValveList', [], paginate);
};

export const manufacturerFilter = (search: string) => {
    return fireRequest('valveList', [], {search});
};

export const valveListFilter = (paginate: object = {}) => {
    return fireRequest('getAllValveList', [], paginate);
};
export const feedback = (form: object) => {
    return fireRequest('feedback', [], form);
};

export const approval = (status: string, id: number) => {
    return fireRequest('approval', [], {status, id});
};

export const getVersion = () => {
    return fireRequest('versionNumber', [], {});
};

export const postResult = (resultData: object) => {
    return fireRequest('resultInfo', [], resultData);
};

export const getFirstUser = () => {
    return fireRequest('firstUser', [], {});
};

export const logout = () => {
    return fireRequest('logout', [], {});
}