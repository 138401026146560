import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, Theme, InputBase, Box, Card, CardHeader, CardContent, Fade, CircularProgress, Breadcrumbs, Link, Tooltip } from '@material-ui/core';
import { makeStyles, withStyles, createStyles, fade } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { navigate, usePath, A } from 'hookrouter';
import AlertDialog from '../Common/AlertDialog';
import ValveModal from './ValveModal';
import { getValveList, destroyValve, findValve, setStoreData, getProjectDetailsById } from '../../Redux/actions';
import SearchBox from '../Common/SearchBox';
import Pagination from '../Common/Pagination';
import RoleChecker from '../Common/RoleChecker';
import DialogModal from '../Common/DialogModal';
import ConfirmDialog from '../Common/ConfirmDialog';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: '8px',
  },
  card: {
    width: '100%',
    height: 140,
    backgroundColor: '#FFFFFF',
    cursor: 'pointer',
    overFlow: 'scroll',
  },
  cardHeaderPadding: {
    padding: '8px'
  },
  spacing: {
    marginLeft: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
  addValveCard: {
    marginTop: '50px',
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 400,
    //padding: '10px',
    //fontSize: '14px',
    display:'inline-block',
    [theme.breakpoints.up('md')]: {
      width: '12vw'
    },
    [theme.breakpoints.down('sm')]: {
      width: '50vw'
    },
    [theme.breakpoints.down('xs')]: {
      width: '75vw'
    }
  },
  content: {
    //marginBottom: '5px',
    padding: '8px'
  },

  contentText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display:'inline-block',
    lineHeight: 1.2,
    [theme.breakpoints.up('md')]: {
      width: '16vw'
    },
    [theme.breakpoints.down('sm')]: {
      width: '40vw'
    },
    [theme.breakpoints.down('xs')]: {
      width: '40vw'
    }
  },
  padding10:{
    padding:'10px'
  },
  noDecoration:{
      textDecoration:'none'
  },
  paginateTopPadding:{
    paddingTop:'50px'
  },
  projectInfoDiv:{
      background: 'whitesmoke',
      padding: '10px 15px',
      display: 'flex',
      flexWrap: 'wrap',
      borderRadius:'5px'
  },
  projectUsers:{
    width:'100%',
    display:'flex',
    flexWrap:'wrap',
  },
  sideTitle:{
    color: 'darkslategray',
    fontWeight: 'bold',
    fontSize:'14px'
  },
  valveInfoTitle:{
    fontSize: '13px'
  },
  toolTip:{
    fontSize:'13px'
  }
}));

export default function ManageValves(props: any) {

  const classes = useStyles();
  const dispatch: any = useDispatch();
  const initialData: any[] = [];
  let manageValves: any = null;
  const [data, setData] = useState(initialData);
  const [userList, setUserList] = useState();
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState();
  const [alertMessage, setAlertMessage] = useState();
  const state: any = useSelector(state => state);
  const { valveList, currentProject } = state;
  const [anchorEl, setAnchorEl] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const menuOpen = Boolean(anchorEl);
  const path = usePath();
  const splitedPath = path.split('/');
  const currentProjectId: number = parseInt(splitedPath[2]);
  const [isLoading, setIsLoading] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [ searchStr, setSearchStr] = useState('');
  const limit = 11;
  const initialPaginateData = {
    id:currentProjectId,
    search:'',
    page:1,
    offset: 0,
    limit
  }
  const [ currentPage, setCurrentPage ] = useState(1);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [selectedValve, setSelectedValve] = useState<any>(Object.assign({}));

  const fetchData = (paginateData: any) => {
    setIsLoading(true);
    dispatch(getValveList(paginateData)).then((res: any) => {
      if (res && res.status === 200) {
        const respData = res.data
        setData(respData.data);
        setTotalCount(respData.total);
        setIsLoading(false);
      }
    });
  };

  const fetchSearchData = (searchData:any)=>{
    setIsLoading(true);
    dispatch(findValve(searchData)).then((res: any) => {
      setData(res.data.data);
      setTotalCount(res.data.total);
      setIsLoading(false);
    });
  }

  useEffect(() => {
    if(!currentProject){
      dispatch(getProjectDetailsById(currentProjectId)).then((res:any)=>{
        const respData = res.data;
        if(respData.success){
          dispatch(setStoreData('currentProject', respData.data))
        }
      })
    }
    setCurrentPage(1);
    fetchData(initialPaginateData);
  }, [dispatch]);

  const handleSearch = async (e: any) => {
    const search = e.target.value.toLowerCase();
    const searchQueryData = {
      id: currentProjectId,
      search,
      page:1,
      offset: 0,
      limit
    }

    if (search !== '') {
      setSearchStr(search);
      fetchSearchData(searchQueryData);
    } else if (search === '') {
      setSearchStr('');
      fetchData(initialPaginateData);
    }
    setCurrentPage(1);
  };

  const handleMenuClick = (event: any, id: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedItem(id);
  };

  const handleMenuClose = () => {
    const value: any = null;
    setAnchorEl(value);
    setSelectedItem(value);
  };

  const handleEditValve = (item: object) => {
    setOpen(true);
    setEditData(item);
    handleMenuClose();
  };

  const handleDeleteValve = () => {
    if(selectedValve){
      setShowConfirmDialog(false);
      handleMenuClose();
      dispatch(destroyValve(selectedValve.id, currentProjectId)).then((resp: any) => {
        const res = resp && resp.data;
        if (res && res.success) {
          setAlertMessage('Valve deleted successfully...!');
          setSelectedValve({});
          setCurrentPage(1);
          fetchData(initialPaginateData);
        } else {
          setAlertMessage('Valve deletion failed...!');
        }
      });
    }
  };

  const handlePagination = (page: any, perPage: any) =>{
    setCurrentPage(page);
    const paginateData = {
      id:currentProjectId,
      page,
      offset:perPage,
      limit
    }
    if(searchStr !==''){
      Object.assign(paginateData, {search:searchStr});
      fetchSearchData(paginateData)
    }else{
      fetchData(paginateData);
    }
  }

  const triggerDelete = (valve: any)=>{
    setSelectedValve(valve);
    setShowConfirmDialog(true);
  }

  const addValveCard = <Grid item xs={12} md={3} className={classes.root} >
    <Card className={`add-item-card `} onClick={() => setOpen(true)} style={{ cursor: 'pointer', height:"140px" }}>
      <Typography className={classes.addValveCard} display="block" align="center">
        + Add New Valve
      </Typography>
    </Card>
  </Grid>;

  let ValveList: any[] = [];
  if (data && data.length) {
    ValveList = data.map((valve: any, idx: number) => (
      <Grid item xs={12} md={3} key={`valve_${valve.id}`} className={classes.root}>
        <Card className={classes.card} onClick={(e) => {
          navigate(`/project/${currentProjectId}/valve/${valve.id}`);
        }}>
          <CardHeader
            className={classes.cardHeaderPadding}
            action={
              <RoleChecker aclList = {
                [{
                  type: 'project',
                  refId: valve.projectId,
                }]
              }>
                {
                  currentProject && currentProject.status !== 'Completed' &&
                  <IconButton aria-label="settings" aria-controls="fade-menu" aria-haspopup="true"
                              onClick={(e) => handleMenuClick(e, valve.id)}>
                    <MoreVertIcon/>
                  </IconButton>
                }
              </RoleChecker>
            }
            title={<span className={classes.title}><Tooltip title={<span className={classes.toolTip}>{valve.name}</span>} interactive={true} arrow={true}><span>{valve.name}</span></Tooltip></span>}
          />

          <CardContent className={classes.content}>
            <Typography className={classes.contentText}><span className={`w3-text-gray ${classes.valveInfoTitle}`}>Valve ID - </span>{valve.valveNo}</Typography>
            <Typography className={classes.contentText}><span className={`w3-text-gray ${classes.valveInfoTitle}`}>Location - </span>{valve.location}</Typography>
            <Typography className={classes.contentText}><span className={`w3-text-gray ${classes.valveInfoTitle}`}>Status - </span>{valve.status}</Typography>
          </CardContent>
        </Card>

        <Menu
          key={valve.id}
          anchorEl={anchorEl}
          keepMounted
          open={selectedItem === valve.id ? menuOpen : false}
          onClose={handleMenuClose}
          TransitionComponent={Fade}
        >
          <MenuItem onClick={(e) => {
            e.stopPropagation();
            handleEditValve(valve);
          }}>Edit</MenuItem>
            <MenuItem onClick={(e) => {
              e.stopPropagation();
              triggerDelete(valve)
            }}>Delete</MenuItem>
        </Menu>
      </Grid>
    ));
  }

  if (isLoading || !data) {
    manageValves = (
      <Grid item xs={12} md={12}>
        <div className="textMarginCenter">
          <CircularProgress/>
        </div>
      </Grid>
    );
  } else if (data && data.length) {
    manageValves = ValveList;

  } else if (data && data.length === 0) {
    manageValves = (
      <Grid item xs={6} md={3} className="textMarginCenter">
        <h5>No Valves Found</h5>
      </Grid>
    );
  }

  return (
    <div>
      <Box bgcolor="#EEEEEE"> 
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs={8} md={6}>
            {currentProject && <div className={classes.padding10}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="/projects">
                Projects
              </Link>
              <Typography
                color="textPrimary"
                aria-current="page">
                {currentProject.client}
              </Typography>
            </Breadcrumbs>
            </div>}
          </Grid>
          <Grid item xs={4} md={6}>
            <div className="w3-right-align">
                <SearchBox
                  value={searchStr}
                  placeholder={'Search Valves...'}
                  handleSearch={handleSearch}
                />
            </div>    
          </Grid>
        </Grid>
        </Box>
      {currentProject &&
        <div className={classes.projectInfoDiv}>
          <div className={classes.projectUsers}>
          <Typography>
            <span className={classes.sideTitle}>Project Managers</span> - {currentProject.projectUsers
              .filter((item: any) => item.roles.name === 'project_admin')
              .map((item: any) => (item.user.name)).join()}
            <br />

            <span className={classes.sideTitle}>Team Leads</span> - {currentProject.projectUsers
              .filter((item: any) => item.roles.name === 'team_lead')
              .map((item: any) => (item.user.name)).join()}
          </Typography>

          <div>
            <a className="w3-margin-left" href={`/project-report/${currentProject.id}`}>View Report</a> 
          </div>
           </div>       
          <div
            className={`description-text cursor-pointer ${isHover ? 'wrap-text' : 'no-wrap-text'}`}
            onClick={() => setIsHover(!isHover)}>
            <span className={classes.sideTitle}>Project Description</span>
            <br/>
            {currentProject.description}
          </div> 
        </div>
         
      }  
      <Grid container>
        <RoleChecker aclList={[{
          refId: currentProjectId,
          type:'project'
        }]}>
        { currentProject && currentProject.status !== 'Completed' ? addValveCard : null }
        </RoleChecker>
        {manageValves}
      </Grid>
    
      {( data && data.length >0 && totalCount > limit) && (
          <Grid container className={`w3-center ${classes.paginateTopPadding}`}>
            <Pagination
                cPage={currentPage}
                defaultPerPage={limit}
                data={{totalCount}}
                onChange={handlePagination}
            />
        </Grid>)}

      {open ? (<DialogModal
        fullWidth
        isOpen={open}
        hideTitle={true}
        hideBtns={true}
        maxWidth="sm"
        dialogContent={(
          <ValveModal
            triggerList={() => {
              setSearchStr('')
              setCurrentPage(1);
              fetchData(initialPaginateData)
            }}
            projectId={currentProjectId}
            open={open}
            editData={editData}
            handleClose={() => {
              setOpen(false);
              setEditData(null);
            }}
          />
        )}
      />)
        : null}
      {alertMessage ? (
        <AlertDialog
          handleClose={() => setAlertMessage(null)}
          message={alertMessage}
        />) : null}
      {showConfirmDialog
        ? (
          <ConfirmDialog
            handleCancel={() => {
              handleMenuClose()
              setSelectedValve({})
              setShowConfirmDialog(false)
            }}
            handleOk={handleDeleteValve}
            message={'Are you sure to delete the Valve?'}
          />
        ): null}
    </div>
  );
}
