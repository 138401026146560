import {Button, Grid, TextField} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {getInvitedUserList, postInviteUser, updateInviteUser, getCurrentUser } from '../../Redux/actions';
import AlertDialog from '../Common/AlertDialog';
import {ErrorHelperText} from '../Common/HelperInputFields';
import ModalComponent from '../Common/Modal';
import Loader from '../Common/Loader';

const useStyles = makeStyles({
    modalHeader: {
        padding: '15px',
        alignItems: 'center',
    },
    title: {
        fontWeight: 500,
        textAlign: 'center',
    },
    formGroup: {
        padding: '30px 40px',
    },
    marginBottom10: {
        marginBottom: '10px',
    },
    marginTop10: {
        marginTop: '10px',
    },
    label: {
        background: 'white',
        padding: '0px 5px',
    },
});

const UserModal = (props: any) => {
    const {open, handleClose, editData, triggerList} = props;
    const classes = useStyles();
    const state: any = useSelector(state => state);
    const { currentUser } = state;
    const dispatch: any = useDispatch();
    const [alertMessage, setAlertMessage] = useState();
    const error: any = {};
    const [errors, setErrors] = useState(error);
    let formData: any = {
        name: '',
        email: '',
    };
    if (editData) {
        formData = editData;
    }
    const [form, setForm] = useState(formData);
    const [ showLoader, setShowLoader ] = useState(false);

    const handleChange = (e: any) => {
        const {name, value} = e.target;
        const data: any = Object.assign({}, form);
        data[name] = value;
        const err = Object.assign({}, errors);
        if (err[name]) {
            err[name] = null;
        }
        setErrors(err);
        setForm(data);
    };

    const updateResponse = (resp: any, message: string) => {
        const res = resp && resp.data;
        if (res && res.success) {
            setAlertMessage(message);
            // dispatch(getInvitedUserList());
        } else {
            setAlertMessage(res.message);
        }
        setShowLoader(false);
        triggerList();
    };

    const validateData = () => {
        const err = Object.assign({}, errors);
        if (!form.name) {
            err.name = 'Field is Required';
        }
        if (!form.email) {
            err.email = 'Field is Required';
        }

        if (Object.keys(err).length > 0) {
            setErrors(err);
            return false;
        }
        return true;
    };

    const handleSubmit = () => {
        const checkForm = validateData();
        if (!checkForm) {
            return;
        }
        setShowLoader(true);
        if (form.id) {
            dispatch(updateInviteUser(form, form.id)).then((resp: any) => {
                if (resp.status === 200 || resp.status === 201) {
                    const respData = resp.data && resp.data.data; 
                    const currentUserData = currentUser && currentUser.data && currentUser.data.data
                    if(currentUserData && respData && currentUserData.id === respData.id){
                        dispatch(getCurrentUser())
                    }
                    updateResponse(resp, 'Invited User edited successfully...!');
                } else if (resp.status === 400) {
                    setAlertMessage('Enter a Valid XylemInc Email');
                }
            });
        } else {
            dispatch(postInviteUser(form)).then((resp: any) => {
                if (resp.status === 200 || resp.status === 201) {
                    updateResponse(resp, 'Invited Successfully...!');
                } else if (resp.status === 400) {
                    setAlertMessage('Enter a Valid XylemInc Email');
                }
            });
        }
    };
    console.log('currentUser :', currentUser);
    return (
        <div style={{paddingBottom: '20px'}}>
            <Grid container className={classes.modalHeader}>
                <Grid item xs={12} className={classes.title}>
                    {editData ? 'Edit User' : 'Add New User'}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} className={classes.marginBottom10}>
                    <TextField
                        required
                        fullWidth
                        variant='outlined'
                        label='Name'
                        margin='dense'
                        name='name'
                        value={form.name || ''}
                        onChange={handleChange}
                        InputLabelProps={{
                            classes: {
                                root: classes.label,
                            },
                        }}
                    />
                    <ErrorHelperText error={errors.name}/>
                </Grid>
                <Grid item xs={12} className={classes.marginBottom10}>
                    <TextField
                        disabled={editData ? true : false}
                        required
                        fullWidth
                        variant='outlined'
                        label='Email'
                        name='email'
                        value={form.email.toLowerCase() || ''}
                        onChange={handleChange}
                        margin='dense'
                        InputLabelProps={{
                            classes: {
                                root: classes.label,
                            },
                        }}
                    />
                    <ErrorHelperText error={errors.email}/>
                </Grid>
                <Grid item xs={12} className={classes.marginTop10}>
                    <Grid container spacing={2} justify='flex-start'>
                        <Grid item>
                            <Button
                                variant='contained'
                                size='small'
                                onClick={handleClose}
                            >Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                color='primary'
                                variant='contained'
                                size='small'
                                onClick={handleSubmit}
                            >
                                {editData ? 'Save' : 'Add New User'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {alertMessage ? (
                <AlertDialog
                    handleClose={() => {
                        setAlertMessage(null);
                        handleClose();
                    }}
                    message={alertMessage}
                />
            ) : null}
            { showLoader ? <Loader open={showLoader}/> : null}
        </div>
    );
};

export default UserModal;
