import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Paper, CircularProgress, Card, CardHeader, CardContent, Typography, Box, Button, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { deleteTerm, findTerm, getAllTerms} from '../../Redux/actions';
import { navigate } from 'hookrouter';
import AlertDialog from '../Common/AlertDialog';
import StandardsModal from './standardsModal';
import SearchBox from '../Common/SearchBox';
import axios from "axios";
import RoleChecker from '../Common/RoleChecker';
import TitleHeader from '../Common/TitleHeader';
import DialogModal from '../Common/DialogModal';
import ConfirmDialog from '../Common/ConfirmDialog';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: '10px',
  },
  card: {
    padding: 6,
    height: 140,
    width: '100%',
    backgroundColor: '#FFFFFF',
    cursor: 'pointer',
  },
  addBtn: {
    padding: 6,
    height: 140,
    background: '#FFFFFF',
  },
  addBtnText: {
    verticalAlign: 'middle',
    lineHeight: '130px',
    textAlign: 'center',
  },
  spacing: {
    marginLeft: theme.spacing(1),
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    [theme.breakpoints.up('md')]: {
      width: '12vw'
    },
    [theme.breakpoints.down('sm')]: {
      width: '40vw'
    },
    [theme.breakpoints.down('xs')]: {
      width: '65vw'
    }
  },
  toolTip:{
    fontSize:'13px'
  }
}));

function ManageStandards(props: any) {
  const classes = useStyles();
  const dispatch: any = useDispatch();
  const initialData: any[] = [];
  let manageTerms: any = null;
  const [data, setData] = useState(initialData);
  const initialUsers: any[] = [];
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedTerm, setTerm] = React.useState(null);
  const [alertMessage, setAlertMessage] = useState();
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState();
  const openMenu = Boolean(anchorEl);
  const state: any = useSelector(state => state);
  const { termList } = state;
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [selectedStandard, setSelectedStandard] = useState<any>(Object.assign({}));

  const fetchData = () => {
    setIsLoading(true);
    dispatch(getAllTerms(1)).then((resp: any) => {
      if (resp && resp.status === 200) {
        setData(resp.data);
        setIsLoading(false);
        setData(resp.data);
      }
    });
  };
  useEffect(() => {
    fetchData();
  }, [dispatch]);


  const handleDeleteStandard = () => {
    if(selectedStandard){
        handleCloseMenu();
        setShowConfirmDialog(false)
        dispatch(deleteTerm(selectedStandard)).then((resp: any) => {
          if (resp && resp.status === 200) {
            setSelectedStandard({})
            setAlertMessage('Standard deleted successfully...!');
            fetchData();
          } else {
            setAlertMessage('Standard deletion failed...!');
          }
        });
    }
  };

  const handleClick = (event: any, id: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setTerm(id);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setTerm(null);
  };

  const triggerDelete = (standardId: any)=>{
    setShowConfirmDialog(true);
    setSelectedStandard(standardId);
  }

  const addStandardsCard = <Grid item xs={12} md={4} style={{ padding: '8px' }}>
    <Card className={`add-item-card ${classes.addBtn}`} onClick={() => {
      setEditData({});
      setOpen(true);
    }}>
      <Typography className={classes.addBtnText} display="block" align="center">
        + Add New Standard
      </Typography>
    </Card>
  </Grid>;

  let TermList: any[] = [];
  if (data && data.length) {
    TermList = data.map((value: any, idx: number) => (
      <Grid item xs={12} md={4} style={{ padding: '8px' }} key={`standards_${idx}`}>
        <Card className={classes.card}
              onClick={() => navigate(`/standards/${value.id}`)}
        >
          <CardHeader action ={
            <RoleChecker>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(e) => {
                handleClick(e, value.id);
              }}>
              <MoreVertIcon/>
            </IconButton>
            </RoleChecker>
          }
              title={<span className={classes.title}><Tooltip title={<span className={classes.toolTip}>{value.name}</span>} interactive={true} arrow={true}><span>{value.name}</span></Tooltip></span>}
          />
          <CardContent>
            <Typography>
              <span>{value.description}</span>
              <span className="w3-right"> {value.location}</span>
            </Typography>
          </CardContent>
        </Card>

        <Menu
          key={value.id}
          anchorEl={anchorEl}
          keepMounted
          open={openMenu}
          onClose={handleCloseMenu}
          PaperProps={{
            style: {
              maxHeight: 190,
              width: 80,
            },
          }}
        >
          <MenuItem onClick={(e) => {
            e.stopPropagation();
            triggerDelete(selectedTerm);
          }}>
            Delete
          </MenuItem>
        </Menu>

      </Grid>
    ));
  }
  if (isLoading || !data) {
    manageTerms = (
      <Grid item xs={12} md={3} className="textMarginCenter">
        <div className="textMarginCenter">
          <CircularProgress/>
        </div>
      </Grid>
    );
  } else if (data && data.length) {
    manageTerms = TermList;
  } else if (data && data.length === 0) {
    manageTerms = (
      <Grid item xs={12} md={3} className="textMarginCenter">
        <h5>No Standards Found</h5>
      </Grid>
    );
  }

  const exportBtn = (<div>
    <Button
      color="primary"
      variant="contained"
      size="small"
      href="https://bfvt.t.ceegees.in/static/data.csv">Standard Input Template</Button>
  </div>)

  return (
    <div>
      <TitleHeader title="Standards" showSearch={false}>
        {exportBtn}
      </TitleHeader>  
      <Grid container>
        <RoleChecker>
        {addStandardsCard}
        </RoleChecker>
        {manageTerms}
      </Grid>


      {open ? (

        <DialogModal
          fullWidth
          isOpen={open}
          hideTitle={true}
          hideBtns={true}
          maxWidth="sm"
          dialogContent={(
            <StandardsModal
              open={open}
              id={selectedTerm}
              editData={editData}
              handleClose={() => {
                setOpen(false);
                setEditData({});
                fetchData();
              }}
            />
          )}
        />)
        : null}
      {alertMessage
        ? (
          <AlertDialog
            handleClose={() => setAlertMessage(null)}
            message={alertMessage}
          />
        )
        : null
      }
      {showConfirmDialog
        ? (
          <ConfirmDialog
            handleCancel={() => {
              handleCloseMenu()
              setShowConfirmDialog(false)
              setSelectedStandard({})
            }}
            handleOk={handleDeleteStandard}
            message={'Are you sure to delete the Standard?'}
          />
        ): null}
    </div>
  );
}

export default ManageStandards;
