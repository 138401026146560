import React, { useState, useEffect, PureComponent } from 'react';
import { Grid, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import StatsCard from './StatsCard';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardStats, getDashEnquiryStats } from '../../Redux/actions';
import {
  ResponsiveContainer,
  Legend,
  PieChart, Pie, Sector, Cell, Tooltip
} from 'recharts';
import { navigate } from 'hookrouter';
import RoleChecker from './RoleChecker';

const COLORS = ['#CCCCCC', '#04b404', 'red'];
const width = 280;
const height = 280;

const RADIAN = Math.PI / 180;
/*
const renderCustomizedLabel = ({
    cx:number, cy:number, midAngle : number , innerRadius : number, outerRadius:number, 
  percent:number, index:number,
}) => {
   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};*/

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  title: {
    marginTop: '15px',
    fontWeight: 600,
    textAlign: 'center'
  },
  noteCardContent: {
  },
  marginTop8: {
    marginTop: '8px'
  },
  customToolTip:{
    background: 'rgba(0, 0, 0, 0.6)',
    padding:'0px 8px',
    borderRadius:'5px',
    color:'white',
    fontSize:'16px'
  }
}));

const Dashboard = (props: any) => {
  const classes = useStyles();
  const dispatch: any = useDispatch();
  const state: any = useSelector(state => state);
  const { dashboardStats, enquiryStats } = state;
  const [dashBoardData, setDashBoardData] = React.useState();
  const [enqDashData, setEnqDashData] = React.useState();

  useEffect(() => {
    dispatch(getDashboardStats()).then((resp: any) => {
      setDashBoardData(resp.data);
    });
    dispatch(getDashEnquiryStats()).then((resp: any) => {
      setEnqDashData(resp.data);
    });
  }, [dispatch]);

  if (!dashboardStats || dashboardStats.isFetching || !enquiryStats || enquiryStats.isFetching) {
    return (
      <div className="textMarginCenter">
        <CircularProgress/>
      </div>);
  }

  let pp = 0;
  let pc = 0;
  let vp = 0;
  let vf = 0;
  let vc = 0;
  let ep = 0;
  let ec = 0;

  if (dashBoardData) {
    let item = dashBoardData.projects.find((item: any) => item.status == 'Active');
    pp = item ? parseInt(item.total) : 0;
    item = dashBoardData.projects.find((item: any) => item.status == 'Completed');
    pc = item ? parseInt(item.total) : 0;
    item = dashBoardData.valves.find((item: any) => (item.status == 'Active'));
    vp += item ? parseInt(item.total) : 0;
    item = dashBoardData.valves.find((item: any) => ( item.status == 'Pending Approval'));
    vp += item ? parseInt(item.total) : 0;
    item = dashBoardData.valves.find((item: any) => ( item.status == 'Approved'));
    vp += item ? parseInt(item.total) : 0;
    item = dashBoardData.valves.find((item: any) => item.status == 'Failed');
    vf = item ? parseInt(item.total) : 0;
    item = dashBoardData.valves.find((item: any) => (item.status == 'Passed' ));
    vc += item ? parseInt(item.total) : 0;
    item = dashBoardData.valves.find((item: any) => (item.status == 'Modified'));
    vc += item ? parseInt(item.total) : 0;
  }

  if (enqDashData) {
    let item = enqDashData.find((item: any) => item.status == 'Pending');
    ep = item ? parseInt(item.total) : 0;
    item = enqDashData.find((item: any) => item.status == 'Closed');
    ec = item ? parseInt(item.total) : 0;
  }

  const pData = [
    { name: 'Active', value: pp },
    { name: 'Completed', value: pc }
  ];
  const vData = [
    { name: 'Pending', value: vp },
    { name: 'Completed', value: vc },
    { name: 'Failed', value: vf }
  ];
  const eData = [

    { name: 'Pending', value: ep },
    { name: 'Closed', value: ec }
  ];

  const CustomTooltip = ({ active, payload }:any) => {
    if (active) {
      return (
        <div className={classes.customToolTip}>
          <p>{`${payload[0].name} : ${payload[0].value}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div >
      <Grid container className={classes.root} spacing={2}>
        <StatsCard title={`Project Progress - ${pc}/${pp + pc} `}>
          <div className={classes.noteCardContent}>
            <ResponsiveContainer width={width} height={height} className="bfv-margin-auto">
              <PieChart>
                <Pie
                  data={pData}
                  cx={120}
                  cy={120}
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {
                    pData.map((entry: any, index: number) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>)
                  }

                </Pie>
                <Tooltip content={<CustomTooltip />}/>
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </StatsCard>
        <StatsCard title={`Valve Operations Progress - ${vc}/${vp + vf + vc}   `}>
          <div className={classes.noteCardContent}>
            <ResponsiveContainer width={width} height={height} className="bfv-margin-auto">
              <PieChart>
                <Pie
                  data={vData}
                  cx={120}
                  cy={120}
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {
                    vData.map((entry: any, index: number) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>)
                  }
                </Pie>
                <Legend/>
                <Tooltip content={<CustomTooltip />}/>
              </PieChart>
            </ResponsiveContainer>
          </div>
        </StatsCard>
        {<RoleChecker>
          <StatsCard title={`Enquires - ${ec}/${ep + ec}`}>
            <div className={`cursor-pointer ${classes.noteCardContent}`} onClick={() => navigate(`/enquiries`)}>
              <ResponsiveContainer width={width} height={height} className="bfv-margin-auto">
                <PieChart>
                  <Pie
                    data={eData}
                    cx={120}
                    cy={120}
                    labelLine={false}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {
                      eData.map((entry: any, index: number) => <Cell key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}/>)
                    }
                  </Pie>
                    <Legend/>
                  <Tooltip content={<CustomTooltip />}/>
                </PieChart>
              </ResponsiveContainer>
            </div>
          </StatsCard>
        </RoleChecker>
        }
      </Grid>
    </div>
  );
};
export default Dashboard;
