import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    tooltip: {
        position: 'relative',
    },
    tooltipTrigger: {
        display: 'inline-block',
        paddingTop:'5px',
    },
    tooltipBubble: {
        minWidth: '120px',
        maxWidth: '280px',
        position: 'absolute',
        zIndex: 1000,
        '&::after': {
            content: '" "',
            position: 'absolute',
        }
    },
    tooltipMessage: {
        background: 'rgba(0,0,0,.7)',
        color: '#FFFFFF',
        borderRadius: '3px',
        fontSize: '.72rem',
        lineHeight: 1.2,
        padding: '.72em',
        textAlign: 'center',
    },
    top: {
        bottom: '100%',
        left: '50%',
        paddingBottom: '8px',
        transform: 'translateX(-50%)',
        '&::after': {
            borderLeft: '8px solid transparent',
            borderRight: '8px solid transparent',
            borderTop: '8px solid rgba(0,0,0,.7)',
            bottom: 0,
            left: '50%',
            transform: 'translateX(-50%)',
        },
    },
    bottom: {
        top: '100%',
        left: '50%',
        paddingTop: '8px',
        transform: 'translateX(-50%)',
        '&::after': {
            borderLeft: '8px solid transparent',
            borderRight: '8px solid transparent',
            borderBottom: '8px solid rgba(0,0,0,.7)',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
        },
    },
    left: {
        top: '50%',
        right: '100%',
        paddingRight: '8px',
        transform: 'translateY(-50%)',
        '&::after': {
            borderLeft: '8px solid rgba(0,0,0,.7)',
            borderTop: '8px solid transparent',
            borderBottom: '8px solid transparent',
            top: '50%',
            right: 0,
            transform: 'translateY(-50%)',
        }
    },
    right: {
        top: '50%',
        left: '100%',
        paddingLeft: '8px',
        transform: 'translateY(-50%)',
        '&::after': {
            borderRight: '8px solid rgba(0,0,0,.7)',
            borderTop: '8px solid transparent',
            borderBottom: '8px solid transparent',
            top: '50%',
            left: 0,
            transform: 'translateY(-50%)',
        },
    },

});

const ToolTip = (props :any) => {

    const classes = useStyles();
    const { title, position, children } = props;
    const [ showToolTip, setShowToolTip ] = useState(false);
    let positionClass = '';

    const hideTooltip = () => {
        setShowToolTip(false);
    };

    const showTooltip = () => {
        setShowToolTip(true);
    };

    switch (position) {
        case 'top':
            positionClass = classes.top;
            break;
        case 'bottom':
            positionClass = classes.bottom;
            break;
        case 'right':
            positionClass = classes.right;
            break;
        case 'left':
            positionClass = classes.left;
            break;
    
        default:
            positionClass = '';
            break;
    }

    return (
        <div className={`${classes.tooltip} w3-center`} onMouseLeave={hideTooltip}>
          {showToolTip &&
          <div className={`${classes.tooltipBubble} ${positionClass}`}>
                <div className={`${classes.tooltipMessage}`}>
                    {title}
                </div>
          </div>
          }
          <div className={`${classes.tooltipTrigger}`} onMouseOver={showTooltip}>
            {children}
          </div>
        </div>
      )
}

export default ToolTip;