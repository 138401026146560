import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postCreateEnquiry, putUpdateEnquiry } from '../../Redux/actions';
import AlertDialog from '../Common/AlertDialog';
import { ErrorHelperText } from '../Common/HelperInputFields';
import ModalComponent from '../Common/Modal';
import moment from 'moment';

const useStyles = makeStyles({
  modalHeader: {
    padding: '15px',
    alignItems: 'center',
  },
  title: {
    fontWeight: 600,
    textAlign: 'center',
  },
  formGroup: {
    padding: '30px 40px',
  },
  marginBottom10: {
    marginBottom: '10px',
  },
  marginTop10: {
    marginTop: '10px',
  },
  selectLabel: {
    background: 'white',
    padding: '0px 10px',
  },
  sideTextHeading:{
    fontSize:'14px',
    fontWeight:'bold'
  },
  userAndDate:{
    fontSize:'14px',
  },
  messageField:{
    whiteSpace:'pre-wrap'
  }

});

const EnquiryModal = (props: any) => {

  const { open, handleClose, valveId, enquiryData, triggerList } = props;
  const store: any = useSelector(store => store);
  const dispatch: any = useDispatch();
  const modalTitle: string = enquiryData ? enquiryData.status === 'Closed'?'Enquiry Details':'Reply' : 'Troubleshooting query';
  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const classes = useStyles();
  const [alertMessage, setAlertMessage] = useState();
  const [editFlag, setEditFlag] = useState(false);
  const [values, setValues] = useState<any>({
    subject: '',
    message: '',
    responseMsg: '',
  });

  const [errors, setErrors] = useState<any>({
    subject: '',
    message: '',
  });

  const validateForm = () => {
    let formIsValid = true;
    let err = Object.assign({}, errors);

    if (!values.subject) {
      formIsValid = false;
      err.subject = 'This field is required';
    }
    if (!values.message) {
      formIsValid = false;
      err.message = 'This field is required';
    }

    if(enquiryData && enquiryData.id && !editFlag){
      if (!values.responseMsg || values.responseMsg === '') {
        formIsValid = false;
        err.responseMsg = 'This field is required';
      }
    }

    setErrors(err);
    return formIsValid;
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setEditFlag(true);
    setValues({
      ...values,
      [name]: value,
    });

    const err = Object.assign({}, errors);
    if (err[name]) {
      err[name] = null;
    }
    setErrors(err);
  };


  const updateResponse = (resp: any) => {
    const res = resp && resp.data;
    if (res && res.success) {
      setAlertMessage(res.message);
    } else {
      setAlertMessage('Something went wrong. Try again...!');
    }
    if(enquiryData){
      triggerList();
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const valid = validateForm();
    if (!valid) {
      return;
    }

    const createEnquiry = {
      valveId: valveId,
      subject: values.subject,
      message: values.message,
    };
    if (enquiryData && enquiryData.id) {
      if(values.responseMsg !== ''){
        const updateEnquiry = {
          projectId: enquiryData.valve.project.id,
          responseMsg: values.responseMsg,
          status: 'Closed',
        };
        dispatch(putUpdateEnquiry(updateEnquiry, enquiryData.id)).then((resp: any) => {
          updateResponse(resp);
        });
      }

    } else {
      dispatch(postCreateEnquiry(createEnquiry)).then((resp: any) => {
        updateResponse(resp);
      });
    }
  };

  if (enquiryData && enquiryData.id && !editFlag) {
    values.subject = enquiryData.subject;
    values.message = enquiryData.message;
  }

  return (
      <div style={{paddingBottom:'20px'}}>
        <Grid container className={classes.modalHeader}>
          <Grid item xs={12} className={classes.title}>
            {modalTitle}
          </Grid>
        </Grid>
        <form onSubmit={(e) => handleSubmit(e)}>
        <Grid container>
            <Grid item xs={12} className={classes.marginBottom10}>
              {enquiryData?(<Box>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <Typography variant="caption" display="block" className={classes.sideTextHeading}>
                      Subject
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid container justify="flex-end" alignItems="flex-end">
                      <Typography className={`w3-text-gray w3-margin-right ${classes.userAndDate}`}>{enquiryData.user.name}</Typography>
                      <Typography className={`w3-text-gray ${classes.userAndDate}`}>{moment(enquiryData.createdAt).format("lll")}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                  <Typography variant="body2" gutterBottom>
                    {values.subject}
                  </Typography>
              </Box>):
              (<Fragment>
                  <TextField
                    InputLabelProps={{ shrink: !!values.subject }}
                    fullWidth
                    id="subject"
                    name="subject"
                    margin="dense"
                    label="Subject"
                    value={values.subject || ''}
                    onChange={(e) => handleChange(e)}
                    variant="outlined"
                    required
                  />
                  <ErrorHelperText error={errors.subject}/>
              </Fragment>)}
            </Grid>
            <Grid item xs={12} className={classes.marginBottom10}>
              
            {enquiryData?(<Box>
              <Typography variant="caption" display="block" gutterBottom className={classes.sideTextHeading}>
                  Message
              </Typography>
              <Typography variant="body2" gutterBottom className={classes.messageField}>
                  {values.message}
              </Typography>
            </Box>):
              (<Fragment>
                <TextField
                  InputLabelProps={{ shrink: !!values.message }}
                  fullWidth
                  multiline
                  rows={5}
                  id="message"
                  name="message"
                  margin="dense"
                  label="Message"
                  value={values.message || ''}
                  onChange={(e) => handleChange(e)}
                  variant="outlined"
                  required
                />
                <ErrorHelperText error={errors.message}/>
              </Fragment>)}
            </Grid>

            {enquiryData && <Fragment>
              {enquiryData.response?(
                <Grid item xs={12} className={classes.marginBottom10}>
                    <Box>
                      <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="caption" display="block" className={classes.sideTextHeading}>
                                Replied
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid container justify="flex-end" alignItems="flex-end">
                              <Typography className={`w3-text-gray w3-margin-right ${classes.userAndDate}`}>{enquiryData.respondedUser.name}</Typography>
                              <Typography className={`w3-text-gray ${classes.userAndDate}`}>{moment(enquiryData.respondedAt).format("lll")}</Typography>
                            </Grid>
                        </Grid>
                      </Grid>
                      <Typography variant="body2" gutterBottom className={classes.messageField}>
                          {enquiryData.response}
                      </Typography>
                  </Box>
            </Grid>):
              <Grid item xs={12} className={classes.marginBottom10}>
                <TextField
                  InputLabelProps={{ shrink: !!values.responseMsg }}
                  fullWidth
                  multiline
                  rows={5}
                  id="responseMsg"
                  name="responseMsg"
                  margin="dense"
                  label="Reply Message"
                  value={values.responseMsg || ''}
                  onChange={(e) => handleChange(e)}
                  variant="outlined"
                  required
                />
                <ErrorHelperText error={errors.responseMsg}/>
              </Grid>}
            </Fragment>}
            <Grid item xs={12} className={classes.marginBottom10}>
              <Grid container spacing={2} justify={enquiryData && enquiryData.status === 'Closed'?'flex-end':'flex-start'} className={classes.marginTop10}>
                {enquiryData && enquiryData.status === 'Closed'?
                <Grid item>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleClose}
                  >Close
                  </Button>
              </Grid> : (<Fragment>
                <Grid item>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleClose}
                  >Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    type="submit"
                    onClick={(e) => handleSubmit(e)}
                  >Submit
                  </Button>
                </Grid>
                </Fragment>)}
              </Grid>
            </Grid>

        </Grid>
        </form>

        {alertMessage ?
          (
            <AlertDialog
              handleClose={() => {
                setAlertMessage(null);
                handleClose();
              }}
              message={alertMessage}
            />
          )
          : null
        }
      </div>
  );
};

export default EnquiryModal;