import {PD_CONFIG} from '../../Redux/actions';

class BFVCalculator {
    pipeDia: number;
    thickness: number;
    eccentricity: number;
    valveType: string;
    balance: number;
    pdDia :number;
    openDir:string;
    numberOfRotations:number;
    pipeDiver:any;

    constructor(valve:any , balance:number = 0) {
        const pipeDia = parseFloat(valve.diskDiameter);
        const thickness = parseFloat(valve.plateThickness);
        const eccentricity = parseFloat(valve.eccentricity); 
        
        this.pipeDiver = PD_CONFIG.find((item: any) => item.id == valve.pipeDiverPlatform);
        this.pdDia = this.pipeDiver.dia;
        this.openDir = valve.valveOpenDirection;
        this.pipeDia = pipeDia;
        this.thickness = thickness;
        this.eccentricity = eccentricity;
        this.valveType = valve.valveType;

        this.numberOfRotations = (valve.json && valve.json.rotations) ? valve.json.rotations :0
        this.balance = balance;
    }

    deg2rad(deg: number) {
        return deg * Math.PI / 180
    }

    getDistances(theta: number) {
        const pipeDia = this.pipeDia
        const thickness = this.thickness
        const e: number = this.eccentricity;
        const r: number = pipeDia / 2;
        const rad = this.deg2rad(90 - theta);
        const tby2 = thickness / 2;

        const thicknessProjection = Math.abs(tby2 * Math.cos(rad));

        let tfov = (r - e) * Math.sin(rad) + thicknessProjection + e;
        let bfov = tfov - 2 * thicknessProjection;

        //0.5 is added for offsetting the 1 inch 
        let trov = (r + e) * Math.sin(rad) - thicknessProjection + (r - e) + 0.5;
        let brov = pipeDia - (trov + 2 * thicknessProjection) + 0.5;


        if (Math.cos(rad) < 0) {
            console.log('anti clockwise');

            tfov = (r + e) * Math.sin(rad) - thicknessProjection - e
            bfov = tfov + 2 * thicknessProjection;
            trov = (r - e) - (r - e) * Math.sin(rad) - thicknessProjection + 0.5;
            brov = pipeDia - (trov + 2 * thicknessProjection) + 0.5;
            bfov += this.balance;
            tfov += this.balance;
        } else {
            bfov -= this.balance;
            tfov -= this.balance;
        }

        const result = {
            tfov,
            brov,
            bfov,
            trov,
            tp: thicknessProjection
        }
        // console.log(result);
        return result;
    }

    calculateThetaResult(theta: number) {

        const r = this.pipeDia / 2;
        let rankEx = -1000;
        let rankEn = -1000;
        const pdNoseRad = 0.25;
        let success = true;
        let reco = ['Caution PD Collission - '];
        let reason = 'Collision Probability';

        const {
            tfov,
            brov,
            bfov,
            trov,
            tp
        } = this.getDistances(theta);

        if (tfov * bfov < 0) {
            rankEn = -10;
            rankEx = -10;
            reco.push('Nose collission head on');
        } else if (Math.abs(tfov) < Math.abs(bfov)) {
            rankEn = Math.abs(tfov) - pdNoseRad;
            if (rankEn < 0) {
                reco.push('Balancing PipeDiver from centre line can help');
            }
            rankEx = trov - this.pdDia;
            if (rankEx < 0) {
                reco.push(' Can\'t pass due to collision on exit.');
            }
        } else {
            rankEn = Math.abs(bfov) - pdNoseRad;
            rankEx = brov - this.pdDia;
            if (rankEn < 0) {
                reco.push('Balancing PipeDiver from centre line can help');
            }
            if (rankEx < 0) {
                reco.push(' Can\'t pass due to collision on exit.');
            }
        }

        if (rankEn < 0 || rankEx < 0) {
            success = false
        }

        if (this.valveType === 'humpBack') {
            if (bfov < 0 || tfov < 0) {
                success = false;
                rankEn = 0;
                rankEx = 0;
            }
        }

        const openDirection =  theta < 0 ? 'AntiClockwise' : 'Clockwise';
        return {
            success,
            theta,
            thetaInfo: `${Math.abs(theta)} ${openDirection}`,
            recommendation: success ? '' : reco.join(','),
            reason: success ? '' : reason,
            score: ((rankEx + rankEn) - Math.abs(rankEn - rankEx)).toFixed(2),
            pdDia: this.pdDia,
            pdBalance: this.balance,
            tfov: Math.abs(tfov).toFixed(2),
            brov: brov.toFixed(2),
            bfov: Math.abs(bfov).toFixed(2),
            trov: trov.toFixed(2),
            rotationInfo : `${Math.abs(this.numberOfRotations *  theta / 90).toFixed(2)} rotations ${openDirection}`,
            tp: tp.toFixed(2),
            rEx: rankEx.toFixed(2),
            rEn: rankEn.toFixed(2),
            openDirection,
            valveType: this.valveType,
        }

    }

    calculatePassRange( checkAngele: number | null ) {
 

        const resultList: any = [];
        let minAngle = 0
        let maxAngle = 90;
        if (this.openDir !== "Clockwise") {
            minAngle = -90;
            maxAngle = 0;
        }

        for (let ang: number = minAngle; ang <= maxAngle; ang += 0.5) {
            const res = this.calculateThetaResult(ang);
            resultList.push(res);
        }
        const rankedList: any[] = [].concat(resultList).sort((a: any, b: any) => {
            return b.score - a.score;
        });

        let optimalValue = rankedList[0];
        if (checkAngele) {
            optimalValue = this.calculateThetaResult(checkAngele);
        }

        const passRangeList: any = [];
        resultList.forEach((item: any, idx: number) => {
            if (item.success) {
                if (passRangeList.length > 0 && passRangeList[0].end == item.theta - 0.5) {
                    passRangeList[0].end = item.theta;
                    return;
                }
                passRangeList.unshift({
                    start: item.theta,
                    end: item.theta,
                    dir: item.theta < 0 ? 'AntiClockwise' : 'Clockwise'
                });
            }
        });

        const result = {
            passRangeList,
            resultList,
            rankedList,
            optimalValue
        };
        return result;
    }
}

export default BFVCalculator;