import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Drawer, Divider, List, FormControl, InputLabel, Select, Button, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles({
    list: {
        paddingTop: 50,
        maxWidth: 380,
        padding: 20,
    },
    selectLabel: {
        background: 'white',
        padding: '0px 10px',
    },
    formControl: {
        minWidth: 300,
    },
    filterIcon: {
        marginTop: '4px',
        marginRight: '20px',
    },
    reactSelect: {
        zIndex: 2
    },
    scrollMenuList: {
        maxHeight: 200,
        overflow: 'auto'
    }
});

const EnquiryFilters = (props: any) => {
    const { fetchFilteredData, clearFilterValues, filterQueries, handleSelectFilter, clearFilterState } = props;
    const classes = useStyles();
    const dispatch: any = useDispatch();
    const initialData: any[] = [];
    const [drawerState, setDrawerState] = React.useState({
        right: false,
    });

    type DrawerSide = 'right';
    const toggleDrawer = (side: DrawerSide, open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        setDrawerState({ ...drawerState, [side]: open });
    };


    const triggerFilter = () => {
        fetchFilteredData();
        setDrawerState({
            right: false,
        });
    }

    const handleClearFilter = () => {
        setDrawerState({
            right: false,
        });
        clearFilterValues();
    }

    const inputLabel = React.useRef<HTMLLabelElement>(null);

    const sideList = (side: DrawerSide) => (
        <div
            className={classes.list}
            role="presentation"
        >
            <h3>Filters</h3>
            <List>
                <Grid item xs={12} md={3}>
                    <FormControl
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        className={classes.formControl}>
                        <InputLabel className={classes.selectLabel} ref={inputLabel} id="statusFilterLabel">
                            Select Status
                        </InputLabel>
                        <Select
                            labelId="statusFilterLabel"
                            id="status"
                            name="status"
                            value={filterQueries.status|| ''}
                            onChange={v => handleSelectFilter(v)}
                            fullWidth
                            
                        >
                            <MenuItem value="Pending">Pending</MenuItem>
                            <MenuItem value="Closed">Closed</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </List>
            <Divider />
            <List>
                <Grid container justify="space-between" alignItems="center">
                    <Button variant="contained"
                        size="small"
                        onClick={handleClearFilter}>
                        Clear
                    </Button>
                    <Button color="primary"
                        variant="contained"
                        size="small"
                        onClick={triggerFilter}
                        className="w3-center">
                        Filter
                    </Button>
                </Grid>
            </List>

        </div>
    );

    return (
        <div>
            <IconButton className={`w3-right cursor-pointer ${classes.filterIcon}`} onClick={toggleDrawer('right', true)} >
                <FilterListIcon />
            </IconButton>
            <Drawer anchor="right" open={drawerState.right} onClose={toggleDrawer('right', false)}
                ModalProps={{ onBackdropClick: ()=> clearFilterState() }}>
                {sideList('right')}
            </Drawer>
        </div>
    )
}

export default EnquiryFilters;