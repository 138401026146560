import {FormControl, makeStyles, Typography} from '@material-ui/core';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { getAllTerms, addMeta, postUpdateProject } from '../../Redux/actions';
import ModalComponent from '../Common/Modal';
import { Grid, Button, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import AlertDialog from '../Common/AlertDialog';
import { ErrorHelperText } from '../Common/HelperInputFields';

const useStyles = makeStyles({
  modalHeader: {
    padding: '15px',
    alignItems: 'center',
  },
  modalFooter: {
    padding: '15px 0px',
  },
  title: {
    fontWeight: 600,
    textAlign: 'center',
  },
  formGroup: {
    padding: '30px 40px',
  },
  marginBottom10: {
    marginBottom: '10px',
  },
  marginTop10: {
    marginTop: '10px',
  },
  formControl: {
    minWidth: 300,
  },
});

const StandardsModal = (props: any) => {
  const { open, handleClose, id } = props;
  const classes = useStyles();
  const [alertMessage, setAlertMessage] = useState();
  const [errors, setErrors] = useState<any>({
    name: '',
  });
  const [values, setValues] = useState<any>(Object.assign({
    name: '',
  }));
  const dispatch: any = useDispatch();
  const store: any = useSelector(store => store);
  const modalTitle: string = 'Create New Standard';
  const [uploadFile, setUploadFile] = useState();

  const validateForm = () => {
    let formIsValid = true;
    let err = Object.assign({}, errors);
    if (!values.name) {
      formIsValid = false;
      err.name = 'This field is required';
    }

    if(!uploadFile){
      formIsValid = false;
      err.uploadFile = 'Please choose a file to upload';
    }
    setErrors(err);
    return formIsValid;
  };
  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });

    const err = Object.assign({}, errors);
    if (err[name]) {
      err[name] = null;
    }
    setErrors(err);
  };

  const handleFileUpload = (e: any) => {
        const regex = new RegExp('(.*?).(csv)$');
        if (regex.test(e.target.files[0].name)) {
            const err = Object.assign({}, errors);
            if (err.uploadFile) {
                err.uploadFile = null;
            }
            setErrors(err);
            setUploadFile(e.target.files[0]);
        } else {
            setAlertMessage('Incompatible File Type :  Please Upload a CSV File');
        }
    };

  const handleSubmit = () => {
    const valid = validateForm();
    if (!valid) {
      return;
    }

    let formData = new FormData();
    formData.append('name',values.name);
    formData.append('file', uploadFile);

    dispatch(addMeta(formData)).then((resp: any) => {
      const res = resp && resp.data;
      if (res && res.success) {
        setAlertMessage(res.message);
      } else {
        setAlertMessage(res.message);
      }
    });
  };
  return (
      <div style={{paddingBottom:'20px'}}>
        <Grid container className={classes.modalHeader}>
          <Grid item xs={12} className={classes.title}>
            {modalTitle}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} className={classes.marginBottom10}>
            <TextField fullWidth
                       id="name"
                       name="name"
                       margin="dense"
                       label="Standards Name"
                       value={values.name || ''}
                       onChange={(e) => handleChange(e)}
                       variant="outlined"
                       required
            />
            <ErrorHelperText error={errors.name}/>
          </Grid>
          <Grid item xs={12} className={classes.marginBottom10}>
              <FormControl variant="outlined" className={classes.formControl}>
                <input
                  type='file'
                  color="primary"
                  className='custom-file-input '
                  id='customFile'
                  name="file"
                  onChange={handleFileUpload}
                /> <Typography className="w3-padding">*csv files only</Typography>
              </FormControl>
            <ErrorHelperText error={errors.uploadFile}/>
          </Grid>

        </Grid>
        <Grid container className={classes.modalFooter} justify="flex-start" alignItems="center" spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleClose}
                >Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={handleSubmit}
                >Save
                </Button>
              </Grid>
        </Grid>

        {alertMessage ?
          (
            <AlertDialog
              handleClose={() => {
                setAlertMessage(null);
                handleClose();
              }}
              message={alertMessage}
            />
          )
          : null
        }
      </div>
  );
};

export default StandardsModal;
