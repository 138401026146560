export default {

    // auth
    currentUser: {
        path: '/api/v1/auth/user',
    }, 
    logout: {
        path: '/api/v1/auth/logout',
    },
    currentUserRoles: {
        path: '/api/v1/roles/current-user-roles',
    },
    firstUser: {
        path: '/api/v1/auth/first-user'
    },
    updateAdminStatus: {
        path: '/api/v1/roles/update-user-role',
        method: 'POST'
    },
    getRoleUsers: {
        path: '/api/v1/roles/get-role-users',
        method: 'GET'
    },
    register: {
        path: '/api/v1/auth/register',
        method: 'POST',
    },
    login: {
        path: '/api/v1/auth/login',
        method: 'POST',
    },
    forgotPassword: {
        path: '/api/v1/auth/forgot-password',
        method: 'POST',
    },
    resetPassword: {
        path: '/api/v1/auth/reset-password',
        method: 'POST',
    },

    //invite
    invitedUserList: {
        path: '/api/v1/invites/all-invites',
    },
    inviteUser: {
        path: '/api/v1/invites/create',
        method: 'POST',
    },
    editInviteUser: {
        path: '/api/v1/invites/update',
        method: 'PUT',
    },
    deleteInviteUser: {
        path: '/api/v1/invites/delete',
        method: 'DELETE',
    },
    changeUserPassword: {
        path: '/api/v1/auth/change-password',
        method: 'PUT',
    },
    changeUserName: {
        path: '/api/v1/auth/change-username',
        method: 'PUT',
    },
    acceptInvite: {
        path: '/api/v1/invites/accept',
        method: 'POST',
    },
    validateToken: {
        path: '/api/v1/invites/validate-token',
    },

    //projects
    projectList: {
        path: '/api/v1/project/list-projects',
    },
    deleteProject: {
        path: '/api/v1/project/delete',
        method: 'DELETE',
    },
    createProject: {
        path: '/api/v1/project/create',
        method: 'POST',
    },
    editProject: {
        path: '/api/v1/project/edit',
        method: 'PUT',
    },
    findProjects: {
        path: '/api/v1/project/find-projects',
    },
    filterProjects: {
        path: '/api/v1/project/filter-projects',
    },
    projectReport:{
        path:'/api/v1/project/report'
    },
    projectDetails:{
        path:'/api/v1/project/details'
    },

    //valve
    valveList: {
        path: '/api/v1/valves/all-valves',
    },
    valveListAll: {
        path: '/api/v1/valves/all-valves',
    },
    valveInfo: {
        path: '/api/v1/valves/get-valve',
    },
    createValve: {
        path: '/api/v1/valves/create',
        method: 'POST',
    },
    updateValve: {
        path: '/api/v1/valves/update',
        method: 'PUT',
    },
    deleteValve: {
        path: '/api/v1/valves/delete',
        method: 'DELETE',
    },
    findValve: {
        path: '/api/v1/valves/find-valves',
    },
    createValveDetail: {
        path: '/api/v1/valves/create-detail',
        method: 'POST',
    },
    updateValveDetail: {
        path: '/api/v1/valves/update-detail',
        method: 'PUT',
    },
    getValveDetailById: {
        path: '/api/v1/valves/detail',
        // method: 'GET',
    },
    getAllVersions: {
        path: '/api/v1/valves/all-valve-versions',
    },
    createValveVersion: {
        path: '/api/v1/valves/create-valve-version',
        method: 'POST',
    },
    getAllValveList: {
        path: '/api/v1/valves/valve-list',
    },
    saveParams:{
        path: '/api/v1/valves/save-params',
        method: 'POST'
    },

    //roles
    //--not used now
    // roleUsersByRefId: {
    //     path: '/api/v1/roles/role-users-by-refid',
    //     method: 'GET'
    // },

    // users

    usersList: {
        path: '/api/v1/auth/all-users',
        method: 'GET',
    },

    findUser: {
        path: '/api/v1/invites/find-user',
    },

    getStandards: {
        path: '/api/v1/standards/all-standards',
    },
    //Enquiries
    createEnquiry: {
        path: '/api/v1/enquiries/create',
        method: 'POST',
    },
    enquiryList: {
        path: '/api/v1/enquiries/all-enquiries'
    },
    updateEnquiry: {
        path: '/api/v1/enquiries/update-status',
        method: 'PUT'
    },

    //DashBoard
    dashboardStats: {
        path: '/api/v1/project/dashboard-stats',
    },
    enquiryStats: {
        path: '/api/v1/enquiries/dash-enq-stats',
    },

    // meta

    getAllMeta: {
        path: '/api/v1/meta/all-meta',
    },

    addMeta: {
        path: '/api/v1/meta/add-meta',
        method: 'POST',
    },

    getMetaByName: {
        path: '/api/v1/meta/get-meta-byname',
    },

    deleteMeta: {
        path: '/api/v1/meta/delete',
        method: 'DELETE',
    },
    versionNumber: {
        path: '/api/v1/meta/version',
    },
// Terms

    getAllTerms: {
        path: '/api/v1/meta/all-terms',
    },
    addTerm: {
        path: '/api/v1/meta/add-term',
        method: 'PUT',
    },
    getTermById: {
        path: '/api/v1/meta/get-term',
    },
    updateTerm: {
        path: '/api/v1/meta/update',
        method: 'PUT',
    },
    deleteTerm: {
        path: '/api/v1/meta/delete-term',
        method: 'DELETE',
    },
    findTerm: {
        path: '/api/v1/meta/find-meta-term',
    },
    feedback: {
        path: '/api/v1/valves/feedback',
        method: 'POST'
    },
    approval: {
        path: '/api/v1/valves/approval',
        method: 'PUT'
    },
    resultInfo: {
        path: '/api/v1/valves/resultInfo',
        method: 'POST'
    },
};
