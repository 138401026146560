
// declare var Phaser:any;

const IMG_PD = require('../../img/pd_nose.png');
const IMG_PD_BODY =  require('../../img/pd_body.png');
const Phaser = window.Phaser;
function createPhysicsModel(name, config) {
  if (!document.getElementById(name)) {
    return;
  }
  if (document.getElementById(name).children.length) {
    document.getElementById(name).children[0].remove();
  } 


  const {animate = false, calc,result} = config;
  const pipeDiver = calc.pipeDiver;
 

  const CANVAS_WIDTH = window.innerWidth < 540 ? window.innerWidth - 60 : (window.innerWidth - 340) * 2 / 3;
  const CANVAS_HEIGHT = 200;
  const IMAGE_WIDTH = 404;
  const IMAGE_HEIGHT = 300;

  const PD_SUCCESS = result.success;

  const PD_BODY_MAX =  pipeDiver.numberOfBodies;
  const SCALING_FACTOR = CANVAS_HEIGHT / calc.pipeDia;

  const X_PD_BODY_WIDTH = 24 * SCALING_FACTOR;
  const PD_IMG_SF = SCALING_FACTOR * 6 / IMAGE_HEIGHT;

  const PLATE_X = CANVAS_HEIGHT;
  const PD_Y = CANVAS_HEIGHT / 2 - result.pdBalance * SCALING_FACTOR;
  const X_PD_RADIUS = pipeDiver.dia  * 0.5 * SCALING_FACTOR;

  const X_THICKNESS = calc.thickness * SCALING_FACTOR;
  const X_ECC = calc.eccentricity * SCALING_FACTOR;
  const X_PD_NOSE_RAD = 3 * SCALING_FACTOR;
  const X_IMAGE_WIDTH = PD_IMG_SF * IMAGE_WIDTH;

  const DRAWING_OFFSET = X_PD_RADIUS;
  const HUMPBACK_FACTOR = calc.valveType !== 'humpBack' ? 0 : CANVAS_HEIGHT / 2.5;


  function preload() {
    this.load.image('pipeDriver', IMG_PD);
    this.load.image('pipeDriverBody', IMG_PD_BODY);
  }

  function createPlate() {
    const centerLine = new Phaser.Geom.Line(0, CANVAS_HEIGHT / 2, CANVAS_WIDTH*2, CANVAS_HEIGHT / 2);
    const graphics = this.add.graphics({ lineStyle: { width: 1, color: 0xFF0000 } });
    graphics.strokeLineShape(centerLine);

    //Plate
    const platePoints = [
      X_THICKNESS, 0,
      X_THICKNESS, CANVAS_HEIGHT,
      0, CANVAS_HEIGHT - HUMPBACK_FACTOR,
      0, HUMPBACK_FACTOR
    ];

    const pts = platePoints.join(' ');
    const rad = result.theta * Math.PI / 180;
    const yDiff = X_ECC * Math.cos(rad);
    const xDiff = X_ECC * Math.sin(rad);
    const plate = this.add.polygon(
      PLATE_X - xDiff,
      CANVAS_HEIGHT / 2 - (X_ECC - yDiff),
      pts,
      0x40549e, 1
    ).setName('plate');

    this.matter.add.gameObject(plate, {
      x: PLATE_X - xDiff,
      y: CANVAS_HEIGHT / 2 - X_ECC,
      shape: {
        type: 'fromVerts',
        verts: pts,
      },
      label:'plate'
    });

    plate.body.onCollide = true;
    plate.setAngle(result.theta);
    plate.setBounce(0);
    plate.setFriction(0.5);
    plate.setStatic(true);
    this.add.circle(PLATE_X, CANVAS_HEIGHT / 2 - X_ECC,X_THICKNESS/4, 0xFFFFFF, 0.8);
  }

  function createPDNose(xPos, pdBody) {
    const nosePoints = [
      0, 0 + DRAWING_OFFSET,
      2 / 3 * X_PD_NOSE_RAD, X_PD_NOSE_RAD + DRAWING_OFFSET,
      X_IMAGE_WIDTH, X_PD_NOSE_RAD + DRAWING_OFFSET,
      X_IMAGE_WIDTH, -1 * X_PD_NOSE_RAD + DRAWING_OFFSET,
      2 / 3 * X_PD_NOSE_RAD, -1 * X_PD_NOSE_RAD + DRAWING_OFFSET
    ];

    const nose = this.matter.add
      .image(xPos, PD_Y, 'pipeDriver')
      .setName('nose')
      .setScale(PD_IMG_SF, PD_IMG_SF*400/300);

    nose.setBody({ 
      type: 'fromVerts',
      verts: nosePoints.join(' ')
    });

    this.matter.add.worldConstraint(nose, 0, 0.00005, {
      pointA: { x: -800, y: PD_Y },
      pointB: { x: X_IMAGE_WIDTH / -2, y: 0 }
    });
    this.matter.add.constraint(nose, pdBody, 4, 0.3, {
      pointA: { x: X_IMAGE_WIDTH / 2, y: 0, },
      pointB: { x: X_PD_BODY_WIDTH / -2, y: 0, }
    });
    
    nose.setFrictionAir(0.2);
    nose.setFixedRotation();
    nose.setFriction(0);
    nose.setBounce(0);
    return nose;
  }

  function createPDBody(bodyX,prev) {

    
    const pdBodyPoints = [
      0, 2 / 3 * X_PD_NOSE_RAD + DRAWING_OFFSET,
      X_PD_BODY_WIDTH, X_PD_RADIUS + DRAWING_OFFSET,
      X_PD_BODY_WIDTH, -1 * X_PD_RADIUS + DRAWING_OFFSET,
      0, -2 / 3 * X_PD_NOSE_RAD + DRAWING_OFFSET
    ];
    const verts = pdBodyPoints.join(' '); 

    const pdBody = this.matter.add
      .image(bodyX, PD_Y, 'pipeDriverBody')
      .setScale( 
        X_PD_BODY_WIDTH  / 842 ,
        X_PD_RADIUS * 2  / 300
      );
    pdBody.setBody({ 
      type: 'fromVerts',
      verts
    });

    pdBody.setFixedRotation()
      .setFriction(0)
      .setBounce(0);


    if (prev) {
      this.matter.add.constraint(prev, pdBody, 2, 0.5, {
        pointA: { x: X_PD_BODY_WIDTH / 2, y: 0 },
        pointB: { x: X_PD_BODY_WIDTH / -2, y: 0 }
      });
    }
    return pdBody;
  }
 

  function create() {
    this.matter.world
      .setBounds()
      .setGravity(-0.01, 0, 0)
      .updateWall(false, 'left');

    const plate = createPlate.call(this);
    console.log('Animating',animate);
    if (!animate) {
      return;
    }

    let bodyStart = CANVAS_WIDTH;
    bodyStart += X_IMAGE_WIDTH 

    // bodyStart -= 200 * SCALING_FACTOR;
    const pdBody = createPDBody.call(this, bodyStart, null);
    let prev = pdBody;
    for (var idx = 0; idx < PD_BODY_MAX - 1; idx++) {
      bodyStart += (X_PD_BODY_WIDTH + 2);
      prev = createPDBody.call(this, bodyStart, prev);
    }
    const nose = createPDNose.call(this, CANVAS_WIDTH, pdBody);
    const self = this;
    if (PD_SUCCESS) {
      return;
    }
    this.matter.world.on('collisionstart', function (event, nose, plate){ 
      event.pairs.forEach(item=> {
        if(item.bodyA.gameObject && item.bodyB.gameObject){
          const names = [item.bodyA.gameObject.name,
            item.bodyB.gameObject.name
          ];
          const collId = names.sort().join('-');
          if(collId == 'nose-plate') {
            self.matter.world.pause();
          }
        }
      });
     
    });
  }

  var phaserConfig = {
    type: Phaser.AUTO,
    width: animate ? CANVAS_WIDTH * 5: 500 ,
    height: CANVAS_HEIGHT,
    backgroundColor: '#CCCCCC',
    parent: name,
    physics: {
      default: 'matter'
    },
    scene: {
      create: create,
      preload: preload
    }
  };
  return new Phaser.Game(phaserConfig);
}


export default createPhysicsModel;