import { Grid, CircularProgress, Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { navigate, usePath } from 'hookrouter';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAllValvesList, getProjectList, valveListFilter } from '../../Redux/actions';
//import AlertDialog from '../Common/AlertDialog';
import Pagination from '../Common/Pagination';
import ValveListFilters from './ValveListFilters';
import TitleHeader from '../Common/TitleHeader';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      fontWeight: 'bold',
      padding: 8
    },
    body: {
      fontSize: 14,
      cursor: 'pointer',
      padding: 8
    }

  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default
      }

    }
  })
)(TableRow);

const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  table: {
    minWidth: 700
    // cursor: 'pointer',
  },
  list: {
    paddingTop: 50,
    maxWidth: 380,
    padding: 20
  },
  selectLabel: {
    background: 'white',
    padding: '0px 10px'
  },
  uploadFile: {
    float: 'right'
  },
  inputFile: {
    color: '#000',
    backgroundColor: '#EEEEEE',
    fontSize: '0.875rem',
    lineHeight: '1.75'
  },
  formControl: {
    minWidth: 300
  },
  paginateTopPadding: {
    paddingTop: '50px'
  },
  filterIcon: {
    marginTop: '8px',
    marginRight: '20px'
  },
  scrollMenuList: {
    maxHeight: 200,
    overflow: 'auto'
  },
  marginRight20:{
    marginRight:'20px'
  }
});

export default function ValveList(props: any) {
  const classes = useStyles();
  const dispatch: any = useDispatch();
  const initialData: any[] = [];
  const [data, setInitialData] = useState(initialData);
  //const [alertMessage, setAlertMessage] = useState();
  const path = usePath();
  const [projectList, setProjectList] = useState([]);
  const [projectId, setProjectId] = useState('');
  const [valveDetailsListData, setValveDetailsListData] = useState(initialData);
  const [valveData, setData] = useState(data);
  const [state, setState] = React.useState({
    right: false
  });
  const [totalCount, setTotalCount] = useState(0);
  const [filterValues, setFilterValues] = useState<any>(Object.assign({
    manufacturer: '',
    pdPlatform: '',
    valveSize: '',
    valveType: ''
  }));
  const [isLoading, setIsLoading] = useState(false);
  let displayValveList: any = null;
  let valveList: any[] = [];
  
  const limit = 10;
  const initialPaginateData = {
    page: 1,
    offset: 0,
    limit
  };
  const [currentPage, setCurrentPage] = useState(1);
  let manufacturers: any[] = [];
  let valveSizes: any[] = [];

  const fetchData = (paginateData: any) => {
    setIsLoading(true);
    dispatch(getProjectList()).then((resp: any) => {
      setIsLoading(false);
      if (resp && resp.status === 200) {
        const respData = resp.data;
        const projects = respData.data.filter((obj: any, idx: any, arr: any) => (
          arr.findIndex((item: any) => item.id === obj.id) === idx
        ));
        const projectListData = projects.map((item: any) => {
          if (item.client != null) {
            return {
              label: item.client,
              value: item.id
            };
          }
        });
        setProjectList(projectListData);
      }
    });

    dispatch(getAllValvesList(paginateData)).then((resp: any) => {
      setIsLoading(false);
      if (resp && resp.status === 200) {
        const respData = resp.data;
        const valveDetailList = respData.data.map((item: any) => (item.valveDetails));
        const finalList = valveDetailList.filter((item: any) => (item != null));
        setInitialData(respData.data);
        setData(respData.data);
        setTotalCount(respData.total);
        setValveDetailsListData(finalList);
      }
    });
  };

  const fetchFilteredData = (filterData: any) =>{
    setIsLoading(true);
    dispatch(valveListFilter(filterData)).then((res: any) => {
      setIsLoading(false);
      if (res && res.status === 200) {
        const respData = res.data;
        const valveDetailList = respData.data.map((item: any) => (item.valveDetails));
        const finalList = valveDetailList.filter((item: any) => (item != null));
        setData(respData.data);
        setTotalCount(respData.total);
        setValveDetailsListData(finalList);
      }
    });
  };

  useEffect(() => {
    fetchData(initialPaginateData);
  }, [dispatch]);


  const handleSelectProject = (v: any) => {
    const projectId = v.target.value;
    setProjectId(projectId);
  };

  const handleSelectFilter = (e: any) => {
    const { name, value } = e.target;
    setFilterValues({
      ...filterValues,
      [name]: value,
    });
  };


  const handlePagination = (page: any, perPage: any) =>{
    setCurrentPage(page);
    const filters = {};
    Object.keys(filterValues).forEach((key: any) => {
      if (filterValues[key] !== '') {
        Object.assign(filters, { [key]: filterValues[key] });
      }
    });

    let paginateData = {
      page,
      offset: perPage,
      limit
    };
    
    if (projectId) {
      paginateData = Object.assign(paginateData, { id: projectId });
    }

    if (Object.keys(filters).length > 0) {
      paginateData = Object.assign(paginateData, filters);
      fetchFilteredData(paginateData);
    } else {
      fetchData(paginateData);
    }
  };

  const triggerFilter = () => {
    const filters = {};
    Object.keys(filterValues).forEach((key: any) => {
      if (filterValues[key] !== '') {
        Object.assign(filters, { [key]: filterValues[key] });
      }
    });

    let filterQueryData = {
      page: 1,
      offset: 0,
      limit
    };

    if (projectId) {
      filterQueryData = Object.assign(filterQueryData, { id: projectId });
    }
    if (Object.keys(filters).length > 0) {
      filterQueryData = Object.assign(filterQueryData, filters);
    }
    setCurrentPage(1);
    fetchFilteredData(filterQueryData);
    setState({
      right: false
    });
  };

  const handleClearFilter = () => {
    setProjectId('');
    setFilterValues({
      manufacturer: '',
      pdPlatform: '',
      valveSize: '',
      valveType: ''
    });
    setState({
      right: false
    });
    setCurrentPage(1);
    fetchData(initialPaginateData);
  };

  const clearFilterState = () => {
    setProjectId('');
    setFilterValues({
      manufacturer: '',
      pdPlatform: '',
      valveSize: '',
      valveType: ''
    });
  };

  if (valveDetailsListData && valveDetailsListData.length > 0) {
    manufacturers = valveDetailsListData.filter((obj, idx, arr) => (
      arr.findIndex((item) => item.manufacturer.toString().toLowerCase() === obj.manufacturer.toString().toLowerCase()) === idx
    ));

    valveSizes = valveDetailsListData.filter((obj, idx, arr) => (
      arr.findIndex((item) => item.valveSize === obj.valveSize) === idx
    ));
  }

  if (valveData && valveData.length) {
      valveList = valveData.map((item:any) => (
        <StyledTableRow key={item.id} onClick={(e) => {
          navigate(`project/${item.projectId}/valve/${item.id}`);
        }} className="cursor-pointer" >
          <StyledTableCell>
            {item.valveNo}
          </StyledTableCell>
          <StyledTableCell>{item.name}</StyledTableCell>
          <StyledTableCell>{item.location}</StyledTableCell>
          <StyledTableCell>{item.valveDetails && item.valveDetails.valveType ? item.valveDetails.valveType === 'humpBack'? 'Hump Back': 'Flat Plate' : null}</StyledTableCell>
          <StyledTableCell>{item.valveDetails && item.valveDetails.pipeDiverPlatform ? item.valveDetails.pipeDiverPlatform : null}</StyledTableCell>
          <StyledTableCell>{item.valveDetails && item.valveDetails.valveSize ? item.valveDetails.valveSize : null}</StyledTableCell>
          <StyledTableCell>{item.valveDetails && item.valveDetails.manufacturer ? item.valveDetails.manufacturer : null}</StyledTableCell>
          <StyledTableCell>{item.valveDetails && item.valveDetails.modelNumber ? item.valveDetails.modelNumber : null}</StyledTableCell>
          <StyledTableCell>{item && item.status ? item.status : null }</StyledTableCell>
        </StyledTableRow>
      ))
  }

  if (isLoading && !valveData )  {
    displayValveList = (
      <TableRow className="no-valve-row">
        <TableCell colSpan={3} />
        <TableCell colSpan={3} align="center">
          <CircularProgress />
        </TableCell>
        <TableCell colSpan={3} />
      </TableRow>
    );
  }else if (valveData && valveData.length) {
    displayValveList = valveList;
  } else if (valveData && valveData.length === 0) {
    displayValveList = (
        <TableRow className="no-valve-row">
            <TableCell colSpan={3} />
            <TableCell colSpan={3} align="center"> <h5>No Valves Found</h5> </TableCell>
            <TableCell colSpan={3} />
        </TableRow>
    );
  }

  const exportBtn = (
    <div className={classes.marginRight20}>
      <form method="POST" action="/api/v1/valves/export-csv">
        <input type="hidden"
          name="access_token"
          value={`${localStorage.getItem('bfv_access_token')}`} />
        <Button
          color="primary"
          variant="contained"
          size="small"
          type="submit"
        >
          Export Valves
      </Button>
      </form>
    </div>)

  return (
    <div>
      <TitleHeader title="Valve List" showSearch={true}>
        {exportBtn}
        <ValveListFilters
          projectId={projectId}
          filterQueries={filterValues}
          valveSizes={valveSizes}
          manufacturers={manufacturers}
          projectList={projectList}
          fetchFilteredData={triggerFilter}
          clearFilterValues={handleClearFilter}
          handleSelectProject={handleSelectProject}
          handleSelectFilter={handleSelectFilter}
          clearFilterState={clearFilterState}
        />
      </TitleHeader>
      <Paper className={`w3-section ${classes.root}`} style={{ padding: '10px' }}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Valve No </StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Valve Location</StyledTableCell>
              <StyledTableCell>Type</StyledTableCell>
              <StyledTableCell>PipeDiver Platform</StyledTableCell>
              <StyledTableCell>Size</StyledTableCell>
              <StyledTableCell>Manufacturer</StyledTableCell>
              <StyledTableCell>Model Number</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody className="valve-list">
            {displayValveList}
          </TableBody>
        </Table>
      </Paper>

      {(valveData && valveData.length > 0 && totalCount > limit) && (
        <Grid container className={`w3-center ${classes.paginateTopPadding}`}>
          <Pagination
            cPage={currentPage}
            defaultPerPage={limit}
            data={{ totalCount }}
            onChange={handlePagination}
          />
        </Grid>)}
    </div>

  );

}
