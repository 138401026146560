import React, { useEffect, useState } from 'react';
import {Box, Grid, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        //marginTop: '10px'
    },
    boxBackground: {
        backgroundColor: '#f5f5f5'
    }
}));

function ResultPage(props: any) {

    const classes = useStyles(); 
    const {result,paramObj, saveResultObjHandler} = props;

    useEffect(()=>{
        if(!result){
          return;
        }
        saveResultObjHandler(result);
      },[result]);

    if (!result) {
        return <div className="w3-text-red w3-padding-32 w3-margin">
            Unable to Identify a valid Pass Range for provided values
        </div>;
    }

    let passRange: any = [];
    if (result.passRangeList && result.passRangeList.length > 0) {
        result.passRangeList.forEach((element: any,idx:number) => {
                passRange.push(
                    <Box key={`key_${idx}`} textAlign="left" fontWeight="fontWeightBold" m={1}>
                        {`Angle Range: ${element.start} to ${element.end}, Direction: ${element.dir}`}
                    </Box>
                );
            }
        );
    }

    const ov = result.optimalValue;
    return (
        <div>
            <Grid container spacing={2} direction="column">
                <Grid item xs={12}>
                    <Typography component="div">
                        <Box textAlign="left" fontWeight="fontWeightBold" m={1}>
                            {ov.success ?
                                (<span className="w3-text-green">Success</span>)
                                :
                                (<span className="w3-text-red">Caution </span>)
                            }
                        </Box>
                    </Typography>
                </Grid>
                <Grid container spacing={2} direction="row">
                    <Grid item xs={6}>
                        <Typography component="div" className={classes.boxBackground}>
                            <Box fontWeight="fontWeightLight" m={1}>
                                Top Front Opening Value from center (A)
                            </Box>
                            <Box fontWeight="fontWeightBold" m={1}>
                                {ov.tfov} Inches
                            </Box>
                        </Typography>
                        <Typography component="div" className={classes.boxBackground}>
                            <Box fontWeight="fontWeightLight" m={1}>
                                Bottom Front Opening Value from center (C)
                            </Box>
                            <Box fontWeight="fontWeightBold" m={1}>
                                {ov.bfov} Inches
                            </Box>
                        </Typography>
                        <Typography component="div" className={classes.boxBackground}>
                            <Box fontWeight="fontWeightLight" m={1}>
                                Top Rear Opening Value (B)
                            </Box>
                            <Box fontWeight="fontWeightBold" m={1}>
                                {ov.trov} Inches
                            </Box>
                        </Typography>
                        <Typography component="div" className={classes.boxBackground}>
                            <Box fontWeight="fontWeightLight" m={1}>
                                Bottom Rear Opening Value (D)
                            </Box>
                            <Box fontWeight="fontWeightBold" m={1}>
                                {ov.brov} Inches
                            </Box>
                        </Typography>
                        <Typography component="div" className={classes.boxBackground}>
                            <Box fontWeight="fontWeightLight" m={1}>
                                Plate Opening Angle (ϕ)
                            </Box>
                            <Box fontWeight="fontWeightBold" m={1}>
                                {`${ov.theta}°,  ${ov.rotationInfo}`}
                            </Box>
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography component="div">
                            <Box textAlign="left" fontWeight="fontWeightBold" m={1}>
                                {ov.success ?
                                    (<span className="w3-text-green">Conclusion: Possible </span>)
                                    :
                                    (<span className="w3-text-red">Warning: Caution </span>)
                                }
                            </Box>
                        </Typography>

                        <Typography component="div" className={classes.boxBackground}>
                            <Box fontWeight="fontWeightLight" m={1}>
                                Suggested Recommendation
                            </Box>
                            <Box fontWeight="fontWeightBold" m={1}>
                                {ov.success &&
                                ('Open ' + ov.theta + ' degrees ' + ov.openDirection)
                                }
                            </Box>
                            <Box m={1} className="w3-text-red">
                                {!ov.success ? ov.recommendation :''}
                            </Box>
                        </Typography>
                        <Typography component="div" className={classes.boxBackground}>
                            <Box fontWeight="fontWeightLight" m={1}>
                                Suggested Pass Range
                            </Box>
                            {passRange.length > 0 ?  passRange :
                                <span className="w3-text-red w3-margin-left">No viable pass range found</span>
                            }
                        </Typography>

                        <Typography component="div" className={classes.boxBackground}>
                            <Box fontWeight="fontWeightLight" m={1}>
                                Reason for fail
                            </Box>
                            <Box fontWeight="fontWeightBold" m={1}>
                                {ov.success ?
                                    (<span>None</span>)
                                    :
                                    (<span className="w3-text-red">{ov.reason}</span>)
                                }
                            </Box>
                        </Typography>

                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default ResultPage;
