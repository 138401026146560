import React, { useState, useEffect } from 'react';
import ModalComponent from '../Common/Modal';
import { Grid, Button, TextField, Menu, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers, postCreateProject, postUpdateProject, getProjectList } from '../../Redux/actions';
import { ErrorHelperText } from '../Common/HelperInputFields';
import Select from 'react-select';
import MuiSelect from '@material-ui/core/Select';
import AppMessage from '../Common/AppMessage';
import Loader from '../Common/Loader';


const useStyles = makeStyles((theme)=>({
    modalHeader: {
        padding: '15px',
        alignItems: 'center'
    },
    modalFooter: {
        textAlign: 'right'
    },
    title: {
        fontWeight: 600,
        textAlign: 'center'
    },
    formGroup: {
        padding: '15px',
    },
    marginBottom5: {
        marginBottom: '5px',
    },
    marginTop10:{
        marginTop:'10px'
    },
    formControl: {
        marginLeft: '0px'
    },
    selectLabel:{
        background: 'white',
        padding: '0px 10px'
    },
    label:{
        background:'white',
        padding: '0px 5px'
    }
}));



const CreateProjectModal = (props: any) => {
    const { open, handleClose, editData,triggerList } = props;
    const classes = useStyles();
    const [alertMessage, setAlertMessage] = useState();
    const [errors, setErrors] = useState<any>({
        name: '',
        description: '',
        location: '',
        status:''
    });
    const [values, setValues] = useState<any>(Object.assign({
        name: '',
        description: '',
        location: '',
        status:''
    }, editData));
    const [selectedUsers, setUsers] = useState([]);
    const [selectedTeamLeads, setTeamLeads] = useState([]);
    const dispatch: any = useDispatch();
    const store: any = useSelector(store => store);
    const { usersList } = store;
    let userItems: any[] = [];
    const modalTitle: string = editData ? 'Edit Project' : 'Create New Project';
    const inputLabel = React.useRef<HTMLLabelElement>(null);
    const [ openAlert, setOpen ] = useState(false);
    const [ msgType, setMsgType ] = useState("success");
    const [ showLoader, setShowLoader ] = useState(false);
    let teamLeadUsers: any[] = [];

    useEffect(() => {
        dispatch(getAllUsers());
        if(!editData){
            return;
        }
        setUsers(editData.projectUsers
            .filter((item:any) => item.roles.name === 'project_admin')
            .map((item:any) => (
            {
                value: item.user.id,
                label: item.user.name
            }
        )));
        setTeamLeads(editData.projectUsers
            .filter((item:any) => item.roles.name === 'team_lead')
            .map((item:any) => (
            {
                value: item.user.id,
                label: item.user.name
            }
        )));
    }, [1]);

    if (usersList && usersList.data && usersList.data.length > 0) {
        userItems = usersList.data.map((item: any) => ({ label: item.name, value: item.id }));
    }

    if(userItems && userItems.length>0 && selectedTeamLeads && selectedTeamLeads.length > 0){
        userItems = userItems.filter((user:any) => !selectedTeamLeads.find((selectedUser:any) => selectedUser.value === user.value))
    }
    
    if(userItems && userItems.length>0){
        teamLeadUsers = userItems;
        if(selectedUsers && selectedUsers.length > 0){
            teamLeadUsers = userItems.filter((user:any) => !selectedUsers.find((selectedUser:any) => selectedUser.value === user.value))
        }
    }

    const validateForm = () => {
        let formIsValid = true;
        let err = Object.assign({}, errors);
        
        if (!values.projectCode) {
            formIsValid = false;
            err.projectCode = 'This field is required';
        }
        if (!values.client) {
            formIsValid = false;
            err.client = 'This field is required';
            values.name = values.client;
        }
        // if (!values.name) {
        //     formIsValid = false;
        //     err.name = 'This field is required';
        // }
        if (!values.description) {
            formIsValid = false;
            err.description = 'This field is required';
        }
        if (!values.location) {
            formIsValid = false;
            err.location = 'This field is required';
        }
        if (!values.status) {
            formIsValid = false;
            err.status = 'This field is required';
        }

        //front end validation for valve status
        if(values.status && values.status === 'Completed'){
            if(!editData){
                formIsValid = false;
                err.status = 'Cannot mark as Completed as it is a new Project with no Valves.';
            }else{
                if(editData.valves && editData.valves.length){
                    const valveFlag = editData.valves.some((item: any)=>((item.status !== 'Passed') && (item.status !== 'Failed') && (item.status !== 'Modified')));
                    if(valveFlag){
                        formIsValid = false;
                        err.status = 'The project has active valves. Please mark all valves completed.';
                    }
                } else {
                    formIsValid = false;
                    err.status = 'Cannot mark as Completed as the Project has no associated Valves.';
                }  
            } 
        }
        setErrors(err);
        return formIsValid;
    }

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setValues({
            ...values,
            [name]: value
        });

        const err = Object.assign({}, errors);
        if (err[name]) {
            err[name] = null;
        }
        setErrors(err);
    }

    const showAlert = (msg: any, type: any) => {
        setAlertMessage(msg); 
        setMsgType(type);
        setOpen(true);
      }
    
    const updateResponse = (resp: any, message: string) => {
        const res = resp && resp.data;
        let msg = res.message;
        let type = "fail";
        if (res && res.success) {
            msg = message;
            type = "success";
        } 
        setShowLoader(false);
        triggerList();
        showAlert(msg, type);
    };


    const handleSelect = ( selected: any) => {
        setUsers(selected);
    }
    
    const handleSelectTeamLead = (selected: any) => {
        setTeamLeads(selected);
    }

    const handleDialogClose = () => { 
        setAlertMessage(null);
        setOpen(false);
    };

    const handleSubmit = () => {
        const valid = validateForm();
        if (!valid) {
            return;
        }
        setShowLoader(true);
        const projectData = {
            id: values.id,
            name: values.client,
            description: values.description,
            location: values.location,
            status: values.status,
            users: selectedUsers,
            projectCode:values.projectCode,
            teamLeads:selectedTeamLeads,
            client: values.client,
            inspectTool:'',

        };

        if (editData && editData.id) {
            dispatch(postUpdateProject(projectData)).then((resp: any) => {
                updateResponse(resp, 'Project Updated Successfully...!')
            });
        } else {
            dispatch(postCreateProject(projectData)).then((resp: any) => {
                updateResponse(resp, 'Project Created Successfully...!')
            });
        }
    }

    return (
            <div style={{paddingBottom:'20px'}}>
                <Grid container className={classes.modalHeader}>
                    <Grid item xs={12} className={classes.title}>
                        {modalTitle}
                   </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} className={classes.marginBottom5}>
                        <TextField fullWidth
                            id="projectCode"
                            name="projectCode"
                            margin="dense"
                            label="Project Code"
                            value={values.projectCode || ''}
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            InputLabelProps={{
                                classes: {
                                  root: classes.label
                                }
                            }}
                            required
                        />
                        <ErrorHelperText error={errors.projectCode} />
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.marginBottom5}>
                        <TextField fullWidth
                            id="client"
                            name="client"
                            margin="dense"
                            label="Client"
                            value={values.client || ''}
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            InputLabelProps={{
                                classes: {
                                  root: classes.label
                                }
                            }}
                            required
                        />
                        <ErrorHelperText error={errors.client} />
                    </Grid>

                    {/* <Grid item xs={12} md={6} className={classes.marginBottom5}>
                        <TextField fullWidth
                            id="name"
                            name="name"
                            margin="dense"
                            label="Project Name"
                            value={values.name || ''}
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            required
                            InputLabelProps={{
                                classes: {
                                  root: classes.label
                                }
                            }}
                        />
                        <ErrorHelperText error={errors.name} />
                    </Grid> */}
                    <Grid item xs={12} className={classes.marginBottom5}>
                        <TextField fullWidth
                            multiline
                            rows={5}
                            id="description"
                            name="description"
                            margin="dense"
                            label="Description"
                            value={values.description || ''}
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            required
                            InputLabelProps={{
                                classes: {
                                  root: classes.label
                                }
                            }}
                        />
                        <ErrorHelperText error={errors.description} />
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.marginBottom5}>
                        <TextField fullWidth
                            id="location"
                            name="location"
                            margin="dense"
                            label="Location"
                            value={values.location || ''}
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            required
                            InputLabelProps={{
                                classes: {
                                  root: classes.label
                                }
                            }}
                        />
                        <ErrorHelperText error={errors.location} />
                    </Grid>
                    
                    {/* <Grid item xs={12} md={6} className={classes.marginBottom5}>
                        <TextField fullWidth
                            id="inspectTool"
                            name="inspectTool"
                            margin="dense"
                            label="Inspection Tool"
                            value={values.inspectTool || ''}
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            InputLabelProps={{
                                classes: {
                                  root: classes.label
                                }
                            }}
                        />
                        <ErrorHelperText error={errors.inspectTool} />
                    </Grid> */}
                    <Grid item xs={12} md={6} className={classes.marginBottom5}>
                        <FormControl variant="outlined" fullWidth margin="dense"className={classes.formControl}>
                            <InputLabel className={classes.selectLabel} ref={inputLabel} id="statusLabel">
                            Select Status*
                            </InputLabel>
                            <MuiSelect
                                labelId="statusLabel"
                                id="status"
                                name="status"
                                value={values.status}
                                onChange={v => handleChange(v)}
                                fullWidth
                            >
                                <MenuItem value="Active">Active</MenuItem>
                                <MenuItem value="Completed">Completed</MenuItem>
                            </MuiSelect>
                        </FormControl>
                        <ErrorHelperText error={errors.status} />           
                    </Grid>
                    <Grid item xs={12} className={classes.marginBottom5}>
                        <Select
                            isMulti
                            name="users"
                            label="Select Project Managers"
                            options={userItems}
                            placeholder="Select Project Managers"
                            value={selectedUsers}
                            onChange={(e)=>handleSelect(e)}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.marginBottom5}>
                        <Select
                            isMulti
                            name="teamLeads"
                            label="Select Team leader"
                            options={teamLeadUsers}
                            placeholder="Select Team Leads"
                            value={selectedTeamLeads}
                            onChange={(e)=>handleSelectTeamLead(e)}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.modalFooter}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Button
                                variant="contained"
                                size="small"
                                onClick={handleClose}
                            >Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                color="primary"
                                variant="contained"
                                size="small"
                                onClick={handleSubmit}
                            >Save
                            </Button>
                        </Grid>
                    </Grid>
                    </Grid>
                </Grid>

                {alertMessage ?
                    (
                        <AppMessage
                            handleClose={() => {
                                setAlertMessage(null); 
                                handleClose();
                            }}
                            message={alertMessage}
                            open={openAlert}
                            type={msgType}
                            handleDialogClose={handleDialogClose}
                        />
                    )
                    : null
                }
                { showLoader ? <Loader open={showLoader}/> : null}
            </div>
    );
}

export default CreateProjectModal;