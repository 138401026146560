import React from 'react';
import Login from '../Components/Auth/Login';
import Register from '../Components/Auth/Register';
import ForgotPassword from '../Components/Auth/ForgotPassword';
import ResetPassword from '../Components/Auth/ResetPassword';
import { useRoutes } from 'hookrouter';
import Invite from '../Components/Auth/Invite';
import TopBar from '../Components/Common/TopBar';
const img  = 'https://cdn.ceegees.in/xylem/xylem-wave.png';

const routes = {
    "/": () => <Login />,
    "/login": () => <Login />,
    "/register": () => <Register />,
    "/forgot-password": () => <ForgotPassword />,
    "/reset-password/:token": ({ token }: any) => <ResetPassword token={token} />,
    "/invite/:token": ({ token }: any) => <Invite token={token} />,
};

const LayoutRoutes = () => {
    const content =  useRoutes(routes) || <Login />;
    return <div className="app-auth-form"> 
            <TopBar /> 
            <div className="w3-padding">
            {content} 
            </div>
            <div className="app-footer">
                <img src={img}/>
                <div className="copy-right">
                    Copyright © 2020 Xylem Inc. All rights reserved.
                </div>
            </div>
    </div>
    
}

export default LayoutRoutes;
