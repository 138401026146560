import {makeStyles} from '@material-ui/styles';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {findValveDetailById} from '../../Redux/actions';
import {Paper, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';

import AlertDialog from '../Common/AlertDialog';
import {createStyles, Theme, withStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        '& .MuiTextField-root': {
            width: 200
        },
        width: '100%',
        overflowX: 'auto'
    },
    formGroup: {
        padding: '30px 40px'
    },
    marginBottom10: {
        marginBottom: '10px'
    },
    formControl: {
        width: '25%'
    },
    selectLabel: {
        background: 'white',
        padding: '0px 10px'
    },
    label: {
        background: 'white',
        padding: '0px 5px'
    }
});

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.black,
            fontSize: 15,
            fontWeight: 'bold'
        },
        body: {
            fontSize: 14
        }
    })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            flexShrink: 0,
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.background.default
            }
        }
    })
)(TableRow);

const FeedBackList = (props: any) => {
    const dispatch: any = useDispatch();
    const classes = useStyles();
    const {valveId} = props;
    const [detailData, setListData] = useState();
    const [alertMessage, setAlertMessage] = useState();
    const [resultData , setResultData] = useState();

    const fetchData = () => {
        dispatch(findValveDetailById(valveId)).then((resp: any) => {
            if (resp && resp.status === 200) {
                setListData(resp.data);
                setResultData(resp.data.resultAngleRange);
            } else {
                setAlertMessage('Something went wrong...!');
            }
        });
    };
    useEffect(() => {
        fetchData();
    }, [dispatch]);
    return (
        <div>
            <div className='card-padding'>
                <Paper className={classes.root}>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                {
                                    resultData &&
                                <StyledTableCell>Calculated Angle</StyledTableCell>
                                }
                                {
                                    resultData &&
                                    <StyledTableCell>Direction</StyledTableCell>
                                }
                                <StyledTableCell>FeedBack</StyledTableCell>
                                {
                                    resultData &&
                                    <StyledTableCell>Angle</StyledTableCell>
                                }
                                <StyledTableCell>Result</StyledTableCell>
                                {
                                    (detailData && detailData.json && detailData.json.action) &&
                                <StyledTableCell>Action Taken</StyledTableCell>
                                }
                                <StyledTableCell>Notes</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <StyledTableRow>
                                {
                                resultData &&
                            <StyledTableCell>{resultData.start} to {resultData.end }</StyledTableCell>
                            }
                                {
                                    resultData &&
                                    <StyledTableCell>{resultData.dir}</StyledTableCell>
                                }
                                <StyledTableCell>{(detailData && detailData.json) ? detailData.json.feedback : ''}</StyledTableCell>
                                {
                                    resultData &&
                                    <StyledTableCell>{(detailData && detailData.json) ? detailData.json.angle : ''}</StyledTableCell>
                                }
                                <StyledTableCell>{(detailData && detailData.json) ? detailData.json.result : ''}</StyledTableCell>
                                { (detailData && detailData.json && detailData.json.action) &&
                                <StyledTableCell>{(detailData && detailData.json) ? detailData.json.action : ''}</StyledTableCell>
                                }
                                <StyledTableCell>{(detailData && detailData.json) ? detailData.json.notes : ''}</StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </Paper>
            </div>
            {alertMessage ?
                (
                    <AlertDialog
                        handleClose={() => {
                            setAlertMessage(null);
                        }}
                        message={alertMessage}
                    />
                )
                : null
            }
        </div>
    );
};

export default FeedBackList;
