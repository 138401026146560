import {Grid, Box, Breadcrumbs, Link, Paper, Typography} from '@material-ui/core';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { usePath } from 'hookrouter';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getStandards } from '../../Redux/actions';
import AlertDialog from '../Common/AlertDialog';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }),
)(TableRow);

const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  uploadFile: {
    float: 'right',
  },
  inputFile: {
    color: '#000',
    backgroundColor: '#EEEEEE',
    fontSize: '0.875rem',
    lineHeight: '1.75',
  },
  formControl: {
    minWidth: 300,
  },
  padding10:{
    padding:'10px'
  },
  marginBottom10:{
    marginBottom:'10px'
  },
});

export default function Standards(props: any) {
  const classes = useStyles();
  const { handleClose, id } = props;
  const dispatch: any = useDispatch();
  const initialData: any[] = [];
  const [data, setData] = useState(initialData);
  const [alertMessage, setAlertMessage] = useState();
  const path = usePath();
  const splitedPath = path.split('/');
  const [standardName, setStandardName] = useState();
  const standardTermId: number = parseInt(splitedPath[2]);

  const fetchData = () => {
    dispatch(getStandards(standardTermId)).then((resp: any) => {
      if (resp && resp.status === 200) {
        setStandardName(resp.data[0].standardName);
        setData(resp.data);

      }
    });
  };
  useEffect(() => {
    fetchData();
  }, [dispatch]);

  return (
    <div className='card-padding'>
      {standardName && <Box bgcolor="#EEEEEE" className={classes.marginBottom10}>
        <Grid container justify="flex-start" alignItems="center">
          <Grid item xs={8} md={6}>
            <div className={classes.padding10}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href="/standards">
                  Standards
                </Link>
                <Typography
                  color="textPrimary"
                  aria-current="page">
                  {standardName}
                </Typography>
              </Breadcrumbs>
            </div>
          </Grid>
        </Grid>
      </Box>}
      <Paper className={classes.root}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Standard Class</StyledTableCell>
              <StyledTableCell>Valve Diameter</StyledTableCell>
              <StyledTableCell>Shaft Diameter</StyledTableCell>
              <StyledTableCell>Max Disc Thickness</StyledTableCell>
              <StyledTableCell>Shaft Eccentricity</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(data => (
              <StyledTableRow key={data.standardsId}>
                <StyledTableCell>{data.standardClass}</StyledTableCell>
                <StyledTableCell>{data.valveDiameter}</StyledTableCell>
                <StyledTableCell>{data.shaftDiameter}</StyledTableCell>
                <StyledTableCell>{data.maxDiscThickness}</StyledTableCell>
                <StyledTableCell>{data.shaftEccentricity}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      {alertMessage ?
        (
          <AlertDialog
            handleClose={() => {
              setAlertMessage(null);
              handleClose();
            }}
            message={alertMessage}
          />
        )
        : null
      }
    </div>

  );

}