import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Fade,
  CircularProgress,
  Box,
  Tooltip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AlertDialog from '../Common/AlertDialog';
import UserModal from './UserModal';
import { getInvitedUserList, 
  deleteInviteUser, 
  findUser, 
  changeAdminStatus,
  getRoleUsers
 } from '../../Redux/actions';
import SearchBox from '../Common/SearchBox';
import Pagination from '../Common/Pagination';
import RoleChecker from '../Common/RoleChecker';
import TitleHeader from '../Common/TitleHeader';
import DialogModal from '../Common/DialogModal';
import ConfirmDialog from '../Common/ConfirmDialog';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: '8px'
  },
  card: {
    height: 120,
    width: '100%',
    backgroundColor: '#FFFFFF',
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 400,
    //padding: '10px',
    //fontSize: '14px',
    display: 'inline-block',
    [theme.breakpoints.up('md')]: {
      width: '12vw'
    },
    [theme.breakpoints.down('sm')]: {
      width: '40vw'
    },
    [theme.breakpoints.down('xs')]: {
      width: '65vw'
    }
  },
  content: {
    padding:'5px 10px'
  },
  cardHeader:{
    padding:'10px'
  },
  contentText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    [theme.breakpoints.up('md')]: {
      width: '10vw'
    },
    [theme.breakpoints.down('sm')]: {
      width: '40vw'
    },
    [theme.breakpoints.down('xs')]: {
      width: '40vw'
    }
  },
  spacing: {
    marginLeft: theme.spacing(1)
  },
  margin: {
    margin: theme.spacing(1)
  },
  addUserCard: {
    marginTop: '50px'
  },
  paginateTopPadding: {
    paddingTop: '50px'
  },
  userCardSideTitle:{
    fontSize: '13px'
  },
  toolTip:{
    fontSize:'13px'
  }
}));

export default function ManageUsers(props: any) {
  const classes = useStyles();
  const dispatch: any = useDispatch();
  const initialData: any[] = [];
  let manageUsers: any = null;
  const [data, setData] = useState(initialData);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState();
  const [alertMessage, setAlertMessage] = useState();
  const [anchorEl, setAnchorEl] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const menuOpen = Boolean(anchorEl);
  const [totalCount, setTotalCount] = useState(0);
  const [searchStr, setSearchStr] = useState('');
  const [adminList,setAdminList] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(Object.assign({}));

  const limit = 15;
  const initialPaginateData = {
    page: 1,
    offset: 0,
    limit
  };
  const [ currentPage, setCurrentPage ] = useState(1);

  const fetchData = (paginateData: any) => {
    setIsLoading(true);
    dispatch(getRoleUsers('admin'))
    .then((resp:any)=> {
      console.log(resp.data);
      setAdminList(resp.data.data);
    });
    dispatch(getInvitedUserList(paginateData))
    .then((resp: any) => {
      if (resp && resp.status === 200) {
        setData(resp.data.data);
        setTotalCount(resp.data.total);
        setIsLoading(false);
      }
    });
  };

  const fetchSearchData = (searchData: any) => {
    setIsLoading(true);
    dispatch(findUser(searchData)).then((resp: any) => {
      setData(resp.data.data);
      setTotalCount(resp.data.total);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchData(initialPaginateData);
  }, [dispatch]);

  const handleSearch = async (e: any) => {
    const search = e.target.value.toLowerCase();
    const searchQueryData = {
      search,
      page: 1,
      offset: 0,
      limit
    };

    if (search !== '') {
      setSearchStr(search);
      fetchSearchData(searchQueryData);
    } else if (search === '') {
      setSearchStr('');
      fetchData(initialPaginateData);
    }
    setCurrentPage(1);
  };

  const handleMenuClick = (event: any, id: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedItem(id);
  };

  const handleMenuClose = () => {
    const value: any = null;
    setAnchorEl(value);
    setSelectedItem(value);
  };

  const handleEditInvite = (item: object) => {
    setOpen(true);
    setEditData(item);
    handleMenuClose();
  };

  const handleDeleteInvite = () => {
    if(selectedUser){
    setShowConfirmDialog(false);
    handleMenuClose();
    dispatch(deleteInviteUser(selectedUser.id)).then((resp: any) => {
      const res = resp && resp.data;
      if (res && res.success) {
        setAlertMessage('User Invite deleted successfully...!');
        setSelectedUser({});
        setCurrentPage(1);
        fetchData(initialPaginateData);
      } else {
        setAlertMessage('User Invite deletion failed...!');
      }
    });
  }
  };

  const handleMakeSuperAdmin = (user:any, isAdmin:boolean) => {
    handleMenuClose();
    dispatch(changeAdminStatus(
      parseInt(`${user.userId}`),
      isAdmin ? 'remove': 'add'
    )).then((resp:any) => {
      if (!resp.data.success){
        alert(resp.data.message);
        return;
      }
      fetchData(initialPaginateData);
    });
  };

  const handlePagination = (page: any, perPage: any) => {
    setCurrentPage(page);
    const paginateData = {
      page,
      offset: perPage,
      limit
    };
    if (searchStr !== '') {
      Object.assign(paginateData, { search: searchStr });
      fetchSearchData(paginateData);
    } else {
      fetchData(paginateData);
    }
  };

  const triggerDelete = (user: any) =>{
    setSelectedUser(user);
    setShowConfirmDialog(true);
  }

  const addUserCard = <Grid item xs={12} md={3} style={{ padding: '8px' }}>
    <Card className={` ${classes.card} add-item-card`} onClick={() => setOpen(true)}
          style={{ cursor: 'pointer' }}>
      <Typography className={classes.addUserCard} display="block" align="center">
        + Add New User
      </Typography>
    </Card>
  </Grid>;

  let userList: any[] = [];
  if (data && data.length) {
    userList = data.map((user: any, idx: number) => { 
      const isAdmin = adminList.some((item:any)=> item.userId == user.userId); 
      return (
      <Grid item xs={12} md={3}  key={`usr_${user.id}`}
            className={classes.root}>
        <Card className={classes.card}>
          <CardHeader className={classes.cardHeader}
            action={
              <RoleChecker>
                <IconButton aria-label="settings" aria-controls="fade-menu" 
                aria-haspopup="true" onClick={(e) => handleMenuClick(e, user.id)}>
                  <MoreVertIcon/>
                </IconButton>
              </RoleChecker>
            }
            title={<span className={classes.title}><Tooltip title={<span className={classes.toolTip}>{user.name}</span>} interactive={true} arrow={true}><span>{user.name}</span></Tooltip></span>}
          />
          <CardContent className={classes.content}>
            <Typography>
              <span className={`w3-text-gray ${classes.userCardSideTitle}`}>Status - </span>{user.status}
            </Typography>
            <Typography>
              { isAdmin &&
                <><span className={`w3-text-gray ${classes.userCardSideTitle}`}>Access - </span>Super Admin</>
              }
            </Typography>
          </CardContent>
        </Card>
        <Menu
          key={user.id}
          anchorEl={anchorEl}
          keepMounted
          open={selectedItem === user.id ? menuOpen : false}
          onClose={handleMenuClose}
          TransitionComponent={Fade}
        >
          <MenuItem onClick={(e) => {
            e.stopPropagation();
            handleEditInvite(user);
          }}>Edit</MenuItem>
          {user.userId && <RoleChecker>
          <MenuItem onClick={(e) => {
            e.stopPropagation();
            handleMakeSuperAdmin(user,isAdmin);
          }}> {!isAdmin ? 'Make SuperAdmin' : 'Remove From Super Admin'}</MenuItem>
          </RoleChecker>
          }
          <MenuItem onClick={(e) => {
            e.stopPropagation();
            triggerDelete(user);
          }}>Delete</MenuItem>
        </Menu>
      </Grid>
      );
    });
  }

  if (isLoading || !data) {
    manageUsers = (
      <Grid item xs={12} md={12}>
        <div className="textMarginCenter">
          <CircularProgress/>
        </div>
      </Grid>
    );
  } else if (data && data.length) {
    manageUsers = userList;
  } else if (data && data.length === 0) {
    manageUsers = (
      <Grid item xs={12} md={12} className="textMarginCenter">
        <h5> No Users Found</h5>
      </Grid>
    );
  }

  return (
    <div>
      <TitleHeader title="Users" showSearch={false}>
        <div style={{ marginRight: '10px' }}>
          <SearchBox
            value={searchStr}
            placeholder={'Search Users...'}
            handleSearch={handleSearch}
          />
        </div>
      </TitleHeader>
      <Grid container>
        <RoleChecker>
          {addUserCard}
        </RoleChecker>
        {manageUsers}
        {(data && data.length > 0 && totalCount > limit) && (
          <Grid container className={`w3-center ${classes.paginateTopPadding}`}>
            <Pagination
              cPage={currentPage}
              defaultPerPage={limit}
              data={{ totalCount }}
              onChange={handlePagination}
            />
          </Grid>)}
      </Grid>

      {open ?
        (<DialogModal
          fullWidth
          isOpen={open}
          hideTitle={true}
          hideBtns={true}
          maxWidth="sm"
          dialogContent={(
            <UserModal
              triggerList={() => {
                setSearchStr('')
                setCurrentPage(1);
                fetchData(initialPaginateData)
              }}
              open={open}
              editData={editData}
              handleClose={() => {
                setOpen(false);
                setEditData(null);
              }}
            />
          )}
        />)
      : null}
      {alertMessage ? (
        <AlertDialog
          handleClose={() => setAlertMessage(null)}
          message={alertMessage}
        />) : null}
            {showConfirmDialog
        ? (
          <ConfirmDialog
            handleCancel={() => {
              handleMenuClose()
              setSelectedUser({})
              setShowConfirmDialog(false)
            }}
            handleOk={handleDeleteInvite}
            message={'Are you sure to delete the user invitation?'}
          />
        ): null}
    </div>
  );

}
