import {Button, Card, CardActions, CardContent, CardHeader, Grid, Typography} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {navigate} from 'hookrouter';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {changeUserName, changeUserPassword, getCurrentUser } from '../../Redux/actions';
import AlertDialog from './AlertDialog';
import {TextInputField} from './HelperInputFields';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

const useStyles = makeStyles(theme => ({}));

const panelStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%'
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular
        }
    })
);

const Settings = (props: any) => {
    const classes = useStyles();
    const dispatch: any = useDispatch();
    const initForm: any = {
        currentPassword: '',
        password: '',
        confirm: ''
    };
    const initUserName: any = {
        userName: ''
    };
    const [passReg, setPassReg] = useState();
    const panel = panelStyles();
    const initErr: any = {};
    const [userNameForm, setUserNameForm] = useState(initUserName);
    const [form, setForm] = useState(initForm);
    const [errors, setErrors] = useState(initErr);
    const [alertMessage, setAlertMessage] = useState();
    const [flag, setFlag] = useState();

    const handleChange = (e: any) => {
        const {value, name} = e.target;
        const fieldValue = Object.assign({}, form);
        const errorField = Object.assign({}, errors);
        if (errorField[name]) {
            errorField[name] = null;
            setErrors(errorField);
        }
        fieldValue[name] = value;
        setPassReg(false);

        setForm(fieldValue);
    };

    const handleUserNameChange = (e: any) => {
        const {value, name} = e.target;
        const fieldValue = Object.assign({}, userNameForm);
        const errorField = Object.assign({}, errors);
        if (errorField[name]) {
            errorField[name] = null;
            setErrors(errorField);
        }
        fieldValue[name] = value;
        setPassReg(false);

        setUserNameForm(fieldValue);
    };

    const validateData = () => {
        let hasError = false;
        const err = Object.assign({}, errors);
        if(form.password === form.currentPassword)
        {
            hasError = true;
            err.password = 'Current Password & New Password is Similar';
        }
        if (form.password !== form.confirm) {
            hasError = true;
            err.confirm = 'Password and confirm password must be same.';
        }

        const regex = /^(?=.*[a-z]+)(?=.*[A-Z]+)(?=.*[0-9]+)(?=.*[!@#$%^&*]).{8,}$/;
        if (!regex.test(form.password)) {
            hasError = true;
            setPassReg(true);
            err.password = 'Password Doesnt meet the requirements';
        }

        Object.keys(form).forEach((key) => {
            if (!form[key]) {
                hasError = true;
                err[key] = 'This field is required';
            }
        });
        if (hasError) {
            setErrors(err);
            return false;
        }
        return form;
    };
    const handleSubmitUserName = (e: any) => {
        e.preventDefault();
        const data = {
            username: userNameForm.userName
        };
        if (data) {
            dispatch(changeUserName(data)).then((resp: any) => {
                const res = resp && resp.data;
                if (res && res.success) {
                    dispatch(getCurrentUser())
                    setFlag(0);
                    setAlertMessage('UserName Change successfully.');
                } else if (res && res.data) {
                    setErrors(res.data);
                } else {
                    setAlertMessage('Something went wrong. Try again...!');
                }
            });
        }
    };
    const handleSubmit = (e: any) => {
        e.preventDefault();
        const valid = validateData();
        if (valid) {
            dispatch(changeUserPassword(form)).then((resp: any) => {
                const res = resp && resp.data;
                if (res && res.success) {
                    setFlag(1);
                    setAlertMessage('Password reset successfully. Login now...!');
                    localStorage.removeItem('bfv_access_token');
                } else if (res && res.data) {
                    setErrors(res.data);
                } else {
                    setAlertMessage('Something went wrong. Try again...!');
                }
            });
        }
    };
    return (
        <div>
            <Grid container className="w3-padding-16">
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardHeader title="Change Username"/>
                        <form onSubmit={(e) => {
                            handleSubmit(e);
                        }}>
                            <CardContent>
                                <TextInputField
                                    type="text"
                                    name="userName"
                                    placeholder="New Username"
                                    variant="outlined"
                                    margin="dense"
                                    onChange={handleUserNameChange}
                                    value={userNameForm.userName}
                                    errors={errors.userName}
                                />
                            </CardContent>

                            <CardActions className="padding16">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    style={{marginLeft: 'auto'}}
                                    type="submit"
                                    onClick={(e) => handleSubmitUserName(e)}
                                >Save
                                </Button>
                            </CardActions>
                        </form>
                    </Card>
                </Grid>
            </Grid>
            <Grid container className="w3-padding-16">
                <Grid item xs={12} md={6}>

                    <Card>
                        <CardHeader title="Change Password"/>
                        <form onSubmit={(e) => {
                            handleSubmit(e);
                        }}>
                            <CardContent>
                                <TextInputField
                                    type="password"
                                    name="currentPassword"
                                    placeholder="Current Password"
                                    variant="outlined"
                                    margin="dense"
                                    onChange={handleChange}
                                    errors={errors.currentPassword}
                                />
                                <TextInputField
                                    type="password"
                                    name="password"
                                    placeholder="New Password"
                                    variant="outlined"
                                    margin="dense"
                                    onChange={handleChange}
                                    errors={errors.password}
                                />
                                {
                                    passReg === true &&
                                    <div className={panel.root}>
                                        <ExpansionPanel>
                                            <ExpansionPanelDetails>
                                                <Typography className="w3-text-red">
                                                    <li> Minimum password length 8</li>
                                                    <li>Require at least one digit</li>
                                                    <li>Require at least one upper case</li>
                                                    <li> Require at least one lower case letter</li>
                                                    <li>Require at least one symbol</li>
                                                </Typography>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </div>
                                }
                                <TextInputField
                                    type="password"
                                    name="confirm"
                                    placeholder="Confirm Password"
                                    variant="outlined"
                                    margin="dense"
                                    onChange={handleChange}
                                    errors={errors.confirm}
                                />
                            </CardContent>

                            <CardActions className="padding16">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    style={{marginLeft: 'auto'}}
                                    type="submit"
                                    onClick={(e) => handleSubmit(e)}
                                >Save
                                </Button>
                            </CardActions>
                        </form>
                    </Card>
                </Grid>
            </Grid>
            {alertMessage
                ? (
                    <AlertDialog
                        handleClose={() => {
                            setAlertMessage(null);

                            if (flag === 1) {
                                window.location.reload();
                                navigate('/login');
                            }
                            navigate('/dashboard');
                        }}
                        message={alertMessage}
                    />
                ) : null}
        </div>
    );
};

export default Settings;

