import { Button, FormControl, Grid, InputLabel, MenuItem, TextField } from '@material-ui/core';
import MuiSelect from '@material-ui/core/Select';
import { makeStyles, Theme } from '@material-ui/core/styles';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { getAllUsers, getValveList, postCreateValve, putEditValve } from '../../Redux/actions';
import { ErrorHelperText, DateInputField } from '../Common/HelperInputFields';
import ModalComponent from '../Common/Modal';
import AppMessage from '../Common/AppMessage';

const useStyles = makeStyles(theme => ({
  modalHeader: {
    padding: '15px',
    alignItems: 'center',
  },
  title: {
    fontWeight: 500,
    textAlign: 'center',
  },
  formGroup: {
    padding: '30px 40px',
  },
  marginBottom10: {
    marginBottom: '10px',
  },
  marginTop10: {
    marginTop: '10px',
  },
  selectLabel: {
    background: 'white',
    padding: '0px 10px',
  },
  formControl: {
    marginLeft: '0px',
  },
  label:{
      background:'white',
      padding: '0px 5px'
  }
}));

const ValveModal = (props: any) => {
  const { open, handleClose, editData, projectId, triggerList } = props;
  const classes = useStyles();
  const dispatch: any = useDispatch();
  const [alertMessage, setAlertMessage] = useState();
  const error: any = {};
  const [errors, setErrors] = useState(error);
  let formData: any = {
    valveNo: '',
    name: '',
    location: '',
    valveDate: null,
    users: [],
  };
  if (editData) {
    formData = editData;
  }
  const [form, setForm] = useState(formData);
  //const [selectedUsers, setUsers] = useState();
  const store: any = useSelector(store => store);
  //const { usersList, roleUsersByRefId } = store;
  let selectOptions: any[] = [];
  const modalTitle: string = editData ? 'Edit Valve' : 'Add New Valve';
  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [openAlert, setOpen] = useState(false);
  const [ msgType, setMsgType ] = useState("success");

  // useEffect(() => {
  //   if (!usersList) {
  //     dispatch(getAllUsers());
  //   }
  //   if (!editData) {
  //     return;
  //   }
  //   setUsers(editData.valveUsers.map((item: any) => (
  //     {
  //       value: item.user.id,
  //       label: item.user.name,
  //     }
  //   )));
  // }, [1]);

  // if (usersList && usersList.data && usersList.data.length > 0) {
  //   selectOptions = usersList.data.map((item: any) => ({ label: item.name, value: item.id }));
  // }

  const handleDateChange = (date: any, field: string) => {
    const formData = { ...form };
    const err = { ...errors };
    formData[field] = date;
    if (err[field]) {
      err[field] = null;
    }
    setErrors(err);
    setForm(formData);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const data: any = Object.assign({}, form);
    data[name] = value;
    const err = Object.assign({}, errors);
    if (err[name]) {
      err[name] = null;
    }
    setErrors(err);
    setForm(data);
  };

  const showAlert = (msg: any, type: any) => {
    setAlertMessage(msg); 
    setMsgType(type);
    setOpen(true);
  }

  const updateResponse = (resp: any, message: string) => {
    const res = resp && resp.data;
    let msg = res.message;
    let type = "fail";
    if (res && res.success) {
      msg = message;
      type = "success";
    } 
    triggerList();
    showAlert(msg, type);
  };

    const handleDialogClose = () => { 
        setAlertMessage(null);
        setOpen(false);
    };


    const validateData = () => {
        const err: any = {};
        if (!form.valveNo) {
            err['valveNo'] = 'Field is Required';
        }
        if (!form.name) {
            err['name'] = 'Field is Required';
        }
        if (!form.location) {
            err['location'] = 'Field is Required';
        }
        if (!form.valveDate) {
            err['valveDate'] = 'Field is Required';
        }else if(form.valveDate){
          const date = moment(form.valveDate).format('YYYY-MM-DD');
          if(!(moment(date,'YYYY-MM-DD',true).isValid())){
            err['valveDate'] = 'Please enter a valid date';
          }
        }
        if (Object.keys(err).length > 0) {
            setErrors(err);
            return false;
        }
        return true;
    };

  const handleSubmit = () => {
    const checkForm = validateData();
    if (checkForm) {
      Object.assign(form, {
        projectId: props.projectId,
        //users: selectedUsers,
      });
      if (form.id) {
        dispatch(putEditValve(form, form.id)).then((resp: any) => {
          updateResponse(resp, 'Valve edited successfully...!');
        });
      } else {
        dispatch(postCreateValve(form))
          .then((resp: any) => {
            updateResponse(resp, 'Valve created successfully...!');
          });
      }
    }
  };


  // const handleNameChange = (selectedOptions: any) => {
  //   setUsers(selectedOptions);
  // };

    return (
            <div style={{paddingBottom:'20px'}}>
                <Grid container className={classes.modalHeader}>
                    <Grid item xs={12} className={classes.title}>
                        {modalTitle}
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} className={classes.marginBottom10}>
                        <TextField
                            required
                            fullWidth
                            variant="outlined"
                            label="Valve ID"
                            margin="dense"
                            name="valveNo"
                            value={form.valveNo || ''}
                            onChange={handleChange}
                            InputLabelProps={{
                              classes: {
                                root: classes.label
                              }
                          }}
                        />
                        <ErrorHelperText error={errors.valveNo} />
                    </Grid>
                    <Grid item xs={12} className={classes.marginBottom10}>
                        <TextField
                            required
                            fullWidth
                            variant="outlined"
                            label="Name"
                            margin="dense"
                            name="name"
                            value={form.name || ''}
                            onChange={handleChange}
                            InputLabelProps={{
                              classes: {
                                root: classes.label
                              }
                          }}
                        />
                        <ErrorHelperText error={errors.name} />
                    </Grid>
                    <Grid item xs={12} className={classes.marginBottom10}>
                        <TextField
                            required
                            fullWidth
                            variant="outlined"
                            label="Valve Location (STA#)"
                            name="location"
                            value={form.location || ''}
                            onChange={handleChange}
                            margin="dense"
                            InputLabelProps={{
                              classes: {
                                root: classes.label
                              }
                          }}
                        />
                        <ErrorHelperText error={errors.location} />
                    </Grid>
                    <Grid item xs={12} className={classes.marginBottom10}>
                      <DateInputField
                        fullWidth
                        disableToolbar={true}
                        margin="dense"
                        inputVariant="outlined"
                        placeholder="DD/MM/YYYY"
                        label="Inspection Date"
                        name="valveDate"
                        format="dd/MM/yyyy"
                        value={form.valveDate ? moment(form.valveDate).format('YYYY-MM-DD') : null}
                        onChange={(date: any) => handleDateChange(date, "valveDate")}
                        error={errors.valveDate}
                      />
                    </Grid>
                    {/* <Grid item xs={12} className={classes.marginBottom10}>
                        <Select
                            isMulti
                            name="users"
                            options={selectOptions}
                            placeholder="Select Valve Users"
                            value={selectedUsers}
                            onChange={handleNameChange}
                        />
                    </Grid> */}
                    <Grid item xs={12} className={classes.marginTop10}>
                        <Grid container spacing={2} justify="flex-start">
                            <Grid item>
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={handleClose}
                                >Cancel
                                </Button>
                            </Grid>
                        <Grid item>
                            <Button
                                color="primary"
                                variant="contained"
                                size="small"
                                onClick={handleSubmit}
                            >
                              {'Save'}
                            </Button>
                        </Grid>
                    </Grid>
                    </Grid>
                </Grid>
                {alertMessage ?
                    (
                        <AppMessage
                            handleClose={() => {
                                setAlertMessage(null); 
                                handleClose();
                            }}
                            message={alertMessage}
                            open={openAlert}
                            type={msgType}
                            handleDialogClose={handleDialogClose}
                        />
                    )
                    : null
                }
            </div>
    );
}

export default ValveModal;