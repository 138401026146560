import {Box, Button, Grid, MenuItem, TextField} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import {makeStyles} from '@material-ui/styles';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {feedback, findValveDetailById} from '../../Redux/actions';
import AlertDialog from '../Common/AlertDialog';
import {ErrorHelperText, TextInputField} from '../Common/HelperInputFields';

const useStyles = makeStyles({
    root: {
        '& .MuiTextField-root': {
            width: 200
        },
        width: '100%',
        overflowX: 'auto'
    },
    formGroup: {
        padding: '30px 40px'
    },
    marginBottom10: {
        marginBottom: '10px'
    },
    formControl: {
        width: '25%'
    },
    selectLabel: {
        background: 'white',
        padding: '0px 10px'
    },
    label: {
        background: 'white',
        padding: '0px 5px'
    }
});

const FeedBackForm = (props: any) => {
    const {valveId, getValveInfo, paramObj} = props;
    const dispatch: any = useDispatch();
    const classes = useStyles();
    const [detailData, setListData] = useState();
    const [alertMessage, setAlertMessage] = useState();
    const [values, setValues] = useState<any>({
        feedback: '',
        angle: '',
        result: '',
        action: '',
        notes: ''
    });
    const [errors, setErrors] = useState<any>({
        feedback: '',
        angle: '',
        result: ''
    });
    const validateForm = () => {
        let formIsValid = true;
        let err = Object.assign({}, errors);

        if (!values.feedback) {
            formIsValid = false;
            err.feedback = 'This field is required';
        }

        if (!values.result) {
            formIsValid = false;
            err.result = 'This field is required';
        }

        setErrors(err);
        return formIsValid;
    };
    const inputLabel = React.useRef<HTMLLabelElement>(null);

    const handleChange = (event: any) => {
        const {name, value} = event.target;
        setValues({
            ...values,
            [name]: value
        });

        const err = Object.assign({}, errors);
        if (err[name]) {
            err[name] = null;
        }
        setErrors(err);
    };

    const updateResponse = (resp: any) => {
        const res = resp && resp.data;
        if (res && res.success) {
            fetchData();
            setValues({});
            setAlertMessage('Feedback updated successfully!!');
        } else {
            setAlertMessage(res.message);
        }
    };

    const fetchData = () => {
        dispatch(findValveDetailById(valveId)).then((resp: any) => {
            if (resp && resp.status === 200) {
                setListData(resp.data);
            } else {
                setAlertMessage('Something went wrong...!');
            }
        });
    };

    useEffect(() => {
        fetchData();

    }, [dispatch, paramObj]);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const valid = validateForm();
        if (!valid) {
            return;
        }

        const createFeedback = {
            valveId: valveId,
            feedback: values.feedback,
            angle: values.angle,
            result: values.result,
            action: values.action ,
            notes: values.notes
        };

        dispatch(feedback(createFeedback)).then((resp: any) => {
            updateResponse(resp);
        });
    };

    return (
        <div>

            <Grid container className={classes.formGroup}>
                <Grid item xs={12} md={12}>

                    { detailData && detailData.resultAngleRange &&
                        <Box textAlign="left" fontWeight="fontWeightBold" m={1}>
                        {`Angle Range: ${(detailData && detailData.resultAngleRange) ? detailData.resultAngleRange.start : ''} to
                             ${(detailData && detailData.resultAngleRange) ? detailData.resultAngleRange.end : ''},
                              Direction: ${(detailData && detailData.resultAngleRange) ? detailData.resultAngleRange.dir : ''}`}
                    </Box>
                    }
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <Grid item xs={12} md={8} className={classes.marginBottom10}>
                            <TextField
                                id="feedback"
                                label="Feedback*"
                                name="feedback"
                                margin="dense"
                                fullWidth
                                multiline
                                rows={5}
                                value={values.feedback || ''}
                                onChange={(e) => handleChange(e)}
                                variant="outlined"
                                InputLabelProps={{classes: {root: classes.label}}}
                            />
                            <ErrorHelperText error={errors.feedback}/>
                        </Grid>
                        {
                            detailData && detailData.resultAngleRange &&
                            <Grid item xs={12} md={4} className={classes.marginBottom10}>
                                <TextInputField
                                  id="angle"
                                  name="angle"
                                  margin="dense"
                                  label="Angle"
                                  value={values.angle || ''}
                                  onChange={(e) => handleChange(e)}
                                  variant="outlined"
                                  InputLabelProps={{
                                      classes: { root: classes.label }
                                  }}
                                  type="number"
                                  errors={errors.angle}
                                />
                            </Grid>
                        }

                        <Grid item xs={12} md={12} className={classes.marginBottom10}>
                            <FormControl
                                variant="outlined"
                                margin="dense"
                                className={classes.formControl}>
                                <InputLabel className={classes.selectLabel} ref={inputLabel}
                                            id="resultLabel">
                                    Result*
                                </InputLabel>
                                <Select
                                    labelId="resultLabel"
                                    id="result"
                                    name="result"
                                    value={values.result}
                                    onChange={v => handleChange(v)}
                                >
                                    <MenuItem value="Passed">Passed</MenuItem>
                                    <MenuItem value="Failed">Failed</MenuItem>
                                    <MenuItem value="Modified">Pass with Valve / Flow Modification </MenuItem>
                                </Select>
                            </FormControl>
                            <ErrorHelperText error={errors.result}/>
                        </Grid>

                        {
                            (values.result === 'Failed' || values.result === 'Modified') &&
                        <Grid item xs={12} md={8} className={classes.marginBottom10}>
                            <TextField
                                id="actionTaken"
                                label="ActionTaken"
                                name="action"
                                margin="dense"
                                fullWidth
                                multiline
                                rows={5}
                                value={values.action || ''}
                                onChange={(e) => handleChange(e)}
                                variant="outlined"
                                InputLabelProps={{classes: {root: classes.label}}}
                            />

                        </Grid>
                        }

                        <Grid item xs={12} md={8} className={classes.marginBottom10}>
                            <TextField
                                id="notes"
                                label="Additional Notes"
                                name="notes"
                                margin="dense"
                                fullWidth
                                multiline
                                rows={5}
                                value={values.notes || ''}
                                onChange={(e) => handleChange(e)}
                                variant="outlined"
                                InputLabelProps={{classes: {root: classes.label}}}
                            />

                        </Grid>


                        <Grid item xs={12} md={12} className={classes.marginBottom10}>
                            <Button
                                color="primary"
                                variant="contained"
                                size="small"
                                type="submit"
                                onClick={(e) => handleSubmit(e)}
                            >Submit
                            </Button>
                        </Grid>
                    </form>
                </Grid>
            </Grid>


            {alertMessage ?
                (
                    <AlertDialog
                        handleClose={() => {
                            setAlertMessage(null);
                            getValveInfo();
                        }}
                        message={alertMessage}

                    />
                )
                : null
            }
        </div>
    );
};

export default FeedBackForm;
